import { Box, Typography } from "@mui/material";
import I18n from "i18n-js";
import BaseModal from "..";
import { icons } from "../../../utils";
import { LayoutButton } from "../../btnBN/layoutButton";
import { useStyles } from "./styles";

export const ModalUsedItem = (props) => {
  const { label, setOpenModal, openModal } = props;
  const styles = useStyles();
  return (
    <BaseModal open={openModal}>
      <Box className={styles.modal}>
        <Box onClick={() => setOpenModal(false)} className={styles.closeIcon}>
          {icons("closeIcon")}
        </Box>
        <Typography>{label}</Typography>

        <LayoutButton
          background="#03a17f"
          backgroundColorHover="#239192"
          variant="contained"
          label={I18n.t("group.accept")}
          click={() => setOpenModal(false)}
        />
      </Box>
    </BaseModal>
  );
};
