import { useMemo } from "react";
import enumsTypeQuestion from "../../enums/typeOfQuestion";
import FormAnswer from "../formAnswer";

const Answer = (props) => {
  const { deleteOption, typeOfQuestion, valueSwitch } = props;

  //eslint-disable-next-line
  const arrayOption = useMemo(() => props.options, [props.options]);

  if (!arrayOption) return <></>;
  switch (typeOfQuestion) {
    case enumsTypeQuestion.simple:
      return (
        <>
          {arrayOption.map((option) => (
            <FormAnswer
              deleteOption={deleteOption}
              option={option}
              key={option.key}
              type="radio"
              valueSwitch={valueSwitch}
            />
          ))}
        </>
      );

    case enumsTypeQuestion.multiple:
      return (
        <>
          {arrayOption.map((option) => (
            <FormAnswer
              deleteOption={deleteOption}
              option={option}
              key={option.key}
              type="check"
              valueSwitch={valueSwitch}
            />
          ))}
        </>
      );

    case enumsTypeQuestion.open:
      return (
        <>
          {arrayOption.map((option) => (
            <FormAnswer
              deleteOption={deleteOption}
              option={option}
              key={option.key}
              type="open"
              valueSwitch={valueSwitch}
            />
          ))}
        </>
      );

    case enumsTypeQuestion.escalar_text:
      return (
        <>
          {arrayOption.map((option) => (
            <FormAnswer
              deleteOption={deleteOption}
              option={option}
              key={option.key}
              type="radio"
              valueSwitch={valueSwitch}
            />
          ))}
        </>
      );

    default:
      return <></>;
  }
};

export default Answer;
