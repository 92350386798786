import { Box } from "@mui/material"
import pollsStyles from "./themes/polls"

const Polls = () => {
    const classes = pollsStyles()
    return (
        <Box className={classes.container}>
            <h1>Polls</h1>
        </Box>
    )
}

export default Polls
