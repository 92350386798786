import { useContext } from "react";
import QuestionnairesContext from "../context/questionnairesContext";

export const useMoveArrayElem = () => {
  const { data, setData } = useContext(QuestionnairesContext);

  const pushUp = (key, section) => {
    if (section) {
      section.map((item, i) => {
        if (item.key === key) {
          let auxSection = section[i - 1];
          section[i - 1] = section[i];
          section[i] = auxSection;
        }
        return item;
      });

      setData({ ...data, sections: section });
    }
  };

  const pushDown = (key, section) => {
    if (section) {
      let i = 0;
      do {
        if (section[i].key === key) {
          let auxSection = section[i + 1];
          section[i + 1] = section[i];
          section[i] = auxSection;
          break;
        }
        i++;
      } while (true);
      setData({ ...data, sections: section });
    }
  };
  return { pushUp, pushDown };
};
