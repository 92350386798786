import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  containerAll: {
    padding: "10px 30px 0 31px",
  },

  title: {
    margin: "48px 7% 10px 116px",

    [theme.breakpoints.down("md")]: {
      margin: "48px 7% 10px 39px",
    },
  },
  linkSelect: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  container: {
    minHeight: window.innerHeight - 135,
    marginTop: 45,
    margin: "auto",
    width: "95%",
  },
  link: {
    paddingLeft: "7.5%",
  },
}));

export default useStyles;
