import { makeStyles } from "@mui/styles";
import colors from "../colors/colors";
import { createTheme } from "@mui/system";

const theme = createTheme();

const NavbarStyle = makeStyles({
  container: {
    height: 50,
    "& .MuiToolbar-root": {
      backgroundColor: colors.palette.primary,
    },
  },
  toolbar: {
    display: "flex",
  },
  menu: {
    mr: 2,
    flexGrow: 1,
  },
  userInfo: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: -3,
  },
  avatar: {
    "&.MuiSvgIcon-root": {
      fontSize: 40,
      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
      },
    },
    position: "relative",
    left: 15,
    cursor: "pointer",
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
    },
    color: colors.palette.white,
  },
  user: {
    "&.MuiTypography-root": {
      fontSize: 14,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      color: colors.palette.white,
      marginTop: 2,
    },
  },
  menuIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: 30,
      color: colors.palette.white,
    },
  },
});

export default NavbarStyle;
