import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const LogOut = () => {
  const history = useHistory()
  const logout = useCallback(async () => {
    try {
      localStorage.clear()

      history.push('/')
    } catch (error) {
      console.error(error)
    }
  }, [history])

  useEffect(() => logout(), [logout])
  return <div>LogOut</div>
}

export default LogOut
