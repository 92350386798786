import { makeStyles } from '@mui/styles'
import { createTheme } from '@mui/system'
import colors from '../../../themes/colors/colors'

const theme = createTheme()

const ListAnswerStyle = makeStyles({
  container: {
    minHeight: window.outerHeight,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '&.MuiPaper-root': {
      marginTop: 4,
      marginBottom: 40,
    },
    width: '80%',
    margin: 'auto',
  },
  box: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
    marginTop: 45,
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    flexGrow: 1,
    '&.MuiTypography-root': {
      marginTop: 10,
    },
  },
  input: {
    background: colors.palette.white,
    '& .MuiInputLabel-root': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '60%',
      '&.Mui-focused': {
        width: '100%',
      },
    },
  },
  tableContainer: {
    maxHeight: 700,
    marginTop: 10,
    backgroundColor: colors.palette.white,
  },
  icon: {
    color: colors.palette.general,
  },
  search: {
    width: 500,
    display: 'flex',
    '& .MuiBox-root': {
        minWidth: '45%',
        marginRight: '5%'
    }
  },
})

export default ListAnswerStyle
