import MenuBookIcon from "@mui/icons-material/MenuBook";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import BallotIcon from "@mui/icons-material/Ballot";
// import FactCheckIcon from '@mui/icons-material/FactCheck';
import GroupsIcon from "@mui/icons-material/Groups";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuizIcon from "@mui/icons-material/Quiz";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const list = [
  {
    path: "/dashboard",
    label: "drawer.dashboard",
    icon: (classes) => <HomeIcon className={classes} />,
  },
  {
    path: "/dashboard/answers",
    label: "drawer.answers",
    icon: (classes) => <MenuBookIcon className={classes} />,
  },
  {
    label: "drawer.setting",
    icon: (classes) => <SettingsIcon className={classes} />,
    sub: [
      {
        path: "/dashboard/group",
        label: "drawer.group",
        icon: <GroupsIcon />,
      },
      {
        path: "/dashboard/option-group",
        label: "drawer.groupOptions",
        icon: <BallotIcon />,
      },
      {
        path: "/dashboard/exclusion",
        label: "drawer.exclusionConditions",
        icon: <DoNotDisturbAltIcon />,
      },
      {
        path: "/dashboard/question/questionlist",
        label: "drawer.questions",
        icon: <HelpOutlineIcon />,
      },
      {
        path: "/dashboard/typeQuestions",
        label: "drawer.question",
        icon: <QuizIcon />,
      },
      {
        path: "/dashboard/questionnaires",
        label: "drawer.questionnaries",
        icon: <CheckBoxIcon />,
      },
    ],
  },
  {
    path: "/dashboard/pollsters",
    label: "drawer.users.primary",
    icon: (classes) => <AssignmentIcon className={classes} />,
    circleIcon: true,
  },
];
