import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  searchBar: {
    background: 'white',
    height: '41px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
        height: '45px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  barRounded: {
    background: 'white',
    borderRadius: '50px',
    height: '41px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '50px',
        overflow: 'hidden',
        height: '45px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
})

export default useStyles
