import { makeStyles } from "@mui/styles";

export const styles = {
  closeIcon: {
    position: "absolute",
    left: "90%",
    top: "15px",
    cursor: "pointer",
  },
};

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: window.innerHeight - 135,
    marginTop: 45,
    margin: "auto",
    width: "95%",
  },
  link: {
    paddingLeft: "7.5%",
  },
  containerOfAll: {
    padding: "10px 30px 0 36px",
  },
  title: {
    margin: "48px 7% 10px 116px",
  },
  closeIcon: {
    position: "absolute",
    left: "90%",
    top: "15px",
    cursor: "pointer",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
  },
  modalTitle: {
    padding: "0px 0px 19px 37px ",
  },
  formPart: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    margin: "30px 0px",
    width: "86%",
  },
  formPartName: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formPartKey: {
    width: "100%",
    marginBottom: "3px",
  },
  buttonsPart: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    margin: "16px 20px 16px 0px",
  },
}));
