import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import I18n from "i18n-js";
import BaseModal from "..";
import { icons } from "../../../utils";
import { styles } from "./styles";
import { LayoutButton } from "../../btnBN/layoutButton";

export const ModalYouSure = (props) => {
  const {
    openModal,
    setOpenModal,
    eventAccept,
    annularEvent = () => setOpenModal(false),
    actionText,
  } = props;
  return (
    <BaseModal open={openModal}>
      <Box onClick={() => setOpenModal(false)} sx={styles.closeIcon}>
        {" "}
        {icons("closeIcon")}
      </Box>
      <Box sx={styles.modal}>
        <Box sx={styles.textModal}>
          <Typography fontSize="14px" marginBottom={2}>
            {actionText}
          </Typography>
          <Typography fontSize="14px">
            {I18n.t("question.modal.doYouWishContinue")}
          </Typography>
        </Box>

        <Box sx={styles.buttonModal}>
          <LayoutButton
            variant={"outlined"}
            click={annularEvent}
            label={I18n.t("group.cancel")}
            color="#03a17f"
            borderColor="#03a17f"
            hoverBorderColor="#03a17f"
            hoverColor="#03a17f"
            backgroundColorHover="rgba(25, 118, 210, 0.04)"
          />
          <LayoutButton
            background="#03a17f"
            variant={"contained"}
            click={eventAccept}
            label={I18n.t("group.accept")}
            backgroundColorHover="#239192"
          />
        </Box>
      </Box>
    </BaseModal>
  );
};
