import { Grid, Typography, TextField, Box, IconButton, Slider, Alert, AlertTitle, Hidden } from '@mui/material'
import { useEffect, useState } from 'react'
import loginMessage from '../../utils/loginMessage.json'
import { ressetError } from './hooks/useError'
import { useStyles } from './styles'
import { icons } from '../../utils/icons'
import { useParams, useHistory } from 'react-router-dom'
import { ressetPassword } from '../../service/service'
import { LoadingButton } from '@mui/lab'
import SimpleError from '../../components/errors/simple'

const RessetPassword = () => {
  const { token } = useParams()
  const history = useHistory()

  const [visibility, setVisibility] = useState({
    visibilityPassword: false,
    visibilityConfirmPassword: false,
  })

  const [open, setOpen] = useState(false)

  const [error, setError] = useState({})

  const [changedPassword, setChangedPassword] = useState(false)

  const [load, setLoad] = useState(false)

  const [notification, setNotification] = useState({
    message: '',
    type: 'error',
  })

  const classes = useStyles

  const [values, setValues] = useState({
    password: null,
    password_confirmation: null,
    token: token,
  })

  const handleSubmit = async (e) => {
    setLoad(true)
    let notification = {}

    const resp = await ressetPassword(values)
    if (resp.data.success) {
      notification = {
        message: loginMessage.changePasswordSuccess,
        type: 'success',
      }
      setChangedPassword(true)
    } else {
      notification = {
        message: loginMessage.changePasswordError,
        type: 'error',
      }
    }
    setNotification(notification)
    setLoad(false)
    setOpen(true)
  }

  const HandleChange = ({ target }) => {
    setOpen(false)
    const { name, value } = target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    setError(ressetError(values))
  }, [values])

  const handleVisibilityPassword = (name) => setVisibility({ ...visibility, [name]: !visibility[name] })

  const handleTypePassword = (name) => (!visibility[name] ? 'password' : 'text')

  const handleDisabled = () => {
    let errorCopy = { ...error }
    delete errorCopy.completedStep
    let errorFound = Object.values(errorCopy).find((item) => item === true)
    const valueExists = Object.values(values).find((item) => item === null)

    return errorFound || valueExists !== undefined ? true : false
  }
  return (
    <Grid container alignItems="center" sx={classes.form}>
      {/* Longin */}
      <Grid item lg={7} md={7} xs={12} direction="column">
        <Box sx={classes.container}>
          <Box sx={classes.containerHeader}>
            <Typography variant="body2" sx={classes.resetPassTitle} width="100%">
              {loginMessage.changePasswordTitle}
            </Typography>
            <Typography mb={2} variant="caption" width="100%">
              {loginMessage.changePasswordSubtitle}
            </Typography>
          </Box>

          {open && notification.type === 'success' ? (
            <Alert severity="success" sx={classes.required}>
              <Typography variant="body2" sx={classes.infoText}>
                <AlertTitle>{loginMessage.titleSuccess}</AlertTitle>
                {notification.message}
              </Typography>
            </Alert>
          ) : (
            <>
              <TextField
                sx={{ ...classes.input, ...(open ? classes[notification.type] : {}) }}
                name="password"
                label={loginMessage.password}
                type={handleTypePassword('visibilityPassword')}
                value={values?.password ?? ''}
                onChange={HandleChange}
                disabled={changedPassword}
                error={error?.password}
                InputProps={{
                  endAdornment: <IconButton onClick={() => handleVisibilityPassword('visibilityPassword')}>{icons(!visibility.visibilityPassword ? 'visibility' : 'visibilityOff')}</IconButton>,
                }}
              />

              <Box sx={classes.wFull} pb={2}>
                <SimpleError error={error?.password} message={loginMessage.weakPassword} />
              </Box>

              <TextField
                sx={{ ...classes.input, ...(open ? classes[notification.type] : {}) }}
                name="password_confirmation"
                label={loginMessage.password}
                type={handleTypePassword('visibilityConfirmPassword')}
                value={values?.password_confirmation ?? ''}
                onChange={HandleChange}
                disabled={changedPassword}
                error={error?.password_confirmation}
                InputProps={{
                  endAdornment: <IconButton onClick={() => handleVisibilityPassword('visibilityConfirmPassword')}>{icons(!visibility.visibilityConfirmPassword ? 'visibility' : 'visibilityOff')}</IconButton>,
                }}
              />

              <Box sx={classes.wFull} pb={1}>
                <SimpleError error={open || error?.password_confirmation} message={open ? notification.message : loginMessage.confirmPassword} type={open ? notification.type : 'error'} />
              </Box>

              <Box sx={classes.containerEasyStrong}>
                <Slider aria-label="Small steps" defaultValue={0} size="small" value={error?.completedStep ?? 0} step={1} marks min={0} max={6} valueLabelDisplay="auto" disabled />
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {loginMessage.weak}
                </Typography>

                <Typography variant="body2" sx={error?.completedStep !== 6 ? classes.typografyColor : ''}>
                  {' '}
                  {loginMessage.strong}
                </Typography>
              </Box>

              <Alert severity="info" sx={classes.required}>
                <Typography variant="body2" sx={classes.infoText}>
                  <AlertTitle>{loginMessage.title}</AlertTitle>
                  {loginMessage.length} <br />
                  {loginMessage.letter} <br />
                  {loginMessage.special} <br />
                </Typography>
              </Alert>
            </>
          )}

          <LoadingButton
            loading={load}
            variant="contained"
            onClick={() => (changedPassword ? history.push('/') : handleSubmit())}
            sx={!changedPassword ? classes[!handleDisabled() ? 'btn' : 'btnDisabled'] : classes.btn}
            disabled={changedPassword ? false : handleDisabled()}
          >
            {loginMessage[changedPassword ? 'changePasswordReturn' : 'changePasswordButton']}
          </LoadingButton>
        </Box>
      </Grid>

      <Hidden mdDown>
        <Grid item lg={5} md={5} sm={12} sx={classes.bg}>
          <Box />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default RessetPassword
