import { makeStyles } from "@mui/styles";
import colors from "../../../themes/colors/colors";

export const useStyles = makeStyles((theme) => ({
  delete: {
    display: "flex",
    flexWrap: "wrap",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    boxShadow: "3px 2px #5F6E6D",
  },
  CUText: {
    width: "100%",
    "&.MuiTypography-root": {
      padding: "10px 20px",
    },
  },
  CUButtons: {
    "&.MuiButton-root": {
      marginRight: 10,
      height: 30,
      borderRadius: 0,
    },
  },
  CUContainerButtons: {
    padding: "10px 0",
    display: "flex",
    width: "100%",
  },
  CUButtonsCancel: {
    "&.MuiButton-root": {
      border: `1px solid ${colors.buttons.outline}`,
      color: colors.buttons.outline,
      "&:hover": {
        border: `1px solid ${colors.buttons.outline}`,
        color: colors.buttons.outline,
      },
    },
  },
  CUButtonsAccept: {
    "&.MuiButton-root": {
      backgroundColor: colors.buttons.primary,
      "&:hover": {
        backgroundColor: colors.buttons.secondary,
      },
    },
  },
  link: {
    color: "#ffff",
    textDecoration: "none",
  },
}));
