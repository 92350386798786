import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import I18n from "i18n-js";
import { typeQuestions } from "../../service/typeQuestions";
import { useStyles } from "./styles";
import { icons } from "../../utils";
import BaseModal from "../../components/modal";
import { LayoutButton } from "../../components/btnBN/layoutButton";
import { SaveKey } from "./form/saveKey";
import { SaveName } from "./form/saveName";
import { SmallSpinner } from "../../components/spinnerProgress/smallSpinner";

export const ModalForm = (props) => {
  const {
    openModalCreate,
    setOpenModalCreate,
    isEdit = null,
    setIsEdit,
    consultTypeQuestion,
  } = props;
  const userLocale = localStorage.getItem("lang");
  const [enableButton, setEnableButton] = useState(true);
  const [drillSpinner, setDrillSpinner] = useState(true);
  const [keyExists, setKeyExists] = useState(false);
  const [data, setData] = useState({});
  const styles = useStyles();
  useEffect(() => {
    if (isEdit) {
      typeQuestions.detailTypeQuestion(isEdit).then(({ data: data_ }) => {
        const title_ = data_.question_type.translations.map((item) => {
          return {
            value: item.name,
            locale: item.locale,
          };
        });
        setDrillSpinner(false);
        setData({ ...data, key: data_.question_type.key, name: title_ });
      });
    }
    //eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    if (data.name) {
      let value_ = data?.name?.find(
        (item) => item.locale === userLocale
      )?.value;
      if (value_ && value_ !== "" && data.key && data.key !== "")
        setEnableButton(false);
      if (!value_ || value_ === "" || !data.key || data.key === "")
        setEnableButton(true);
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setKeyExists(false);
  }, [data.key]);
  const actionClose = () => {
    setData({});
    setOpenModalCreate(false);
    setIsEdit(null);
    setDrillSpinner(true);
    setEnableButton(true);
  };

  const saveData = async () => {
    let resp;
    let existence = await typeQuestions.validateKey(data.key.toLowerCase());

    if (existence?.error) {
      setKeyExists(true);
    } else {
      if (isEdit) {
        resp = await typeQuestions.editTypeQuestion(isEdit, data);
      } else {
        resp = await typeQuestions.createTypeQuestion(data);
      }
      if (resp.data) {
        consultTypeQuestion();
        actionClose();
      }
    }
  };

  return (
    <BaseModal open={openModalCreate} width={500}>
      <Box onClick={actionClose} className={styles.closeIcon}>
        {icons("closeIcon")}
      </Box>

      <Box className={styles.modalBody}>
        <Box className={styles.modalTitle}>
          <Typography fontSize="19px">
            {isEdit
              ? I18n.t("typeOfQuestion.titles.updateQuestionType")
              : I18n.t("typeOfQuestion.titles.addQuestionType")}
          </Typography>
        </Box>

        <Divider />
        {isEdit && drillSpinner ? (
          <SmallSpinner />
        ) : (
          <Box className={styles.formPart}>
            <SaveKey data={data} setData={setData} keyExists={keyExists} />
            <SaveName data={data} setData={setData} />
          </Box>
        )}

        <Divider />
        <Box className={styles.buttonsPart}>
          <LayoutButton
            variant="outlined"
            label={I18n.t("general.cancelBtn")}
            click={actionClose}
          />
          <LayoutButton
            variant="contained"
            label={I18n.t("general.add")}
            click={saveData}
            disabled={enableButton}
          />
        </Box>
      </Box>
    </BaseModal>
  );
};
