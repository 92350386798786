import { POST, PUT } from "../utils/crud"

export const all = async (url) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/${url}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            },
        })

        const data = await res.json()

        return {
            data,
            response: 200,
        }
    } catch (error) {
        return { response: 404 }
    }
}

export const createUser = async (body) => {
    const res = await POST('/users', body)

    return res
}

export const updateUser = async (body, id) => {
    const res = await PUT(`/users/${id}`, body)

    return res
}

export const trash = async (url, id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/${url}/${id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            }
        })

        return {
            status: res.status
        }
    } catch (error) {
        return {
            status: 404
        }
    }
}

export const detail = async (url, id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/${url}/${id}`, {
            method: 'get',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            }
        })

        const data = await res.json()

        return {
            data: data.user,
            status: 200
        }
    } catch (error) {
        return {
            status: 404
        }
    }
}

export const validEmail = async (url, email) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/${url}/${email}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            }
        })

        const data = await res.json()

        return {
            data: data.email_exist,
            status: 200
        }
    } catch (error) {
        return {
            status: 404
        }
    }
}
