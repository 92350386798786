import { Box, IconButton, Typography } from "@mui/material";
import { colors, icons } from "../../utils";
import useStyles from "./styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const DragDropItems = (props) => {
  const {
    item,
    setData = null,
    data = null,
    sectionsKey = null,
    filteredQuestions = null,
    setFilteredQuestions = null,
  } = props;
  const classes = useStyles();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(item, result.source.index, result.destination.index);
    if (data) {
      const sections_ = data.sections.map((element) => {
        if (sectionsKey === element.key) element.questions = items;
        return element;
      });
      setData({ ...data, sections: sections_ });
    }
  };

  const deleteItem = (index_, id) => {
    const newState = [...item];

    newState.splice(index_, 1);
    const newSections = data.sections.map((item_) => {
      if (item_.key === sectionsKey) item_.questions = newState;
      return item_;
    });
    setData({ ...data, sections: newSections });
    const filteredQuestions_ = filteredQuestions.map((item_) => {
      if (item_.key === sectionsKey && item_.id === id) {
        item_.used = false;
        item_.key = null;
      }

      return item_;
    });

    setFilteredQuestions(filteredQuestions_);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {item.map((item_, index) => (
                <Draggable
                  key={item_.question_id}
                  draggableId={item_.question_id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={classes.itemsQuestions}
                    >
                      <Typography
                        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {item_.value}
                      </Typography>
                      <Box display="flex">
                        <IconButton {...provided.dragHandleProps}>
                          {icons("move")}
                        </IconButton>
                        <IconButton
                          sx={{
                            "&.MuiIconButton-root": {
                              color:
                                item.length === 1
                                  ? colors.palette.greyMedium
                                  : colors.palette.error,
                            },
                          }}
                          onClick={() => deleteItem(index, item_.question_id)}
                          disabled={item.length === 1}
                        >
                          {icons("close")}
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
