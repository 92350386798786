import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useStyles = makeStyles({
  medium: {
    borderRadius: '0 2px 2px 0',
    fontSize: '14px',
    padding: '7px 15px',
  },
  color: { color: colors.white },
  large: {
    borderRadius: '2px',
    fontSize: '14px',
    fontWeight: '400',
    padding: '14px 15px',
    textTransform: 'none',
  },
  standard: {
    '&.MuiButton-root': {
      borderRadius: '0',
      textTransform: 'none',
    }
  }
})

export default useStyles
