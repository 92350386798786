import { Box, Breadcrumbs, Button, IconButton, TextField, Typography } from '@mui/material'
import tableUsers from './themes/tableUsers'
import { useCallback, useEffect, useState } from 'react'
import TableVerify from '../../components/tables/tableLink'
import SnackSimple from '../../components/snack/SnackSimple'
import { all, createUser, trash, updateUser } from '../../service/CRUD'
import Loading from '../../components/loading'
import I18n from 'i18n-js'
import { Link } from 'react-router-dom'
import LinkStyles from '../../themes/js/Link'
import { icons, languages } from '../../utils'
import BaseModal from '../../components/modal'
import { validedEmail } from '../../validator/validator'
import BasicSelect from '../../components/select/Select'
import { disabled, userErrors } from './methods'
import SimpleError from '../../components/errors/simple'
import { LoadingButton } from '@mui/lab'

const TableUsers = () => {
  const data = {
    title: I18n.t('users.title'),
    search: I18n.t('users.input'),
    searchBtn: I18n.t('users.btn'),
    to: 'pollsters/create',
    to2: 'pollsters/update',
    to3: 'pollsters/associate',
    titleM: I18n.t('users.delete.deleteTitle'),
    btnCancel: I18n.t('users.delete.cancel'),
    btnAccept: I18n.t('users.delete.accept'),
  }

  const [id, setId] = useState()

  const [openModal, setOpenModal] = useState(false)

  const [option, setOption] = useState([])

  const [surveysApplied, setSurveysApplied] = useState(0)

  const [rows, setRows] = useState([])

  const [load, setLoad] = useState(true)

  const [sendsSuccess, setSendsSuccess] = useState(false)

  const [open, setOpen] = useState(false)

  const [type, setType] = useState('info')

  const classes = tableUsers()

  const [errors, setErrors] = useState({})

  const [emailVaild, setEmailVaild] = useState(true)

  const [message, setMessage] = useState('')

  const [user, setUser] = useState({
    name: null,
    email: null,
    locale: null,
  })

  const columns = [
    { id: 'name', label: I18n.t('tableColumn.name') },
    // { id: 'email', label: I18n.t('tableColumn.email') },
    { id: 'excluded_surveys', label: I18n.t('tableColumn.totalOfVisits') },
    { id: 'success_surveys', label: I18n.t('tableColumn.surveysApplied') },
  ]

  const dataGroup = useCallback(async () => {
    const response = await all('users')

    if (response.data !== null) {
      setSurveysApplied(response.data?.users?.map((surveys) => surveys.success_surveys)?.reduce((a, b) => a + b, 0) || 0)

      setRows(
        response.data.users.map((item) => ({
          ...item,
          excluded_surveys: item.excluded_surveys + item.success_surveys,
          actions: (
            <IconButton className={classes.iconOfTheList}>
              <Link to={`${data.to3}/${item.id}`}>{icons('list')}</Link>
            </IconButton>
          ),
        }))
      )
    }

    setLoad(false)
  }, [classes.iconOfTheList, data.to3])

  const handleChange = (e) => {
    let copyUser = { ...user }

    if (!e?.target) {
      copyUser = {
        ...copyUser,
        locale: e,
      }
    } else {
      const { name, value } = e?.target

      if (name === 'email') {
        setEmailVaild(false)

        validedEmail(value).then((response) => {
          let error = { ...errors }

          if (response) {
            setErrors({ ...error, email: I18n.t('users.messages.emailExist') })
          } else {
            delete error.email
            setErrors(error)
          }
          setEmailVaild(!response)
        })
      }

      copyUser = {
        ...copyUser,
        [name]: value,
      }
    }

    setUser(copyUser)
  }

  const handleSubmit = async (e) => {
    setSendsSuccess(true)
    let response

    if (id) {
      const userCopy = { ...user }
      delete userCopy.email
      response = await updateUser(userCopy, id)
    } else {
      response = await createUser(user)
    }

    if (response?.data) {
      if(id) setMessage(I18n.t('users.messages.success.update'))
      else setMessage(I18n.t('users.messages.success.create'))

      setType('success')
      dataGroup()
      handleCloseModal()
    } else {
      if(id) setMessage(I18n.t('users.messages.error.delete'))
      else setMessage(I18n.t('users.messages.error.delete'))
      setType('error')
    }
    setOpen(true)
    setSendsSuccess(false)
  }
  
  const handleUser = (value) => {
    setId(value)

    if (value) {
      const userFilter = rows.filter((item) => item.id === value)
      setEmailVaild(true)
      setUser({
        ...user,
        email: userFilter[0]?.email,
        name: userFilter[0]?.name,
        locale: userFilter[0]?.locale,
      })
    } else {
      setUser({
        name: null,
        email: null,
        locale: null,
      })
    }
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setId(null)
    setOpenModal(false)
    setEmailVaild(false)
    setErrors({})
    setUser({
      name: null,
      email: null,
      locale: null,
    })
  }

  const handleMessageAndErrors = (name) => {
    return errors[name] && user[name] !== null
  }
  const remove = async (row) => {
    const res = await trash('users', row.id)

    if (res.status === 200) {
      setMessage(I18n.t('users.messages.success.delete'))
      setType('success')
      setOpen(true)
      dataGroup()
      return
    }

    setMessage(I18n.t('users.messages.error.delete'))
    setType('error')
    setOpen(true)
  }

  useEffect(() => {
    dataGroup()
  }, [dataGroup])

  useEffect(() => setOption(languages.map((item) => ({ value: item.locale, label: I18n.t(item.name) }))), [])

  useEffect(() => {
    let emailError = errors.email
    
    setErrors({...userErrors(user), ...emailError && {email: emailError}})
  }, [user, errors.email])

  if (load === true) {
    return <Loading />
  }

  return (
    <Box className={classes.container}>
      <SnackSimple open={open} close={setOpen} message={message} type={type} />

      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t('drawer.dashboard')}
        </Link>

        <Link style={LinkStyles} to={`/dashboard/pollsters`}>
          {I18n.t('drawer.users.primary')}
        </Link>
      </Breadcrumbs>

      <TableVerify
        columns={columns}
        rows={rows}
        data={data}
        remove={remove}
        actions={true}
        separator={false}
        buttonUp
        titleUp={`${surveysApplied} ${I18n.t('tableColumn.surveysApplied')}`}
        onCreate={() => handleUser()}
        onEdit={(value) => handleUser(value)}
      />

      <BaseModal open={openModal} onClose={handleCloseModal}>
        <Box className={classes.containerUserInput}>
          <Box className={classes.modelHeader}>
            <Typography variant="h6">{I18n.t(id ? 'users.update.title' : 'users.create.title')}</Typography>
            <IconButton className={classes.iconModal} onClick={() => handleCloseModal()}>
              {icons('close')}
            </IconButton>
          </Box>
          <Box className={classes.modelBody}>
            <Box className={classes.containerInput}>
              <TextField className={classes.input} size="small" name="name" value={user.name ?? ''} label={`${I18n.t('users.inputName')}*`} onChange={handleChange} error={handleMessageAndErrors('name')} />
              <SimpleError message={errors.name} error={handleMessageAndErrors('name')} />
            </Box>

            <Box className={classes.containerInput}>
              <TextField error={handleMessageAndErrors('email')} disabled={id} className={classes.input} size="small" name="email" value={user.email ?? ''} label={`${I18n.t('users.inputEmail')}*`} onChange={handleChange} />
              <SimpleError message={errors.email} error={handleMessageAndErrors('email')} />
            </Box>
            <BasicSelect option={option} selectedOp={user.locale ?? ''} selection={handleChange} label={`${I18n.t('users.inputLocal')}*`} />
          </Box>
          <Box className={classes.modelFooter}>
            <Box className={classes.buttonFooter}>
              <Button onClick={() => handleCloseModal()} variant="outlined" className={`${classes.buttonText}`}>
                {I18n.t('users.btnCancel')}
              </Button>
              <LoadingButton loading={sendsSuccess} onClick={() => handleSubmit(id)} disabled={disabled(errors) || !emailVaild} variant="contained" className={classes.buttonAction}>
                {I18n.t(id ? 'users.btnUpdate' : 'users.btnCreate')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </BaseModal>
    </Box>
  )
}

export default TableUsers
