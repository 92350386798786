import { Box, Breadcrumbs } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import I18n from "i18n-js";
import LinkStyles from "../../themes/js/Link";
import { ModalForm } from "./modalForm";
import { useStyles } from "./styles";
import Loading from "../../components/loading";
import TableVerify from "../../components/tables/tableLink";
import { typeQuestions } from "../../service/typeQuestions";
import { ModalUsedItem } from "../../components/modal/usedItem";
import { ModalQuestion } from "../../components/modal/modalQuestion";

const TypeQuestions = () => {
  const [listOfTypes, setListOfTypes] = useState();
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [trash, setTrash] = useState();
  const [openDelete, setOpenDelete] = useState(false);

  const classes = useStyles();
  const userLocale = localStorage.getItem("lang");
  const data = {
    title: I18n.t("typeOfQuestion.titles.listQuestionTypes"),
    search: I18n.t("users.input"),
    searchBtn: I18n.t("typeOfQuestion.buttons.newType"),
    to: null,
    to2: null,
    to3: "option-group/associate",
    titleM: I18n.t("typeOfQuestion.modal.areYouSure"),
    btnCancel: I18n.t("users.delete.cancel"),
    btnAccept: I18n.t("users.delete.accept"),
  };
  const columns = [
    {
      id: "name",
      label: I18n.t("typeOfQuestion.listTypesQuestions.name"),
      minWidth: 170,
    },
    {
      id: "key",
      label: I18n.t("typeOfQuestion.listTypesQuestions.key"),
      minWidth: 170,
    },
  ];
  useEffect(() => {
    consultTypeQuestion(); //eslint-disable-next-line
  }, []);

  const askDeletedTypeQuestions = async (id) => {
    const exists = await typeQuestions.validateTypeQuestions(id.id);

    if (exists.data.type_question_used) {
      setOpenModal(true);
    } else {
      setTrash(id);
      setOpenDelete(true);
    }
  };

  const consultTypeQuestion = () => {
    typeQuestions.list().then(({ data }) => {
      const listOftypes_ = data.question_types.map((item) => {
        const item_ = item.translations.find(
          (element) => element.locale === userLocale
        )?.name;
        return {
          key: item.key,
          name: item_,
          id: item.id,
        };
      });
      setListOfTypes(listOftypes_);
    });
  };

  const editItem = (flag, id = null) => {
    setIsEdit(id);
    setOpenModalCreate(flag);
  };

  const delateItem = async (obj) => {
    const res = await typeQuestions.deleteTypeQuestions(obj.id);
    if (res.data !== null)
      setListOfTypes(listOfTypes.filter((item) => item.id !== obj.id));
    setOpenDelete(false);
  };
  if (!listOfTypes) return <Loading />;
  return (
    <Box className={classes.container}>
      <ModalForm
        openModalCreate={openModalCreate}
        setOpenModalCreate={setOpenModalCreate}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        consultTypeQuestion={consultTypeQuestion}
      />
      <ModalUsedItem
        openModal={openModal}
        setOpenModal={setOpenModal}
        eventAccept={() => setOpenModal(false)}
        label={I18n.t("typeOfQuestion.modal.questionTypeIsInUse")}
      />
      <ModalQuestion
        title={I18n.t("typeOfQuestion.modal.areYouSure")}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("group.delete")}
        sendDelete={delateItem}
        trash={trash}
      />

      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t("drawer.setting")}
        </Link>

        <Link style={LinkStyles} to="/dashboard/typeQuestions">
          {I18n.t("drawer.question")}
        </Link>
      </Breadcrumbs>

      <TableVerify
        data={data}
        columns={columns}
        rows={listOfTypes}
        check={askDeletedTypeQuestions}
        modalCreate={setOpenModalCreate}
        editingModal={editItem}
      />
    </Box>
  );
};

export default TypeQuestions;
