import { makeStyles } from "@mui/styles";

const pollsStyles = makeStyles({
    container: {
        minHeight: window.innerHeight -135,
        marginTop: 20
    }
})

export default pollsStyles
