import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: window.innerHeight - 155,
    marginTop: 45,
    margin: 'auto',
    width: '81%',
    paddingLeft: '1%',
    position: 'relative',
  },
  textBreadcrum: { color: colors.blueLighten, textDecoration: 'none' },
  bodyContainer: {
    marginTop: '32px',
  },
  MR: {
    '&.MuiTextField-root': {
      marginRight: 10,
    },
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flexGrow: 1,
  },
  loading: {
    position: 'absolute',
    top: '50vh',
    left: '40vw',
  },
  childrenLength: {
    '&.MuiTypography-root': {
      padding: '3px 10px',
      marginRight: 10,
      color: colors.white,
      background: colors.blueLighten,
      borderRadius: '50%',
      fontSize: '.9em',
      fontWeight: 500,
    },
  },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  endButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: '16px',
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.blueLighten,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
  input: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      color: colors.greyPlaceholder,
    },
  },
  inputM: {
    '&.MuiTextField-root': {},
  },
  titleContainer: {
    width: '29.5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '8px',
    },
  },
  stepperContainer: {
    width: '50%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonsContainer: {
    // width: '10%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  card: {
    background: colors.white,
    padding: '30px 50px',
    maxWidth: 500,
    borderRadius: 20,
    display: 'flex',
    flexWrap: 'wrap',
    '&.MuiBox-root': {
      boxShadow: `1px 1px 5px ${colors.palette.greyDark}`,
    },
    margin: '50px auto',
    position: 'relative',
  },
  cardLeftCorner: {
    borderTopLeftRadius: 0,
  },
  tabs: {
    '&.MuiTabs-root': {
      position: 'absolute',
      top: -49,
      left: 0,
      boxShadow: `0px 0px 2px ${colors.palette.greyDark}`,
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      '& .MuiButtonBase-root': {
        minWidth: 60,
        padding: 0,
      }
    },
  },
  tab: { 
    '&.MuiTab-root .MuiAvatar-img': {
      width: '20px',
      height: '20px',
    }
  },
  instructions: {
    '&.MuiTypography-root': {
      color: colors.disabledDarken,
      fontSize: '.85em',
      margin: '20px 0 10px 5px',
      width: '95%',
    },
  },
  file: {
    '&.MuiTypography-root': {
      color: colors.blueLighten,
      fontSize: '.85em',
      fontWeight: 500,
      marginBottom: 10,
      cursor: 'pointer',
      textAlign: 'right',
    },
  },
  containerFlex: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  fullRight: {
    flexGrow: 1,
    width: 10,
  },
  containerMessage: {
    height: 10,
    width: '100%',
  },
  message: {
    '&.MuiTypography-root': {
      marginTop: 15,
      background: colors.palette.greenLighten,
      color: colors.palette.white,
      borderRadius: 5,
      padding: '0 10px',
      fontSize: '.9em',
      display: 'inline-block',
    },
  },
  language: {
    // display: 'inline-block',
    padding: '0 10px',
    '&.MuiTypography-root': {
      marginTop: 5,
      color: colors.greyStrong,
    },
  },
  localeContainer: {
    width: '100%',
  },
  bgMissingText: {
    '&.MuiTypography-root': {
      background: colors.palette.alert,
    },
  },
  item: {
    '&.MuiListItem-root': {
      borderBottom: `1px solid ${colors.disabledLighten2}`,
    },
  },
  itemText: {
    '&.MuiListItemText-root': {
      color: colors.blueLighten,
      wordBreak: 'break-word',
    },
  },
  showItemTitle: {
    '&.MuiListItem-root': {
      background: colors.white,
    },
  },
  showItemTitleText: {
    '&.MuiListItemText-root': {
      color: colors.greyStrong,
      '& .MuiTypography-root': {
        fontWeight: 500,
      },
    },
  },
  flat: {
    '&.MuiIcon-root': {
      overflow: 'none',
      width: 30,
      height: 30,
    },
  },
  arrow: {
    '&.MuiListItemIcon-root': {
      color: colors.greyStrong,
      minWidth: 0,
      cursor: 'pointer',
    },
  },
  cancelBtn: {
    position: 'absolute',
    bottom: '60px',
  },
  warning: { background: colors.white, minWidth: '300px' },
  cancel: {
    '&.MuiButton-root': {
      color: colors.disabled,
      border: colors.disabled + ' solid 1px',
      marginLeft: '52%',
      borderRadius: '2px',
      '&:hover': {
        color: colors.blueLighten,
      },
      transition: '.2s',
    },
  },
  submit: {
    '&.MuiButton-root': {
      marginLeft: 10,
      borderRadius: '2px',
    },
  },
  showItem: {
    '&.MuiListItem-root': {
      borderBottom: `1px solid ${colors.disabledBorder}`,
      background: colors.disabledLighten,
    },
  },
  showItemText: {
    '&.MuiListItemText-root': {
      color: colors.greyStrong,
      marginLeft: 10,
    },
  },
  list: {
    '&.MuiList-root': {
      boxShadow: `1px 1px 5px ${colors.greyDarken}`,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 20,
      borderRadius: 5,
      overflow: 'hidden',
    },
  },
  containerList: {
    '&.MuiList-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  delete: {
    '&.MuiListItemIcon-root': {
      color: colors.palette.error,
      minWidth: 0,
    },
  },
  edit: {
    '&.MuiListItemIcon-root': {
      color: colors.blueLighten,
      minWidth: 0,
    },
  },
  translateTA: {
    '&.MuiTextField-root': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        color: colors.greyPlaceholder,
        lineHeight: '2.55em',
        padding: '0 14px',
      },
    },
  },
  button: {
    '&.MuiButton-root': {
      marginLeft: 10,
    },
  },
}))

export default useStyles
