export const format = (item, position, locale, translate) => {
  let body = {
    position: position,
    description: [
      {
        locale: locale,
        value: item[locale].description,
      },
    ],
    recommendations: [
      {
        locale: locale,
        value: item[locale].recommendation,
      },
    ],
  }

  if(item?.[translate]?.description && item?.[translate]?.description !== '') {
    body.description.push({
      locale: translate,
      value: item[translate].description,
    })
  }

  if(item?.[translate]?.recommendation && item?.[translate]?.recommendation !== '') {
    body.recommendations.push({
      locale: translate,
      value: item[translate].recommendation,
    })
  }

  return body
}
