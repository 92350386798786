import { Box, Breadcrumbs } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { optionGroup } from "../../service/optionGroup";
import useStyles from "./styles";
import I18n from "i18n-js";
import LinkStyles from "../../themes/js/Link";
import TableVerify from "../../components/tables/tableLink";
import Loading from "../../components/loading";
import { ModalUsedItem } from "../../components/modal/usedItem";
import { ModalQuestion } from "../../components/modal/modalQuestion";

const OptionGroup = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [trash, setTrash] = useState();
  const data = {
    title: I18n.t("optionGroup.breadcrumbs.title"),
    search: I18n.t("users.input"),
    searchBtn: I18n.t("optionGroup.btn.create"),
    to: "option-group/create",
    to2: "option-group/edit",
    to3: "option-group/associate",
    titleM: I18n.t("optionGroup.modal.deleteGroup"),
    btnCancel: I18n.t("users.delete.cancel"),
    btnAccept: I18n.t("users.delete.accept"),
  };

  const [list, setList] = useState([]);
  const [flat, setFlat] = useState(false);
  const [isLoadid, setIsLoading] = useState(true);

  const columns = [
    {
      id: "title",
      label: I18n.t("optionGroup.columnLabel.group"),
      minWidth: 170,
    },
  ];
  const getData = useCallback(
    async () => {
      const res = await optionGroup.list();

      if (res.data !== null) {
        setList(
          res.data.options_group
          // res.data.options_group?.map((item) => ({
          //   ...item,
          //   actions: (
          //     <IconButton className={classes.iconOfTheList}>
          //       <Link to={`${data.to3}/${item.id}`}>{icons('list')}</Link>
          //     </IconButton>
          //   ),
          // }))
        );
      }
      setIsLoading(false);
    },
    [
      /* classes.iconOfTheList */
      /* , data.to3 */
    ]
  );

  const askDeletedOptionGroup = async (id) => {
    const exists = await optionGroup.validate(id.id);

    if (exists.data.Used) {
      setOpenModal(true);
    } else {
      setTrash(id);
      setOpenDelete(true);
    }
  };
  const remove = async (obj) => {
    const res = await optionGroup.deleteOptionGroup(obj.id);

    if (res.data !== null) {
      setList(list.filter((item) => item.id !== obj.id));
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    if (!flat) {
      getData();
      setFlat(true);
    }
  }, [flat, getData]);

  if (isLoadid) {
    return <Loading />;
  }

  return (
    <Box className={classes.container}>
      <ModalUsedItem
        openModal={openModal}
        setOpenModal={setOpenModal}
        eventAccept={() => setOpenModal(false)}
        label={I18n.t("optionGroup.modal.validation")}
      />
      <ModalQuestion
        title={I18n.t("optionGroup.modal.deleteGroup")}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("users.delete.accept")}
        sendDelete={remove}
        trash={trash}
      />
      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t("drawer.dashboard")}
        </Link>

        <Link style={LinkStyles} to={history.location.pathname}>
          {I18n.t("optionGroup.breadcrumbs.title")}
        </Link>
      </Breadcrumbs>

      <TableVerify
        columns={columns}
        rows={list}
        data={data}
        parameter="title"
        actions={true}
        check={askDeletedOptionGroup}
      />
    </Box>
  );
};

export default OptionGroup;
