import { Grid, Typography, FormControl, TextField, Button, Box, Hidden } from '@mui/material'
import { useReducer, useState } from 'react'
import TYPE from '../tipe.json'
import loginReducer from '../reducer/loginReducer'
import { useHistory } from 'react-router'
import { login } from '../service/service'
import LoginStyle from '../themes/js/Login'
import SnackLogin from '../components/snack/SnackSimple'
import { Link } from 'react-router-dom'

const initialUser = {
  email: '',
  password: '',
  error: {
    infPass: '',
    infEmail: '',
  },
}

const Login = () => {
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const [message, setMessage] = useState('')

  const classes = LoginStyle()

  const [user, dispatch] = useReducer(loginReducer, initialUser)

  const handleSubmit = async (e) => {
    if (e.key === 'Enter' || e.key === undefined) {
      const exp = /^[a-z-0-9]{1,30}@[a-z]{1,10}\.[a-z]{2,12}/i.test(user.email.trim())

      if (!exp) {
        dispatch({ type: TYPE.EMAIL_VERIFY })
      }

      if (user.password === '') {
        dispatch({ type: TYPE.PASSWORD_VERIFY })
      }

      if (exp && user.password.trim() !== '') {
        const data = {
          email: user.email,
          password: user.password,
        }

        const res = await login(data)

        if (res.response === 200) {
          history.push('/dashboard')
          return
        }

        setMessage('Email ou mot de passe invalide!')

        setOpen(true)
      }
    }
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      <SnackLogin open={open} close={setOpen} message={message} type="error" />

      {/* Longin */}
      <Grid item lg={7} md={7} xs={12}>
        <Grid container justifyContent="center">
          <FormControl component="form" className={classes.form} onKeyUp={handleSubmit}>
            <Typography mb={6} variant="h5" className={classes.title} width="100%">
              S'identifier
            </Typography>

            <TextField className={classes.inputBg} type="text" label="Nom d'utilisateur ou email" value={user.email} onChange={(e) => dispatch({ type: TYPE.EMAIL, payload: e.target.value })} />
            <Box sx={{ height: 25 }}>
              <Typography variant="subtitle2" color="error">
                {user.error.infEmail}
              </Typography>
            </Box>

            <TextField className={classes.inputBg} type="password" label="Mot de passe" value={user.password} onChange={(e) => dispatch({ type: TYPE.PASSWORD, payload: e.target.value })} />
            <Box sx={{ height: 25 }}>
              <Typography variant="subtitle2" color="error">
                {user.error.infPass}
              </Typography>
            </Box>

            <Box className={classes.btnContainer}>
              <Button onClick={handleSubmit} className={`${classes.submit} ${classes.btn}`} variant="contained">
                SE CONNECTER
              </Button>

              <Button variant="text" component={Link} to="/resetpassword" className={classes.reset}>
                Mot de passe oublié?
              </Button>
            </Box>
          </FormControl>
        </Grid>
      </Grid>

      <Hidden mdDown>
        <Grid item lg={5} md={5} sx={0} className={classes.bg}>
          <Box />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Login
