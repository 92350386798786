const tableViewStyles = {
    backgroundColor: '#fff',
    '&.MuiTextField-root': {
        marginTop: 1,
    },
    '& .MuiInputLabel-root': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 180,
        '&.Mui-focused': {
            width: '100%'
        }
    }
}

export default tableViewStyles