import { GET, POST, PUT, DELETE } from "../utils/crud";

const list = async () => {
  const res = await GET("/options-group");

  return res;
};

const detail = async (id) => {
  const res = await GET("/options-group/" + id);

  return res;
};

const validate = async (id) => {
  const res = await GET(`/options-group/used/${id}`);

  return res;
};

const createOptionGroup = async (body) => {
  const res = await POST("/options-group/batch", body);

  return res;
};

const updateOptionGroup = async (body, id) => {
  const res = await PUT(`/options-group/update-batch/${id}`, body);

  return res;
};

const deleteOptionGroup = async (id) => {
  const res = await DELETE(`/options-group/${id}`);

  return res;
};

export const optionGroup = {
  list,
  detail,
  createOptionGroup,
  updateOptionGroup,
  deleteOptionGroup,
  validate,
};
