import { Box, InputAdornment, TextField } from "@mui/material";
import I18n from "i18n-js";
import { useStyles } from "../styles";
import languages from "../../../utils/languages";
import { icons } from "../../../utils/icons";

export const SaveName = (props) => {
  const { data, setData } = props;
  const styles = useStyles();
  const userLocale = localStorage.getItem("lang");
  const handleChange = (e) => {
    const { value, name } = e.target;

    if (data?.name) {
      const nameLocale = data.name.find((item) => item.locale === name);
      if (nameLocale) {
        const name_ = data.name.map((item) => {
          return item.locale === name
            ? { ...item, value: value, locale: name }
            : item;
        });
        setData({ ...data, name: name_ });
      } else {
        const name_ = [...data.name];
        const newName = [...name_, loadData(value, name)];
        setData({ ...data, name: newName });
      }
    }
    if (!data?.name) setData({ ...data, name: [loadData(value, name)] });
  };

  const loadData = (value, name) => {
    return { value: value, locale: name };
  };

  const seekValue = (locale) => {
    const value_ = data?.name?.find((item) => locale === item.locale)?.value;
    return value_ ? value_ : undefined;
  };

  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  return (
    <Box className={styles.formPartName}>
      {languages.map((item, key) => (
        <TextField
          label={I18n.t("typeOfQuestion.listTypesQuestions.name")}
          required={item.locale === userLocale ? true : false}
          margin="normal"
          fullWidth
          onInput={(e) => {
            validateInput(e);
          }}
          name={item.locale}
          value={seekValue(item.locale) ?? ""}
          placeholder={I18n.t("typeOfQuestion.form.typeName")}
          onChange={(e) => handleChange(e)}
          inputProps={{ maxLength: 255 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {icons(item.locale)}
              </InputAdornment>
            ),
          }}
          key={key}
        />
      ))}
    </Box>
  );
};
