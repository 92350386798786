import { makeStyles } from "@mui/styles";
import { colors } from "../../../utils/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },

  header: {
    display: "flex",
    placeContent: "center",
    padding: "8px 0px",
    boxSizing: "border-box",
  },

  headerContainer: {
    width: "79.9%",
    marginTop: 28,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
    gap: "8px",
  },

  title: {
    flex: 0.4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "16px",
  },

  linkSelect: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },

  step: {
    flex: 0.6,
    display: "flex",
    alignItems: "center",
  },

  stepper: { flex: 1 },

  main: {
    display: "flex",
    justifyContent: "center",
  },
  //______________________________________________//
  formOne: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 50px",
    gap: "18px",
    width: "60%",
    marginTop: "40px",
    marginBottom: "80px",
    boxSizing: "border-box",
  },

  textField: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    gap: "18px",
  },

  buttonStepOne: {
    display: "flex",
    justifyContent: "space-between",
  },
  //_______________________________________________//
  //____________ SetepThree_____________//

  formThree: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 50px",
    gap: "18px",
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    marginTop: "40px",
    marginBottom: "100px",
    boxSizing: "border-box",
  },

  notCheck: {
    display: "flex",
    flexDirection: "column",
  },

  buttonPart: {
    display: "flex",
    justifyContent: " space-between",
    gap: "18px",
    marginTop: "30px",
  },
  plus: {
    "&.MuiIconButton-root": {
      color: colors.palette.greenLighten,
    },
  },

  zoneAddAnswer: {
    display: "flex",
    gap: "18px",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  contTranslation: {
    padding: "30px",
    marginBottom: "100px",
    width: "60%",
    borderRadius: "20px",
  },
  paperAnswer: {
    display: "flex",
    placeItems: "center",
    padding: "7px",
  },
  create: {
    gap: "25px",
    marginTop: "25px",
  },

  bodyText: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  link: {
    color: colors.blueLighten,
    textDecoration: "none",
  },
  internalButtonBox: {
    display: "flex",
    width: "200px",
    justifyContent: "space-between",
  },

  titleInstructions: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "auto",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
  },

  contentorSelector: {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  textInputOpen: {
    width: "60%",
  },
  containerToAdd: {
    display: "flex",

    width: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
}));

export const styles = {
  itemsSelect: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inputOpen: {
    width: { xs: "auto", md: "20%" },
    margin: { xs: "0", md: "30px 0px 0px 10px" },
  },
};

export default useStyles;
