import Routes from './router/Routes'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './themes'


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

export default App
