import { Box, InputAdornment, TextField } from '@mui/material'
import I18n from 'i18n-js'
import { icons } from '../../utils'
import useStyles from './style'
const SearchBar = ({ placeholder= null, label = null, change, rounded = false, small = false, sx = {} , ...rest}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} sx={sx}>
      <TextField
        {
          ...rest
        }
        variant="outlined"
        id="search"
        onChange={change}
        placeholder={
          placeholder === null ?
          !small ? I18n.t('form.searchBar') : I18n.t('general.search')
          :
          placeholder
        }
        label={label !== null ? label : undefined}
        size="small"
        className={!rounded ? classes.searchBar : classes.barRounded}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{icons('search')}</InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

export default SearchBar
