import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { styles } from "./styles";
import I18n from "i18n-js";
import SimpleError from "../errorMesage/simple";
import { icons } from "../../utils";
import { useEffect } from "react";
import { classStyles } from "./styles";

export const SectionFlowItem = (props) => {
  const {
    steps,
    list,
    handleChange,
    sectionID,
    questionID,
    error,
    see,
    index,
    questionError,
  } = props;

  useEffect(() => {
    if (error) {
      let element = document?.getElementById(questionError);
      element?.scrollIntoView({ behavior: "smooth" });
    } //eslint-disable-next-line
  }, [error]);
  return (
    <Box id={questionID} sx={styles.bodyFlowItem}>
      {steps.question && (
        <Box sx={styles.titlesHeader}>
          <Typography sx={styles.questionTitle}>{steps.question}</Typography>
          <Typography sx={styles.conttextNotRedic}>
            {I18n.t("questionnaries.title.action")}
          </Typography>
        </Box>
      )}
      {steps.question &&
        steps?.options?.map((item) => (
          <Box key={item.id} sx={styles.listFlowItem}>
            <Box sx={styles.ItemAnswer}>
              <Brightness1Icon sx={styles.pointAnswer} />
              <Typography sx={styles.answerTitle}>{item.title}</Typography>
            </Box>
            <Box>
              {see ? (
                <Box>
                  <TextField
                    select
                    InputProps={{
                      endAdornment: item.redirect_question && (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() =>
                              handleChange(
                                null,
                                sectionID,
                                questionID,
                                item.id,
                                index
                              )
                            }
                            sx={styles.buttonClean}
                          >
                            {icons("clear")}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={I18n.t("questionnaries.form.nextSection")}
                    sx={classStyles(item).itemSelect}
                    value={item.redirect_question ?? ""}
                    onChange={(e) =>
                      handleChange(e, sectionID, questionID, item.id, index)
                    }
                  >
                    {list.length > 1 ? (
                      <Typography sx={styles.textItemSelect}>
                        {I18n.t("questionnaries.questions")}
                      </Typography>
                    ) : (
                      <Typography sx={styles.textItemSelect}>
                        {I18n.t("questionnaries.thereFurtherQuestions")}
                      </Typography>
                    )}

                    {list.map((element, i) => (
                      <MenuItem
                        sx={{
                          display:
                            element.question === steps.question
                              ? "none"
                              : "block",
                        }}
                        key={element.id}
                        value={element.id}
                      >
                        <Typography>{element.question}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>

                  <SimpleError
                    error={error === item.id && error}
                    message={I18n.t(
                      "questionnaries.cetteQuestionProvoqueCycle"
                    )}
                  />
                </Box>
              ) : (
                <Box sx={styles.conttextNotRedic}>
                  <Typography sx={styles.textNotRedic}>
                    {I18n.t("questionnaries.lastQuestionNotHaveRedirects")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};
