import { Box, IconButton, TextField } from "@mui/material";
import I18n from "i18n-js";
import { useEffect, useRef, useState } from "react";
import { icons } from "../../utils";
import SimpleError from "../errorMesage/simple";
import { styles } from "./styles";

export const WrapperColors = ({
  label = null,
  onChange = null,
  name = "color",
  value = null,
  width,
  required = false,
}) => {
  const file = useRef(null);
  const [errors, setErrors] = useState(false);

  const colorPickerCont = {
    "& .MuiInputBase-root": {
      "& .MuiFormControl-root": {
        marginRight: "12px",
        width: "33px",
      },
      "& .MuiInputBase-input": {
        height: "25px",
      },
    },
    "& .square": {
      background: value,
      width: "35px",
      height: "25px",
      marginRight: "8px",
    },
    width: { sx: "auto", lg: width },
  };

  useEffect(() => {
    const regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
    if (value && value?.match(regex)) {
      setErrors(false);
    }
    if (value && !value?.match(regex)) {
      setErrors(true);
    }
  }, [value]);

  const validateHexadecimal = (e) => {
    const regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";
    if (e.target.value.match(regex)) {
      setErrors(false);
    } else {
      setErrors(true);
    }
  };

  return (
    <Box position={"relative"} sx={styles.bodyColors}>
      <Box width={"100%"}>
        <TextField
          name={name ?? "file"}
          fullWidth
          sx={colorPickerCont}
          required={required}
          label={label}
          value={value ?? ""}
          onChange={(e) => onChange(e)}
          onInput={(e) => {
            validateHexadecimal(e);
          }}
          InputProps={{
            startAdornment: (
              <Box className="square" sx={{ border: "1px solid black" }} />
            ),
          }}
          error={errors}
        />
        <SimpleError
          error={errors}
          message={I18n.t("questionnaries.errors.insertDecimalNumber")}
        />
      </Box>

      <IconButton
        sx={{
          marginLeft: "3px",
        }}
        onClick={() => file.current.click()}
      >
        {icons("paint")}
      </IconButton>
      <input
        name={name ?? "file"}
        value={value ?? ""}
        type="color"
        onChange={(e) => onChange(e)}
        style={styles.inputHiddenColors}
        ref={file}
      />
    </Box>
  );
};
