import React, { forwardRef} from 'react';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack'

const SnackMultiple = forwardRef(({message, variant},ref) => {
    const { enqueueSnackbar } = useSnackbar();
  
    const handleClick = () => {
      enqueueSnackbar(message, {variant});
    };
  
    return (
      <React.Fragment>
        <Button ref={ref} onClick={handleClick} sx={{display: 'none'}}>Show snackbar</Button>
      </React.Fragment>
    );
  })

  export default forwardRef(function IntegrationNotistack({message, variant},ref) {
    return (
      <SnackbarProvider maxSnack={3}>
        <SnackMultiple ref={ref} message={message} variant={variant}/>
      </SnackbarProvider>
    );
  })

// export default SnackMultiple 