import { Box, Typography } from '@mui/material'
import useStyles from './styles'

const SimpleError = ({ error = false, message }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
        { error && <Typography className={classes.text}>{message}</Typography>}
    </Box>
    )
}

export default SimpleError
