import { Box, Grid, IconButton, TextField, Typography } from '@mui/material'
import I18n from 'i18n-js'
import React, { useEffect, useState } from 'react'
import { icons } from '../../../../utils'
import languages from '../../../../utils/languages'
import useStyles from './styles'

const Step2 = ({ list, translate, locale, onChange }) => {
  const classes = useStyles()
  const length = list.length
  const [index, setIndex] = useState(0)
  const [translateData, setTranslateData] = useState({})
  const [localeTitle, setLocaleTitle] = useState({})
  const handleIndex = (value) => setIndex(index + value)

  useEffect(() => {
    const data = languages.find((item) => item.locale === translate)
    const localeName = languages.find((item) => item.locale === locale)
    if (data) {
      setTranslateData(data)
      setLocaleTitle(localeName)
    }
  }, [translate, locale])

  return (
    <>
      <Box className={`${classes.flex} ${classes.AIC}`} mb={2}>
        <Box className={classes.flexGrow} />

        <IconButton disabled={index <= 0} onClick={() => handleIndex(-1)}>
          {icons('back')}
        </IconButton>

        <Typography>
          {index + 1} / {length}
        </Typography>

        <IconButton disabled={index >= length - 1} onClick={() => handleIndex(1)}>
          {icons('next')}
        </IconButton>
      </Box>

      <Box className={classes.containerPrincipalData}>
        <Grid container spacing={2} className={classes.principalData}>
          <Grid item xs={12}>
            <Typography className={classes.header}>{I18n.t('exclusionConditions.associate.originalText') + ' ' + I18n.t(localeTitle.name)}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2" className={`${classes.colorLabel} ${classes.tf08}`}>
              {I18n.t('exclusionConditions.form.labelTextField') + ':'}
            </Typography>
            
            <Typography disabled={true} className={classes.break}>
              {list?.[index]?.[locale].description || ' '}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2" className={`${classes.colorLabel} ${classes.tf08}`}>
              {I18n.t('exclusionConditions.form.exclusionInstruction') + ':'}
            </Typography>

            <Typography disabled={true} className={classes.break} label={I18n.t('exclusionConditions.form.exclusionInstruction')} size="small">
              {list?.[index]?.[locale].recommendation || ' '}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={`${classes.cardTranslate} ${classes.padding2}`}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box className={classes.translateContainer}>
              {icons(translate)}
              <Typography className={classes.translateName}>{I18n.t(translateData.name)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <TextField
              value={list?.[index]?.[translate]?.description || ' '}
              name="description"
              onChange={(e) => onChange(e, list?.[index]?.id, true)}
              className={classes.input}
              label={I18n.t('exclusionConditions.form.labelTextField')}
              multiline
              rows={4}
              size="small"
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              value={list?.[index]?.[translate]?.recommendation || ' '}
              name="recommendation"
              onChange={(e) => onChange(e, list?.[index]?.id, true)}
              className={classes.input}
              label={I18n.t('exclusionConditions.form.exclusionInstruction')}
              multiline
              rows={4}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Step2
