import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import I18n from "i18n-js";
import QuestionnairesContext from "../../../../context/questionnairesContext";
import useStyles, { styles } from "../styles";
import { useContext, useEffect, useState } from "react";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";
import { LayoutButtonLink } from "../../../../components/btnBN/layoutButtonLink";
import { useStylesSurveys } from "../../../../hooks/useStylesSurveys";
import { questionnaires } from "../../../../service/questionnaire";
import { WrapperColors } from "../../../../components/inputWrappers/wrapperColors";
import { WrapperImages } from "../../../../components/inputWrappers/wrapperImages";
import { SmallSpinner } from "../../../../components/spinnerProgress/smallSpinner";

const StepThree = () => {
  const classes = useStyles();
  const { step, setStep, dataStyles, data, enableSent, setEnableSent } =
    useContext(QuestionnairesContext);

  const {
    saveColors,
    saveLogo,
    saveBackground,
    logo,
    background,
    contrastBackground,
    saveContrastBackground,
  } = useStylesSurveys();
  const [colorImage, setColorImage] = useState(true);
  const typeImage = [
    "image/png",
    "image/jpg",
    "image/tif",
    "image/gif",
    "image/jpeg",
  ];
  const { id } = useParams();
  const [spinnerAct, setSpinnerAct] = useState(false);

  useEffect(() => {
    if (id && dataStyles?.contrast_background_url !== "") setColorImage(false); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

    const isColors =
      dataStyles?.primary &&
      dataStyles?.primary.match(regex) &&
      dataStyles?.secondary &&
      dataStyles?.secondary.match(regex) &&
      dataStyles?.option_text &&
      dataStyles?.option_text.match(regex) &&
      dataStyles?.error_text &&
      dataStyles?.error_text.match(regex) &&
      dataStyles?.contrast_secondary &&
      dataStyles?.contrast_secondary.match(regex) &&
      dataStyles?.contrast_primary &&
      dataStyles?.contrast_primary.match(regex);

    const isStyles =
      dataStyles?.logo_url &&
      dataStyles?.background_url &&
      ((!colorImage && dataStyles?.contrast_background_url) ||
        (colorImage && dataStyles.primary));

    isColors && isStyles ? setEnableSent(false) : setEnableSent(true);
    //eslint-disable-next-line
  }, [dataStyles, colorImage]);

  const handleSubmit = async () => {
    const body = { ...data };
    const bodyStyles = { ...dataStyles };

    const sections_ = body.sections.map((item) => {
      delete item.key;
      const questions_ = item.questions.map((element, index) => {
        element.position = index;
        delete element.key;
        delete element.used;
        return element;
      });
      item.questions = questions_;
      return item;
    });
    body.sections = sections_;
    if (!body.instructions || (body.instructions && body.instructions === ""))
      body.instructions = null;

    if (!bodyStyles.background_url?.name) delete bodyStyles.background_url;

    if (!bodyStyles.contrast_background_url?.name)
      delete bodyStyles.contrast_background_url;

    if (!bodyStyles.logo_url?.name) delete bodyStyles.logo_url;

    if (colorImage) bodyStyles.contrast_background_url = bodyStyles.primary;

    let resp;
    id
      ? (resp = await questionnaires.editQuestionnaire(id, body))
      : (resp = await questionnaires.createQuestionnaire(body));

    var form_data = new FormData();
    for (let clave in bodyStyles) {
      form_data.append(clave, bodyStyles[clave]);
    }

    let respStyles = await questionnaires.createStyles(
      id ? id : resp.data.data.id,
      form_data
    );

    if (respStyles) setStep(step + 1);
  };

  return (
    <Paper
      elevation={5}
      sx={{ borderRadius: "20px" }}
      className={classes.bodyStepThree}
    >
      <Box className={classes.bodyInputs}>
        <Grid container spacing={2}>
          <Grid item container lg={3} xs={6} spacing={5}>
            <Grid item>
              <Box>
                <Typography sx={{ fontSize: "18px", marginBottom: "3px" }}>
                  {I18n.t("questionnaries.title.generalColors")}
                </Typography>
                <WrapperColors
                  required={true}
                  name="primary"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.mainColor")}
                  value={dataStyles?.primary}
                />
                <WrapperColors
                  required={true}
                  name="secondary"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.secondaryColor")}
                  value={dataStyles?.secondary}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography sx={{ fontSize: "18px", marginBottom: "3px" }}>
                  {I18n.t("questionnaries.title.stockColors")}
                </Typography>
                <WrapperColors
                  required={true}
                  name="option_text"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.colorOptions")}
                  value={dataStyles?.option_text}
                />
                <WrapperColors
                  required={true}
                  name="error_text"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.errorColor")}
                  value={dataStyles?.error_text}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item container lg={3} xs={6} spacing={5}>
            <Grid item>
              <Box>
                <Typography sx={{ fontSize: "18px", marginBottom: "3px" }}>
                  {I18n.t("questionnaries.title.textFormat")}
                </Typography>
                <WrapperColors
                  required={true}
                  name="contrast_primary"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.mainColor")}
                  value={dataStyles?.contrast_primary}
                />
                <WrapperColors
                  required={true}
                  name="contrast_secondary"
                  onChange={saveColors}
                  label={I18n.t("questionnaries.form.secondaryColor")}
                  value={dataStyles?.contrast_secondary}
                />
              </Box>
            </Grid>
            <Grid item sx={{ height: "258px" }}>
              <Typography sx={{ fontSize: "18px", marginBottom: "3px" }}>
                {I18n.t("questionnaries.title.logo")}
              </Typography>
              <WrapperImages
                value={logo}
                name="logo_url"
                onChange={saveLogo}
                image={
                  dataStyles.logo_url
                    ? dataStyles.logo_url.name
                      ? URL.createObjectURL(dataStyles.logo_url ?? "")
                      : dataStyles.logo_url
                    : null
                }
                required={true}
                type={typeImage}
                accept="image/png,image/jpeg"
                label={I18n.t("questionnaries.form.logoImage")}
                sx={{
                  flex: 1,
                  "& .MuiInputBase-input": {
                    paddingRight: "5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item container lg={6} xs={12} spacing={5}>
            <Grid item>
              <Typography sx={{ fontSize: "18px" }}>
                {I18n.t("questionnaries.title.backgroundSettings")}
              </Typography>

              <RadioGroup>
                <FormControlLabel
                  value="useMainColor"
                  onClick={() => setColorImage(true)}
                  control={<Radio checked={colorImage} />}
                  label={I18n.t(
                    "questionnaries.form.useMainColorSolidBackground"
                  )}
                />
                <FormControlLabel
                  onClick={() => setColorImage(false)}
                  value="useBackground"
                  control={<Radio checked={!colorImage} />}
                  label={I18n.t("questionnaries.form.useBackgroundImage")}
                />
              </RadioGroup>

              <Box sx={styles.backgroundFiles}>
                {colorImage ? (
                  <WrapperColors
                    name="primary"
                    onChange={saveColors}
                    label={I18n.t("questionnaries.form.secondaryColor")}
                    value={dataStyles?.primary}
                  />
                ) : (
                  <WrapperImages
                    value={contrastBackground}
                    name="contrast_background_url"
                    onChange={saveContrastBackground}
                    required={true}
                    type={typeImage}
                    accept="image/png,image/jpeg"
                    label={I18n.t("questionnaries.form.contrastImage")}
                    image={
                      dataStyles.contrast_background_url
                        ? dataStyles?.contrast_background_url?.name
                          ? URL.createObjectURL(
                              dataStyles.contrast_background_url
                            )
                          : dataStyles?.contrast_background_url
                        : null
                    }
                    sx={{
                      flex: 1,
                      marginBottom: "12px",
                      "& .MuiInputBase-input": {
                        paddingRight: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item sx={{ height: "192px", width: "90%" }}>
              <Box className={classes.backgroundSettings}>
                <WrapperImages
                  value={background}
                  name="background_url"
                  onChange={saveBackground}
                  required={true}
                  type={typeImage}
                  accept="image/png,image/jpeg"
                  label={I18n.t("questionnaries.form.backgroundImage")}
                  image={
                    dataStyles.background_url
                      ? dataStyles.background_url.name
                        ? URL.createObjectURL(dataStyles.background_url ?? "")
                        : dataStyles.background_url
                      : null
                  }
                  sx={{
                    flex: 1,

                    "& .MuiInputBase-input": {
                      paddingRight: "5px",

                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.buttons}>
        <LayoutButtonLink
          label={I18n.t("general.cancelBtn")}
          link={"/dashboard/questionnaires"}
        />
        <Box className={classes.nextBack}>
          <LayoutButton
            variant="outlined"
            label={I18n.t("general.backBtn")}
            click={() => setStep(step - 1)}
          />
          {spinnerAct ? (
            <SmallSpinner
              sx={{
                display: "flex",
                justifyContent: "center",
                marginRight: "32px",
              }}
            />
          ) : (
            <LayoutButton
              disabled={enableSent}
              variant="contained"
              label={I18n.t("general.nextBtn")}
              click={() => {
                setSpinnerAct(true);
                handleSubmit();
              }}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default StepThree;
