import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Login from '../pages/Login'
import Dashboard from '../pages/dashboard/Dashboard'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import NotFound from '../pages/404'
import RequestResetPassword from '../pages/requestResetPassword'
import RessetPassword from '../pages/ressetPassword'
import LogOut from '../pages/logOut'

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRoutes path="/dashboard" component={Dashboard} />

                <PublicRoutes exact path="/resetpassword" component={RequestResetPassword} />
                <PublicRoutes exact path="/resetpassword/:token" component={RessetPassword} />
                <PublicRoutes exact path="/" component={Login} />
                <Route exact path="/logout" component={LogOut} />

                <Route path="/404" component={NotFound} />

                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes
