import { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { FiberManualRecord, Check } from "@mui/icons-material";
import clsx from "clsx";
import useStyles from "./style";
import I18n from "i18n-js";
import QontoConnector from "./conectorStyles";

const StepperCreate = ({ activeStep, steps = [], color = "secondary" }) => {
  const [width, setWidth] = useState(
    document.getElementById("step")?.clientWidth || 280
  );

  const stepper = document.getElementById("stepper");

  const observerWidth = new ResizeObserver(() => {
    return setWidth(document.getElementById("step")?.clientWidth);
  });
  if (stepper) {
    observerWidth.observe(stepper);
  }
  useEffect(() => {
    return () => setWidth(document.getElementById("step")?.clientWidth);
  }, []);

  const classes = useStyles(width, color);
  //steps icons
  const stepIcon = (props) => {
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes.iconDisabled, {
          [classes.iconActive]: active,
          [classes.iconComplete]: completed,
        })}
      >
        <FiberManualRecord
          className={clsx(classes.roundedIcon, {
            [classes.roundedActive]: active,
            [classes.roundedComplete]: completed,
          })}
        />
      </div>
    );
  };
  const stepFinish = (props) => {
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes.iconDisabled, {
          [classes.iconActive]: active,
          [classes.iconComplete]: completed,
        })}
      >
        <Check
          className={clsx(classes.roundedIcon, {
            [classes.roundedActive]: active,
            [classes.roundedComplete]: completed,
          })}
        />
      </div>
    );
  };
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      color="secondary"
      connector={<QontoConnector width={width} color={color} />}
      className={classes.stepper}
      id="stepper"
    >
      {steps.map((item, index) => {
        return (
          <Step className={classes.step} key={index} id="step">
            <StepLabel
              className={classes.stepLabel}
              StepIconComponent={stepIcon}
            >
              {item.step && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.textStep}
                >
                  {I18n.t(item.step)}
                </Typography>
              )}

              {item.label && (
                <Typography variant="caption" className={classes.textLabelStep}>
                  {I18n.t(item.label)}
                </Typography>
              )}
            </StepLabel>
          </Step>
        );
      })}
      <Step className={classes.stepEnd}>
        <StepLabel
          className={classes.stepLabel}
          StepIconComponent={stepFinish}
        ></StepLabel>
      </Step>
    </Stepper>
  );
};

export default StepperCreate;
