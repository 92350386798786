import { IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { icons } from "../../../../utils/icons";
import I18n from "i18n-js";
import useStyles from "../../question/styles";
import { styles } from "../../question/styles";
import { Box } from "@mui/system";
import Answer from "../../../../components/answer";
import { useContext, useEffect, useState } from "react";
import QuestionContext from "../../../../context/questionContext";
import enumsTypeQuestion from "../../../../enums/typeOfQuestion";
import NumericScale from "../../../../components/numericScale";

const NotChecked = (props) => {
  const { setDisabledOne, valueSwitch } = props;
  const {
    typeOfQuestion,
    userLocale,
    data,
    addOptions,
    setData,
    disabledThree,
    setDisabledThree,
    setTypeOfQuestion,
    setIsLoading,
    questionTypes,
    isEdition,
    dataOpen,
    setDataOpen,
  } = useContext(QuestionContext);
  const [description, setDescription] = useState();
  const classes = useStyles();
  const [translation, setTranslation] = useState();
  const [vecScaleAux, setVecScaleAux] = useState();
  useEffect(() => {
    if (typeOfQuestion) {
      setDisabledThree(!description);
    }
  }, [description, setDisabledThree, typeOfQuestion]);
  useEffect(() => {
    if (isEdition) {
      if (data?.opcions) {
        setTranslation(data?.options ?? "");
        setVecScaleAux([data?.options[0] ?? "", data?.options[1] ?? ""]);
      }
    }

    setIsLoading(false);
    if (data.options) setDisabledOne(false); //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (typeOfQuestion !== enumsTypeQuestion.escalar_numeric) {
      if (data?.options?.length === 0) setDisabledOne(true);
      if (
        data?.options?.length > 0 &&
        typeOfQuestion !== enumsTypeQuestion.open
      )
        setDisabledOne(false);
    }

    if (typeOfQuestion === enumsTypeQuestion.open) {
      if (data.options) {
        if (data?.options[0].title) {
          const val = data?.options[0]?.title.find(
            (item) => item.locale === userLocale
          ).value;

          if (val && val !== "") setDisabledOne(false);
          if (!val || val === "") setDisabledOne(true);
        }
      }
    }
    //eslint-disable-next-line
  }, [data?.options]);
  useEffect(() => {
    if (
      typeOfQuestion === enumsTypeQuestion.open &&
      (!data.options || (data.options && data.options[0].title.length === 1))
    ) {
      setData({ ...data, options: dataOpen });
    }

    //eslint-disable-next-line
  }, [dataOpen]);

  const insertOption = () => {
    const date = new Date();
    addOptions({
      title: [{ value: description, locale: userLocale }],
      key: date.getTime(),
      id: null,
      option_group_id: !data.option_group_id ? null : data.option_group_id,
      weight: null,
    });
    setDescription("");

    setDisabledOne(false);
  };
  const buildTitle = (value) => {
    return {
      value: value,
      locale: userLocale,
    };
  };
  const deleteOption = (key) => {
    let auxOptions = data?.options.filter((option) => {
      return option.key !== key;
    });
    setTranslation(auxOptions);
    setData({ ...data, options: auxOptions });

    if (data?.options.length === 0) {
      setDisabledOne(true);
    }
  };

  const handleChange = (e) => setDescription(e.target.value);

  const handleChangeType = (e) => {
    const { value, name } = e.target;

    if (data.options) {
      if (
        value === enumsTypeQuestion.escalar_numeric &&
        typeOfQuestion !== enumsTypeQuestion.open
      ) {
        setTranslation(data.options);
        delete data.options;
        setData({
          ...data,
          [name]: value,
          option_group_id: data.option_group_id ? data.option_group_id : null,
        });
        if (vecScaleAux) {
          setData({
            ...data,
            [name]: value,
            option_group_id: data.option_group_id ? data.option_group_id : null,
            options: vecScaleAux,
          });
        }
      } else if (
        value === enumsTypeQuestion.open &&
        typeOfQuestion !== enumsTypeQuestion.escalar_numeric
      ) {
        setTranslation(data.options);
        delete data.options;
        setData({
          ...data,
          [name]: value,
          option_group_id: data.option_group_id ? data.option_group_id : null,
        });
        if (dataOpen) {
          setData({
            ...data,
            [name]: value,
            option_group_id: data.option_group_id ? data.option_group_id : null,
            options: dataOpen,
          });
        }
      } else {
        if (typeOfQuestion === enumsTypeQuestion.escalar_numeric) {
          setVecScaleAux(data.options);
          delete data.options;
        }
      }
    }
    if (!data.options && !vecScaleAux) {
      setData({
        ...data,
        [name]: value,
        instructions: !data.instructions ? [] : data.instructions,
        option_group_id: data.option_group_id ? data.option_group_id : null,
      });
    }
    if (
      value !== enumsTypeQuestion.escalar_numeric &&
      value !== enumsTypeQuestion.open
    ) {
      if (typeOfQuestion === enumsTypeQuestion.open) {
        delete data.options;
        if (translation) {
          setData({
            ...data,
            options: translation,
            [name]: value,
            option_group_id: data.option_group_id ? data.option_group_id : null,
          });
        }
      }
      if (translation && typeOfQuestion === enumsTypeQuestion.escalar_numeric)
        setData({
          ...data,
          options: translation,
          [name]: value,
          option_group_id: data.option_group_id ? data.option_group_id : null,
        });
    }

    if (
      typeOfQuestion === enumsTypeQuestion.open &&
      value === enumsTypeQuestion.escalar_numeric
    ) {
      delete data.options;
      setData({
        ...data,
        [name]: value,
        option_group_id: data.option_group_id ? data.option_group_id : null,
        options: vecScaleAux,
      });
    }

    if (
      typeOfQuestion === enumsTypeQuestion.escalar_numeric &&
      value === enumsTypeQuestion.open
    ) {
      delete data.options;
      setData({
        ...data,
        [name]: value,
        option_group_id: data.option_group_id ? data.option_group_id : null,
        options: dataOpen,
      });
    }

    setTypeOfQuestion(value);
    if (!data.options) setDisabledOne(true);
    // if (value === enumsTypeQuestion.open) setDisabledOne(false);
  };

  const handleOpenWeight = (e) => {
    if (!dataOpen) {
      setDataOpen([
        {
          key: 0,
          id: null,
          option_group_id: data.option_group_id ? data.option_group_id : null,
          weight: e.target.value,
        },
      ]);
    } else {
      const opcions_ = dataOpen.map((item, key) => {
        return { ...item, weight: e.target.value };
      });
      setDataOpen(opcions_);
    }
  };

  const handleOpen = (e) => {
    if (!dataOpen) {
      setDataOpen([
        {
          key: 0,
          weight: null,
          id: null,
          option_group_id: data.option_group_id ? data.option_group_id : null,
          title: [buildTitle(e.target.value)],
        },
      ]);
    }
    if (dataOpen) {
      const opcions_ = dataOpen.map((item, key) => {
        if (!item.title) {
          return { ...item, title: [buildTitle(e.target.value)] };
        } else {
          const title_ = item.title.map((element, key) => {
            return element.locale === userLocale
              ? buildTitle(e.target.value)
              : element;
          });
          return { ...item, title: title_ };
        }
      });

      setDataOpen(opcions_);
    }
  };

  const returnValueOpen = () => {
    if (!dataOpen) return undefined;
    return dataOpen[0]?.title?.find((item) => item.locale === userLocale).value;
  };

  const validarEntradasConPunto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9-]|(?<=.)-/, "");
  };
  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };
  return (
    <Box className={classes.notCheck}>
      <Box className={classes.zoneAddAnswer}>
        <TextField
          fullWidth
          select
          label={I18n.t("question.form.typeOfQuestion")}
          name="question_type_id"
          onChange={handleChangeType}
          value={typeOfQuestion ?? ""}
          sx={{ width: { xs: "auto", md: "50%" } }}
          required
        >
          {questionTypes?.map((item, key) => (
            <MenuItem
              sx={{
                maxWidth: "370px",
              }}
              key={item.question_type_id}
              value={item.question_type_id}
            >
              <Typography sx={styles.itemsSelect}>{item.name}</Typography>
            </MenuItem>
          ))}
        </TextField>
        <Box className={classes.containerToAdd}>
          <TextField
            fullWidth
            name="options"
            onChange={handleChange}
            sx={{
              width: "100%",
              display:
                typeOfQuestion === enumsTypeQuestion.open ||
                typeOfQuestion === enumsTypeQuestion.escalar_numeric
                  ? "none"
                  : "flex",
            }}
            value={description ?? ""}
            inputProps={{ maxLength: 255 }}
            onInput={(e) => validateInput(e)}
          />

          <IconButton
            disabled={disabledThree}
            sx={{
              margin: "10px 3px",
              display:
                typeOfQuestion === enumsTypeQuestion.open ||
                typeOfQuestion === enumsTypeQuestion.escalar_numeric
                  ? "none"
                  : "block",
            }}
            className={classes.plus}
            onClick={() => insertOption()}
          >
            {icons("plus")}{" "}
          </IconButton>
        </Box>
      </Box>
      <Typography variant="caption">
        {I18n.t("question.pleaseSortTheAnswersThat")}
      </Typography>
      {typeOfQuestion === enumsTypeQuestion.open && (
        <Box
          sx={{
            display:
              typeOfQuestion === enumsTypeQuestion.open ? "flex" : "none",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <TextField
            className={classes.textInputOpen}
            fullWidth
            sx={{ margin: "30px 0px 10px 0px" }}
            value={returnValueOpen() ?? ""}
            name="value"
            label={I18n.t("question.form.answer")}
            onChange={(e) => handleOpen(e)}
            onInput={(e) => validateInput(e)}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            onInput={validarEntradasConPunto}
            name="weight"
            onChange={(e) => handleOpenWeight(e)}
            value={(dataOpen && dataOpen[0]?.weight) ?? ""}
            sx={styles.inputOpen}
            label={I18n.t("question.form.value")}
            inputProps={{ maxLength: 9 }}
          />
        </Box>
      )}

      {typeOfQuestion !== enumsTypeQuestion.open && (
        <Box>
          <Answer
            deleteOption={deleteOption}
            options={data?.options}
            typeOfQuestion={typeOfQuestion}
            valueSwitch={valueSwitch}
          />
        </Box>
      )}
      {typeOfQuestion === enumsTypeQuestion.escalar_numeric && (
        <NumericScale
          disabled={true}
          setDisabledOne={setDisabledOne}
          arrayTranslations={data?.options}
        />
      )}
    </Box>
  );
};

export default NotChecked;
