import { Box, Breadcrumbs } from '@mui/material'
import I18n from 'i18n-js'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Associate from '../../../components/associate'
import BtnBN from '../../../components/btnBN'
import Loading from '../../../components/loading'
import { exclusion } from '../../../service/exclusion'
import LinkStyles from '../../../themes/js/Link'
import Finally from '../form/steps/finally'
import useStyles from './styles'

const ExclusionAssociate = () => {
  const history = useHistory()
  const classes = useStyles()
  const [listSurvey, setListSurvey] = useState()
  const [associateData, setAssociateDaata] = useState()
  const [isLoadind, setIsLoading] = useState(true)
  const { id } = useParams()
  const [sentSuccessfully, setSentSuccessfully] = useState(false)

  const getData = useCallback(async () => {
    const res = await exclusion.listSurveys()

    const detail = await exclusion.detailSurveys(id)

    if (detail.data !== null) {
      const listSurveys = detail.data.rejectionReasonSurvey?.map((item) => item?.survey_id) ?? []

      setAssociateDaata({
        indicators: listSurveys,
      })
    }

    const data = res?.data?.surveys?.map((item) => ({
      ...item,
      name: item?.title,
    }))
    setListSurvey(data || [])
    setIsLoading(false)
  }, [id])

  const handleStep = async (data = null) => {
    const body = {
      rejection_reason_id: parseInt(id),
      surveys: associateData?.indicators ?? [],
    }

    const res = await exclusion.associateReason(body)

    if (res?.data !== 'null') {
      setSentSuccessfully(true)
    }
  }

  useEffect(() => {
    getData()
  }, [getData])

  if (isLoadind) return <Loading />

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Breadcrumbs className={classes.link} aria-label="breadcrumb">
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t('drawer.dashboard')}
          </Link>

          <Link style={LinkStyles} to={`/dashboard/exclusion`}>
          {I18n.t('exclusionConditions.breadcrumbs.title')}
          </Link>

          <Link style={LinkStyles} to={history.location.pathname}>
            {I18n.t('exclusionConditions.breadcrumbs.associate')}
          </Link>
        </Breadcrumbs>
      </Box>

      {listSurvey?.length >= 0 && !sentSuccessfully ? (
        <>
          <Box className={classes.associate}>
            <Associate values={associateData} setCreateProfil={setAssociateDaata} dataIndicators={listSurvey} title={I18n.t('exclusionConditions.associate.indicatorsTitle')}/>
          </Box>

          <Box className={classes.btns}>
            <BtnBN back={'/dashboard/exclusion'} handleStep={handleStep} />
          </Box>
        </>
      ) : (
        <Finally title={I18n.t('exclusionConditions.finally.title')} subtitle={I18n.t('exclusionConditions.associate.finally')} to="/dashboard/exclusion" btn={I18n.t('exclusionConditions.finally.return')} />
      )}
    </Box>
  )
}

export default ExclusionAssociate
