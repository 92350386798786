import { useState } from "react";
import { questionnaires } from "../service/questionnaire";

export const useQuestionnaireTranslations = () => {
  const [data, setData] = useState();
  const [saved, setSaved] = useState(false);

  const isSection = (setView, idPage, setOpen) => {
    if (data.sections.length) {
      setView(false);
    } else {
      dataSubmit(idPage, setOpen);
    }
  };

  const dataControl = (idPage, setOpen) => {
    if (verifyData()) {
      setOpen(true);
    } else {
      dataSubmit(idPage, setOpen);
    }
  };

  const verifyData = () => {
    let emptyDataFound;

    emptyDataFound = data?.title.find(
      (item) => !item?.value || item?.value === ""
    );
    if (emptyDataFound) return true;

    if (data.instructions)
      emptyDataFound = data.instructions.find(
        (item) => !item?.value && item?.value === ""
      );
    if (emptyDataFound) return true;

    emptyDataFound = data.description.find(
      (item) => !item?.value && item?.value === ""
    );
    if (emptyDataFound) return true;

    emptyDataFound = data?.sections?.find((item) => {
      let exis = item?.title?.find(
        (element) => !element?.value || element?.value === ""
      );
      if (exis) return exis;
      exis = item?.description.find(
        (element) => !element?.value || element?.value === ""
      );
      return exis;
    });

    if (emptyDataFound) return true;
    if (!emptyDataFound) return false;
  };

  const dataSubmit = async (idPage, setOpen) => {
    setOpen(false);
    let resp;

    data.title = data?.title?.map((item) => {
      item.value = item?.value?.trim() ?? null;
      return item;
    });
    data.description = data?.description?.map((item) => {
      item.value = item?.value?.trim() ?? null;
      return item;
    });

    if (data.instructions) {
      data.instructions = data?.instructions.filter((item) => item !== null);

      data.instructions = data?.instructions?.map((item) => {
        if (item) item.value = item?.value?.trim() ?? null;
        return item;
      });
    }

    data.sections = data?.sections?.map((item) => {
      item.title = item?.title?.map((element) => {
        element.value = element?.value?.trim() ?? null;
        return element;
      });
      item.description = item?.description?.map((element) => {
        element.value = element?.value?.trim() ?? null;
        return element;
      });
      return item;
    });

    if (idPage)
      resp = await questionnaires.questionnaireTranslations(idPage, data);
    if (resp) setSaved(true);
  };

  const updateData = (value, locale, name) => {
    const existeTraduccion = data[name]?.find(
      (item) => item?.locale === locale
    );
    if (existeTraduccion) {
      const newData = data[name].map((item) => {
        if (item?.locale === locale) item.value = value;
        return item;
      });
      return newData;
    } else return [...(data[name] ?? []), { locale, value }];
  };

  const updateSections = (value, locale, name, id) => {
    const sections_ = data.sections.map((item) => {
      if (id === item.id) {
        const existeTraduccion = item[name]?.find(
          (element) => element.locale === locale
        );
        if (existeTraduccion) {
          const newData = item[name].map((element) => {
            if (element.locale === locale) element.value = value;
            return element;
          });
          item[name] = newData;
        } else {
          item[name] = [...(item[name] ?? []), { locale, value }];
        }
      }

      return item;
    });

    return sections_;
  };

  const handleTranslations = (e, locale) => {
    setData({
      ...data,
      [e.target.name]: updateData(e.target.value, locale, e.target.name),
    });
  };

  const showMainData = (keysObj, pages, language) => {
    return (
      data &&
      keysObj &&
      data[keysObj[pages]]?.find((item) => item?.locale === language)?.value
    );
  };

  const showSectionData = (keysObj, pages, locale, id) => {
    const sec = data.sections.find((item) => item.id === id);
    return sec[keysObj[pages]].find((element) => element.locale === locale)
      ?.value;
  };

  const handleSectionData = (e, locale, id) => {
    setData({
      ...data,
      sections: updateSections(e.target.value, locale, e.target.name, id),
    });
  };

  const loadData = (detail, setAllData, setDataSections) => {
    const detailTitle = detail.survey.translations.map((item) => {
      return {
        locale: item.locale,
        value: item.title ?? null,
      };
    });
    const detailDescription = detail.survey.translations.map((item) => {
      return {
        locale: item.locale,
        value: item.description ?? null,
      };
    });
    const detailInstructions = detail.survey.translations.map((item) => {
      return item.instructions === null
        ? null
        : {
            locale: item.locale,
            value: item.instructions ?? null,
          };
    });

    const sections_ = detail.survey.survey_sections.map((item) => {
      const titleSec = item.translations.map((element) => {
        return { locale: element.locale, value: element.title };
      });
      const descriptionSec = item.translations.map((element) => {
        return { locale: element.locale, value: element.description };
      });
      return {
        id: item.id,
        title: titleSec,
        description: descriptionSec,
      };
    });

    const exists = detailInstructions.find((item) => item !== null);

    if (exists) {
      setData({
        title: detailTitle,
        description: detailDescription,
        instructions: detailInstructions,
        sections: sections_,
      });
      setAllData({
        title: detailTitle,
        description: detailDescription,
        instructions: detailInstructions,
      });
    } else {
      setData({
        title: detailTitle,
        description: detailDescription,
        sections: sections_,
      });
      setAllData({
        title: detailTitle,
        description: detailDescription,
      });
    }

    setDataSections(sections_);
  };
  return {
    dataSubmit,
    handleTranslations,
    showMainData,
    showSectionData,
    handleSectionData,
    data,
    setData,
    saved,
    setSaved,
    loadData,
    verifyData,
    dataControl,
    isSection,
  };
};
