import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    width: "79.9%",
    marginTop: 28,
    [theme.breakpoints.down(1160)]: {
      alignSelf: "flex-start",
      marginLeft: "62px",
    },
  },
}));

export const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
    width: "100%",
  },
  bottomSection: {
    width: { xs: "570px", md: "750px", lg: "950px" },

    display: "flex",
    marginTop: "60px",
    flexDirection: "column",
  },
  existenceText: {
    marginTop: "12px",
    fontWeight: "bolder",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    width: { xs: "550px", md: "700px", lg: "900px" },
    marginTop: "18px",
  },
};
export default useStyles;
