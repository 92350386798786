import QuestionContext from "../../../../context/questionContext";
import TranslatorAsks from "../../../../components/translatorAsks";
import { useContext } from "react";
import useStyles from "../../question/styles";
import { Paper } from "@mui/material";
import numStep from "../../../../utils/questionStep";
import useOptionsTranslation from "../../../../hooks/useOptionsTranslations";

const StepFour = () => {
  const classes = useStyles();
  const { setSteps, userLocale, localesList, data } =
    useContext(QuestionContext);
  const { handleChangeOption, getOptionLocale, deleteData } =
    useOptionsTranslation();
  const labelContent = [];
  const nameVar = [];

  data.options?.map((option, key) => {
    return (nameVar[key] = "title");
  });

  const nameArray = [];
  data.options?.map((option, key) => {
    const label =
      option?.options?.find(({ locale }) => locale === userLocale)?.value ?? "";
    return (nameArray[key] = label);
  });

  return (
    <Paper
      elevation={5}
      className={classes.contTranslation}
      sx={{ width: "60%", borderRadius: "20px" }}
    >
      <TranslatorAsks
        contentAsk={data.options ?? ""}
        labelContent={labelContent}
        setSteps={setSteps}
        nextStep={numStep.five}
        backStep={numStep.three}
        nameVar={nameVar}
        arrayElement={localesList}
        handleChangeTraslation={handleChangeOption}
        getDataIdiom={getOptionLocale}
        data={data ?? ""}
        bam={true}
        deleteData={deleteData}
      />
    </Paper>
  );
};

export default StepFour;
