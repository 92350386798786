import { MenuItem, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { all } from "./../../../../service/CRUD";
import I18n from "i18n-js";
import { styles } from "../../question/styles";
import QuestionContext from "../../../../context/questionContext";
import useStyles from "../../question/styles";
import Answer from "../../../../components/answer";
import Loading from "../../../../components/loading";
import enumsTypeOfQuestion from "../../../../enums/typeOfQuestion";
import NumericScale from "../../../../components/numericScale";
import { SmallSpinner } from "../../../../components/spinnerProgress/smallSpinner";

const Checked = (props) => {
  const classes = useStyles();
  const {
    userLocale,
    setData,
    data,
    typeOfQuestion,
    setTypeOfQuestion,
    questionTypes,
    dataOptions,
    setDataOptions,
    groupName,
    setGroupName,
    arrayTranslations,
    setArrayTranslations,
  } = useContext(QuestionContext);
  const { setDisabledOne, valueSwitch, setActSpinner, actSpinner } = props;
  const [translation, setTranslation] = useState();

  useEffect(() => {
    if (!dataOptions) {
      all("options-group").then(({ data }) => {
        setDataOptions(data.options_group);
      });
    }
    if (!data.instructions) setData({ ...data, instructions: [] });
    if (data.options) setDisabledOne(false); //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    delete data.options;
    const { value } = e.target;
    setGroupName(e.target.value);
    setData({ ...data, option_group_id: value });
  };

  const handleChangeType = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
    if (value === enumsTypeOfQuestion.escalar_numeric) {
      setData({ ...data, [name]: value });
    } else {
      setData({ ...data, [name]: value, options: arrayTranslations });
    }

    setTypeOfQuestion(value);
    translation && setDisabledOne(false);
  };

  const displayList = async (id) => {
    const date = new Date();
    all(`options-group/${id}`).then(({ data: groups }) => {
      if (groups) {
        const { options: options_ } = groups?.option_group ?? {};
        const options = options_?.map((item, key) => {
          const { translations } = item;
          const option = translations.map(({ locale, title }) => ({
            locale: locale,
            value: title,
          }));
          return {
            key: date.getTime() + key,
            title: option,
            id: item.id,
            option_group_id: id,
            weight: null,
          };
        });
        setArrayTranslations(options);
        /*  if (typeOfQuestion) setData({ ...data, options: options }); */
        if (
          typeOfQuestion &&
          typeOfQuestion !== enumsTypeOfQuestion.escalar_numeric
        )
          setData({ ...data, options: options, option_group_id: id });
        const translation = options.map((item, key) => {
          const trans = item.title.find(
            ({ locale }) => locale === userLocale
          )?.value;
          return { translation: trans, id: item.id };
        });
        setTranslation(translation);
        if (typeOfQuestion) setActSpinner(true);
      }
    });
    typeOfQuestion && setDisabledOne(false);
  };

  if (!dataOptions) {
    return <Loading />;
  }
  return (
    <Box>
      <Box className={classes.contentorSelector}>
        <TextField
          select
          label={I18n.t("question.form.sampleGroupName")}
          name="groupName"
          onChange={handleChange}
          value={groupName ?? ""}
          fullWidth
          sx={{ width: { xs: "auto", md: "49%" } }}
        >
          {dataOptions?.map(
            (group) =>
              group.title && (
                <MenuItem
                  value={group.id}
                  key={group.id}
                  onClick={() => {
                    displayList(group.id);
                  }}
                >
                  {group.title}
                </MenuItem>
              )
          )}
        </TextField>
        <TextField
          select
          label={I18n.t("question.form.typeOfQuestion")}
          name="question_type_id"
          onChange={handleChangeType}
          value={typeOfQuestion ?? ""}
          fullWidth
          sx={{ width: { xs: "auto", md: "49%" } }}
          required
        >
          {questionTypes?.map(
            (item, key) =>
              item.question_type_id !== enumsTypeOfQuestion.open && (
                <MenuItem
                  sx={{
                    width: "370px",
                  }}
                  key={key}
                  value={item.question_type_id}
                >
                  <Typography sx={styles.itemsSelect}>{item.name}</Typography>
                </MenuItem>
              )
          )}
        </TextField>
      </Box>
      {(!data.options || !arrayTranslations) && actSpinner && <SmallSpinner />}
      {typeOfQuestion && (
        <Box className={classes.create}>
          <Answer
            options={data?.options}
            typeOfQuestion={typeOfQuestion}
            valueSwitch={valueSwitch}
          />
        </Box>
      )}
      {typeOfQuestion === enumsTypeOfQuestion.escalar_numeric &&
        arrayTranslations && (
          <NumericScale
            disabled={false}
            translation={translation}
            setDisabledOne={setDisabledOne}
            option_group_id={groupName}
            arrayTranslations={arrayTranslations}
            setArrayTranslations={setArrayTranslations}
          />
        )}
    </Box>
  );
};

export default Checked;
