import { colors } from '../../utils'

export const useStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    marginTop: 6,
  },
  icon: {
    '&.MuiIcon-root': {
      color: colors.blueLighten,
      fontSize: '3em',
      width: '100%',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  title: {
    '&.MuiTypography-root': {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.4em',
      margin: '20px 0',
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      fontSize: '1em',
      width: '100%',
      textAlign: 'center',
      color: colors.palette.greyDark,
    },
  },
  btn: {
    '&.MuiButton-root': {
      borderRadius: 0,
      marginTop: 5,
    },
  },
}
