import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    minHeight: window.innerHeight - 155,
    marginTop: 45,
    margin: 'auto',
    width: '80%',
    position: 'relative',
  },
  btns: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  associate: {
    marginTop: 70
  }
}))

export default useStyles
