import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  next: {
    '&.MuiButton-root': {
      borderRadius: 0,
    },
  },
  back: {
    '&.MuiButton-root': {
      borderRadius: 0,
      marginRight: 20,
      color: colors.blueLighten
    },
  },
})

export default useStyles
