import useStyles from "./styles";

const { FormControlLabel, Checkbox } = require("@mui/material");

const ItemGroupCheckBox = (props) => {
  const { item, handleCheck, element } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox checked={item.used} onChange={() => handleCheck(item.id)} />
      }
      label={item.title ?? ""}
      className={classes.itemsInTheList}
      sx={{
        "&.MuiFormControlLabel-root": {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        display:
          item.key === element.key || item.key === null ? "block" : "none",
      }}
    />
  );
};

export default ItemGroupCheckBox;
