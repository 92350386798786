import { Button, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import I18n from "i18n-js";
import useStyles from "../../question/styles";
import numStep from "../../../../utils/questionStep";
import QuestionContext from "../../../../context/questionContext";

import Loading from "../../../../components/loading";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";
import SimpleError from "../../../../components/errorMesage/simple";

const StepOne = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const {
    setSteps,
    disabledOne,
    setDisabledOne,
    typeOfQuestion,
    handleChangeTraslation,
    getDataIdiom,
    userLocale,
  } = useContext(QuestionContext);
  const data = getDataIdiom(userLocale);

  useEffect(() => {
    const emptyValues = [
      !!getDataIdiom(userLocale)?.title,
      !!getDataIdiom(userLocale)?.description,
    ];
    if (emptyValues.every((v) => v)) setDisabledOne(false);
    else setDisabledOne(true);
  }, [typeOfQuestion, data, setDisabledOne, getDataIdiom, userLocale]);

  const captureRequiredFields = () => {
    const enptyValues = [
      !!getDataIdiom(userLocale)?.title,
      !!getDataIdiom(userLocale)?.description,
    ];
    if (enptyValues.every((v) => v)) setSteps(numStep.two);
  };

  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  if (!data && id) return <Loading />;
  return (
    <Paper
      elevation={5}
      className={classes.formOne}
      sx={{ borderRadius: "20px" }}
    >
      <Box className={classes.textField}>
        <Box className={classes.titleInstructions}>
          <Box>
            <TextField
              required
              fullWidth
              label={I18n.t("question.form.title")}
              placeholder={I18n.t("question.form.sampleQuestionTitle")}
              value={getDataIdiom(userLocale)?.title ?? ""}
              onChange={(e) => handleChangeTraslation(e, userLocale)}
              onInput={(e) => validateInput(e)}
              name="title"
              error={getDataIdiom(userLocale)?.title === ""}
              inputProps={{ maxLength: 255 }}
            />
            <SimpleError
              error={getDataIdiom(userLocale)?.title === ""}
              message={I18n.t("users.messages.required")}
            />
          </Box>

          <TextField
            multiline
            label={I18n.t("question.form.instructions")}
            placeholder={I18n.t("question.form.sampleInstructions")}
            value={getDataIdiom(userLocale)?.instructions ?? ""}
            rows={4}
            onChange={(e) => handleChangeTraslation(e, userLocale)}
            onInput={(e) => validateInput(e)}
            name="instructions"
            inputProps={{ maxLength: 255 }}
          />
        </Box>
        <Box
          sx={{
            width: { sx: "auto", md: "48%" },
          }}
        >
          <TextField
            required
            multiline
            fullWidth
            label={I18n.t("question.form.description")}
            placeholder={I18n.t("question.form.sampleDescription")}
            value={getDataIdiom(userLocale)?.description ?? ""}
            rows={8}
            error={getDataIdiom(userLocale)?.description === ""}
            onChange={(e) => handleChangeTraslation(e, userLocale)}
            onInput={(e) => validateInput(e)}
            name="description"
            inputProps={{ maxLength: 255 }}
          />
          <SimpleError
            error={getDataIdiom(userLocale)?.description === ""}
            message={I18n.t("users.messages.required")}
          />
        </Box>
      </Box>
      <Box className={classes.buttonStepOne}>
        <Button>
          <Link
            className={classes.link}
            to={"/dashboard/question/questionlist"}
          >
            {I18n.t("general.cancelBtn")}
          </Link>
        </Button>
        <LayoutButton
          disabled={disabledOne}
          click={() => {
            captureRequiredFields();
          }}
          variant="contained"
          label={I18n.t("general.nextBtn")}
        />
      </Box>
    </Paper>
  );
};

export default StepOne;
