import { makeStyles } from "@mui/styles";
import colors from "../colors/colors";

const DrawerListStyle = makeStyles({
  list: (open) => ({
    transition: ".3s",
    width: open ? 210 : 60,
    height: "100%",
    background: colors.palette.primary,
    color: "#ddd",
    "&.MuiList-root": {
      paddingLeft: 30,
    },
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&&::-webkit-scrollbar-thumb": {
      background: colors.scroll.primary,
      borderRadius: 5,
    },
  }),
  icons: {
    margin: "25px 15px 0 0",
    fontSize: 24,
  },
  circleIcon: {
    color: colors.palette.blue,
    background: colors.palette.white,
    padding: 3,
    borderRadius: "50%",
  },
  close: {
    color: colors.palette.white,
    position: "relative",
    left: 150,
  },
  logo: (open) => ({
    transition: ".4s",
    margin: "0 auto",
    width: open ? 100 : 70,
    position: "relative",
    left: open ? 0 : -20,
  }),
  text: {
    position: "relative",
    top: -5,
    display: "inline",
    marginRight: 10,
    "&.MuiTypography-root": {
      color: colors.typography.primary,
    },
  },
  subtext: {
    "&.MuiTypography-root": {
      color: colors.typography.primary,
    },
  },
  collapse: (open) => ({
    display: "flex",
    position: "relative",
    left: open ? 20 : -5,
    bottom: 10,
    marginTop: 20,
    padding: 5,
    cursor: 'pointer'
  }),
  collapseIcon: {
    marginRight: -15,
    "&.MuiListItemIcon-root": {
      color: colors.palette.white,
    },
    fontSize: 10,
  },
});

export default DrawerListStyle;
