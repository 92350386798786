import { Breadcrumbs, Typography } from '@mui/material'
import { Box } from '@mui/system'
import I18n from 'i18n-js'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import BtnBN from '../../../components/btnBN'
import StepperCreate from '../../../components/stepperCreate'
import LinkStyles from '../../../themes/js/Link'
import languages from '../../../utils/languages'
import { format } from './methods'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import useStyles from './styles'
import { exclusion } from '../../../service/exclusion'
import Finally from './steps/finally'
import AlertModal from '../../../components/alertModal'
import Loading from '../../../components/loading'

const FormExclusion = () => {
  const history = useHistory()
  const params = useParams()
  const classes = useStyles()
  const [id, setId] = useState(0)
  const [list, setList] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [flat, setFlat] = useState(false)
  const locale = localStorage.getItem('lang')
  const translate = languages?.find((item) => item?.locale !== locale)?.locale
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const steps = [
    {
      step: 'general.step1',
      label: params?.id ? 'exclusionConditions.form.step1M' : 'exclusionConditions.form.step1',
    },
    {
      step: 'general.step2',
      label: 'exclusionConditions.form.step2',
    },
  ]

  const handleChange = ({ target }, id, traduction = null) => {
    const { name, value } = target

    const listCopy = list?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [traduction === null ? locale : translate]: {
            ...item?.[traduction === null ? locale : translate],
            [name]: value,
          },
        }
      }

      return item
    })

    setList(listCopy)
  }

  const handleList = () => {
    setList([
      ...list,
      {
        id: id,
        [locale]: {
          description: null,
          recommendation: null,
        },
        [translate]: {
          description: null,
          recommendation: null,
        },
      },
    ])

    setId(id + 1)
  }

  const handleDelete = (id) => {
    setList(list?.filter((item) => item.id !== id))
  }

  const handleSubmit = () => {
    const allTranslate = list.find((item) => item?.[translate] === undefined || item?.[translate]?.description?.trim() === '')
    if (allTranslate !== undefined) setOpenModal(true)
    else handleSubmitData()
  }

  const handleSubmitData = async () => {
    let newList = []

    list.forEach((item, index) => {
      const body = format(item, index, locale, translate)
      newList.push(body)
    })

    if (params.id) {
      const resp = await exclusion.updateExclusion(newList?.[0], params.id)

      if (resp?.data !== null) {
        setActiveStep(activeStep + 1)
      }
    } else {
      const resp = await exclusion.createExclusion(newList)

      if (resp?.data !== null) {
        setActiveStep(activeStep + 1)
      }
    }
  }

  const handleStep = (value) => {
    if (activeStep + value === 2) {
      handleSubmit()
    } else {
      setActiveStep(activeStep + value)
    }
  }

  const handleDisabled = () => {
    const required = list.find((item) => item?.[locale]?.description?.trim() === '')

    return list.length <= 0 || required !== undefined
  }

  const getData = useCallback(async () => {
    const detail = await exclusion.detail(params.id)

    const list = {}

    detail?.data?.rejection_reason.translations.forEach((item) => {
      list[item.locale] = {
        description: item.description,
        recommendation: item.recommendations,
      }
    })

    setList([
      {
        id: detail.data.rejection_reason.position,
        ...list,
      },
    ])

    setIsLoading(false)
    setFlat(true)
  }, [params?.id])

  useEffect(() => {
    if (!flat && params?.id !== undefined) {
      getData()
    } else {
      setIsLoading(false)
    }
  }, [flat, getData, params?.id])

  if (isLoading) return <Loading />

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Breadcrumbs className={classes.link} aria-label="breadcrumb">
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t('drawer.dashboard')}
          </Link>

          <Link style={LinkStyles} to={`/dashboard/exclusion`}>
            {I18n.t('exclusionConditions.breadcrumbs.title')}
          </Link>

          <Link style={LinkStyles} to={history.location.pathname}>
            {I18n.t(params.id ? 'exclusionConditions.breadcrumbs.update' : 'exclusionConditions.breadcrumbs.create')}
          </Link>
        </Breadcrumbs>

        <Box className={classes.containerTitle}>
          <Typography variant="body1" className={classes.title}>
            {I18n.t(params.id ? 'exclusionConditions.header.update' : 'exclusionConditions.header.create')}
          </Typography>
        </Box>

        <Box className={classes.headerSections}>
          <StepperCreate activeStep={activeStep} steps={steps} />
        </Box>
      </Box>

      <Box mt={2}>
        {activeStep === 0 && <Step1 locale={locale} onChange={handleChange} handleDelete={handleDelete} list={list} handleList={handleList} id={params.id} />}

        {activeStep === 1 && <Step2 locale={locale} translate={translate} onChange={handleChange} list={list} />}

        {activeStep === 2 && (
          <Finally
            title={I18n.t('exclusionConditions.finally.title')}
            subtitle={params?.id ? I18n.t('exclusionConditions.finally.subtitle') : I18n.t('exclusionConditions.associate.createFinally')}
            to="/dashboard/exclusion"
            btn={I18n.t('exclusionConditions.finally.return')}
          />
        )}
      </Box>

      {activeStep < 2 && (
        <Box className={classes.btns}>
          <BtnBN step={activeStep} back={'/dashboard/exclusion'} handleStep={handleStep} disabled={handleDisabled()} />
        </Box>
      )}

      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('traductions.missingTraductionTitle')}
        message={I18n.t('traductions.missingTraductiontext')}
        SuccessText={I18n.t('traductions.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default FormExclusion
