import { Chart as ChartJS, registerables  } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { Box, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { icons } from '../../utils'
import useStyles from './styles'
import I18n from 'i18n-js'
ChartJS.register(...registerables)

const Graphic = ({
  track,
  datasets,
  carrousel = false,
}) => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const [data, setData] = useState({
    datasets: [track],
  })
  
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, dataIndex } = context
            
            if (context?.dataset?.id) 
              return dataset?.trackOption?.[dataIndex] !== undefined ? 
                        ` ${dataset?.trackOption[dataIndex]}` 
                        : 
                        ` ${dataset?.trackOption[dataIndex - 1]} (${I18n.t('listView.graphic.response')})`
            
            return ` ${dataset.data[dataIndex].y.toFixed(5)}`
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          text: I18n.t('listView.graphic.time'),
          color: 'black',
          display: true,
        },
        ticks: {
          font: {
            size: 10,
          },
          backgroundColor: 'red', // not working
          color: 'black', // worked
        },
      },
      y: {
        title: {
          text: I18n.t('listView.graphic.value'),
          color: 'black',
          display: true,
        },
        ticks: {
          stepSize: 0.0,
        },
      },
    },
  }
  
  useEffect(() => {
    if (!carrousel) {
      let min = 0

      datasets.data.forEach((item) => {
        if (min === 0) min = item.y

        min = item.y < min ? item.y : min
      })

      setData({
        datasets: [ {...track, data: track.data?.map((item) => ({ x: item.x, y: min })) }, datasets],
      })
    } else {
      setData({
        datasets: [track, Object.values(datasets)[step]],
      })
    }
  }, [track, carrousel, datasets, step])

  const handleSteps = (value) => {
    const length = Object.values(datasets).length
    let B_N = 0

    if (step === 0 && value < 0) B_N = length - 1
    else if (step === length - 1 && value > 0) B_N = 0
    else B_N = step + value

    setData({ datasets: [track, Object.values(datasets)[B_N]] })
    setStep(B_N)
  }

  return (
    <Box className={classes.container}>
      <Chart data={data} options={options} type="scatter" />

      {carrousel && (
        <Box className={classes.buttons}>
          <IconButton onClick={() => handleSteps(-1)}>{icons('back')}</IconButton>
          <IconButton onClick={() => handleSteps(1)}>{icons('next')}</IconButton>
        </Box>
      )}
    </Box>
  )
}

export default Graphic
