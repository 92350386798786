import { Grid, Typography, FormControl, TextField, Box, Hidden, Button } from '@mui/material'
import { useState } from 'react'
import { requestRessetPassword } from '../../service/service'
import LoginStyle from '../../themes/js/Login'
import { isEmail } from '../../validator/validator'
import loginMessage from '../../utils/loginMessage.json'
import { LoadingButton } from '@mui/lab'
import { Link } from 'react-router-dom'
import SimpleError from '../../components/errors/simple'

const RequestRessetPassword = () => {
  const [open, setOpen] = useState(false)

  const [notification, setNotification] = useState({
    message: '',
    type: '',
  })

  const [error, setError] = useState({})

  const classes = LoginStyle()

  const [email, setEmail] = useState(null)

  const [load, setLoad] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (e.key === 'Enter' || e.key === undefined) {
      setLoad(true)
      if (isEmail(email)) {
        const res = await requestRessetPassword({ email: email })

        let notification = {
          message: '',
          type: '',
        }

        if (res.data.success) {
          notification = {
            message: loginMessage.ressetPassword,
            type: 'success',
          }
        } else {
          notification = {
            message: loginMessage.emailError,
            type: 'error',
          }
        }
        setLoad(false)
        setNotification(notification)
        setOpen(true)
      }
    }
  }
  const handleChange = ({ target }) => {
    setOpen(false)
    setEmail(target.value)
    if (target.value === '') {
      setError({ email: loginMessage.required })
    } else if (!isEmail(target.value)) {
      setError({ email: loginMessage.invalidEmailAddress })
    } else {
      setError({})
    }
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      {/* Longin */}
      <Grid item lg={7} md={7} xs={12}>
        <Grid container justifyContent="center">
          <FormControl  className={classes.form} onKeyUp={(e) => handleSubmit(e)}>
            <Typography variant="body2" className={classes.resetPassTitle} width="100%">
              {loginMessage.ressetPasswordTitle}
            </Typography>
            <Typography mb={2} variant="caption" width="100%">
              {loginMessage.ressetPasswordSubTitle}
            </Typography>

            <TextField 
            className={`${classes.inputBg} ${open && classes[notification.type]}`} type="text" label={loginMessage.labelEmail} value={email ?? ''} onChange={(e) => handleChange(e)} 
            error={!isEmail(email) && email !== null}/>

            <Box className={`${classes.wFull}`} pb={2}>
              <SimpleError error={open || !isEmail(email)} message={open ? notification.message : error.email} type={open ? notification.type : 'error'} />
            </Box>

            <Box className={classes.btnContainer}>
              <LoadingButton loading={load} onClick={handleSubmit} className={`${classes.submit} ${classes[!isEmail(email) || email === null ? 'sendBtnDisabled' : 'btn']}`} variant="contained" disabled={!isEmail(email) || email === null}>
                {loginMessage.ressetPasswordButton}
              </LoadingButton>
            </Box>

            <Button variant="text" component={Link} to="/" className={classes.reset}>
              {loginMessage.goBackToLogin}
            </Button>
          </FormControl>
        </Grid>
      </Grid>

      <Hidden mdDown>
        <Grid item lg={5} md={5} xs={0} className={classes.bg}>
          <Box />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default RequestRessetPassword
