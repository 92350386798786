import { makeStyles } from "@mui/styles";

const groupStyles = makeStyles({
    container: {
        minHeight: window.innerHeight -135,
        marginTop: 45,
        margin: 'auto',
        width: '95%'
    },
    link: {
        paddingLeft: '7.5%',
    }
})

export default groupStyles
