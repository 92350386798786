import { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { Typography } from '@mui/material'

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />
})

const SnackSimple = ({ open, close, message, type, time = 3000 }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        close(false)
    }

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={time} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                <Typography variant="body1">{message}</Typography>
            </Alert>
        </Snackbar>
    )
}

export default SnackSimple
