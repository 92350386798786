import { Route, Redirect} from 'react-router-dom'

const PrivateRoutes = ({component: Component, ...rest}) => {
    return (
        <Route {...rest}>
            {
                !localStorage.getItem('token') ?
                <Component />
                :
                <Redirect to={{ pathname: '/dashboard'}}/>  
            }
        </Route>
    )
}

export default PrivateRoutes
