import { GET, POST, PUT, DELETE } from "../utils/crud";

const list = async () => {
  const res = await GET("/question-types");

  return res;
};

const createTypeQuestion = async (body) => {
  const res = await POST("/question-types", body);

  return res;
};

const detailTypeQuestion = async (id) => {
  const res = await GET(`/question-types/${id}`);

  return res;
};

const editTypeQuestion = async (id, body) => {
  const res = await PUT(`/question-types/${id}`, body);

  return res;
};

const deleteTypeQuestions = async (id) => {
  const res = await DELETE(`/question-types/${id}`);

  return res;
};

const validateTypeQuestions = async (id) => {
  const res = await GET(`/validate/type_question/used?type_question_id=${id}`);

  return res;
};

const validateKey = async (id) => {
  const res = await GET(`/validate/type_question/key?key=${id}`);
  return res;
};

export const typeQuestions = {
  list,
  validateTypeQuestions,
  createTypeQuestion,
  deleteTypeQuestions,
  detailTypeQuestion,
  editTypeQuestion,
  validateKey,
};
