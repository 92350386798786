import { Box, Button, Icon, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { icons } from '../../utils'
import { useStyles } from './style'

const ViewFinally = ({ title, subtitle, btn, to }) => {
  const classes = useStyles

  return (
    <Box sx={classes.container}>
      <Icon sx={classes.icon}>{icons('check')}</Icon>

      <Typography sx={classes.title}>{title}</Typography>
      <Typography sx={classes.subtitle}>{subtitle}</Typography>

      <Button sx={classes.btn} variant="contained" to={to} component={Link}>
        {btn}
      </Button>
    </Box>
  )
}

export default ViewFinally
