import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { Table, TableBody, TableCell, Breadcrumbs, TableContainer, TableHead, TablePagination, TableRow, Paper, Box, Typography, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import I18n from 'i18n-js'

import { listParticipants } from '../../service/service'
import ListAnswerStyle from './themes/ListAnswer'
import LinkStyles from '../../themes/js/Link'
import Loading from '../../components/loading'
import SnackMultiple from '../../components/snack/SnackMultiple'
import BasicSelect from '../../components/select/Select'

const ListAnswer = () => {
  const columns = [
    { id: 'name', label: I18n.t('listAnswer.table.name'), minWidth: 100 },
    { id: 'lastname', label: I18n.t('listAnswer.table.lastname'), minWidth: 100 },
    { id: 'birthdate', label: I18n.t('listAnswer.table.birthdate'), minWidth: 110 },
    { id: 'bracelet_id', label: I18n.t('listAnswer.table.braceletId'), minWidth: 80 },
    { id: 'questionnaries', label: I18n.t('listAnswer.table.questionnaries'), minWidth: 80 },
  ]

  const classes = ListAnswerStyle()

  // Message error start
  const ref = useRef(null)

  const message = I18n.t('listAnswer.messages.urlEmpty')
  // Message error end

  const [load, setLoad] = useState(true)

  // ============== Pages control ==================
  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  //  ========== Particioants information ==================

  // Input text
  const [text, setText] = useState('')

  // lista de participantes
  const [rows, setRows] = useState([])

  const [newRow, setNewRow] = useState([])

  const [selectList, setSelectList] = useState([])

  const [selection, setSelection] = useState('')

  const handleSearchQuestionaries = (value) => {
    setNewRow(rows.filter((item) => (
      (
        item.name?.toLowerCase().includes(text.toLowerCase()) ||
        item.lastname?.toLowerCase().includes(text.toLowerCase()) ||
        item.birthdate?.toLowerCase().includes(text.toLowerCase()) ||
        item.bracelet_id?.toLowerCase().includes(text.toLowerCase())
      )
        &&
        item.questionnaries.toLowerCase().includes(value.toLowerCase())
    )))

    setSelection(value)
  }

  const handleSearch = (value) => {
      if(selection !== '') {
        setNewRow(rows.filter((item) => (
            (
              item.name?.toLowerCase().includes(value.toLowerCase()) ||
              item.lastname?.toLowerCase().includes(value.toLowerCase()) ||
              item.birthdate?.toLowerCase().includes(value.toLowerCase()) ||
              item.bracelet_id?.toLowerCase().includes(value.toLowerCase())
            )
            &&
            item.questionnaries.toLowerCase().includes(selection.toLowerCase())
        )))
      } else {
          setNewRow(rows.filter((item) => (
            item.name?.toLowerCase().includes(value.toLowerCase()) ||
            item.lastname?.toLowerCase().includes(value.toLowerCase()) ||
            item.birthdate?.toLowerCase().includes(value.toLowerCase()) ||
            item.bracelet_id?.toLowerCase().includes(value.toLowerCase())
          )))
      }

        setText(value)
  }

  const participants = async () => {
    const { data } = await listParticipants()

    let newSelectList = []

    let rows = data?.participants.map((item) => {
      const value = item.survey.title

      if (!newSelectList.includes(value)) {
        newSelectList = [...newSelectList, value]
      }
      
      return {
        name: item.name,
        lastname: item.lastname,
        birthdate: format(new Date(item.birthdate), 'dd/MM/yyyy'),
        bracelet_id: item.bracelet_id,
        questionnaries: value,
        watch_data: item.watch_data,
        id: item.id,
      }
    })

    setSelectList(newSelectList.map((item) => ({ value: item, label: item })))

    setRows([...rows])

    setNewRow([...rows])
    setLoad(false)
  }

  useEffect(() => {
    participants()
  }, [])

  if (load) {
    return (
      <Box sx={{ minHeight: window.outerHeight }}>
        <Loading />
      </Box>
    )
  }

  return (
    <Paper elevation={0} className={classes.container}>
      {/* Error */}
      <SnackMultiple ref={ref} message={message} variant="info" />

      <Box className={classes.box}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t('drawer.dashboard')}
          </Link>

          <Link style={LinkStyles} to={`/dashboard/answers`}>
            {I18n.t('drawer.answers')}
          </Link>
        </Breadcrumbs>

        <Box className={classes.header}>
          <Typography mb={5} className={classes.title} variant="h5">
            {I18n.t('listAnswer.title')}
          </Typography>

          <Box className={classes.search}>
            <BasicSelect className={classes.select} option={selectList} selection={handleSearchQuestionaries} label={I18n.t('listAnswer.table.questionnaries')} />

            <TextField
              size="small"
              className={classes.input}
              label={I18n.t('listAnswer.searchPlaceholder')}
              value={text}
              onChange={(e) => handleSearch(e.target.value, 'name')}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchOutlinedIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Box>

        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column, key) => (
                  <TableCell key={column.id} style={{ height: 40 }}>
                    <Typography variant="body2">{column.label}</Typography>
                  </TableCell>
                ))}

                <TableCell sx={{ width: 100 }} align="center">
                  <Typography variant="body2">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {newRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={column.id} sx={{ fontSize: 14 }}>
                        {row[column.id]}
                      </TableCell>
                    ))}

                    <TableCell sx={{ display: 'flex', height: 40, justifyContent: 'center' }}>
                      <IconButton>
                        <Link to={{ pathname: `/dashboard/answers/view/${row.id}` }}>
                          <VisibilityOutlinedIcon className={classes.icon} />
                        </Link>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={newRow.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} sx={{ backgroundColor: '#fff' }} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Box>
    </Paper>
  )
}

export default ListAnswer
