import colors from "../../../themes/colors/colors"

export const table = {
    title: {
        fontSize: 24,
        color: colors.palette.general
    },
    tableC: {
        boxShadow: '3px 2px #bbb'
    },
    tableP: {
        boxShadow: '3px 2px #bbb'
    },
    tableH : {}, 
    tableB : {}, 
    cellH : {}, 
    cellB : {},
    typografyH: {
        fontSize: 12,
        color: '#444',
    },
    typografyB: {
        color: '#444',
    }
}