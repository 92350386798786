import { makeStyles } from '@mui/styles'
import colors from '../../themes/colors/colors'

const useStyles = makeStyles({
  container: {
    minHeight: window.innerHeight - 155,
    marginTop: 45,
    margin: 'auto',
    width: '95%',
  },
  link: {
    paddingLeft: '7.5%',
  },
  iconOfTheList: {
    '& .MuiSvgIcon-root': {
      color: colors.palette.primary,
    },
  },
})

export default useStyles
