import "./interceptor";
const apiUrl = process.env.REACT_APP_URL;

async function GET(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function POST(url, body, isFormData = false) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "POST",
      headers: {
        ...(!isFormData && { "Content-Type": "application/json" }),
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
      body: !isFormData ? JSON.stringify(body) : body,
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

async function PUT(url, body) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: body };
  }
}

async function DELETE(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
    const json = await response.json();
    if (response.status <= 299) return { data: json, error: null };
    return { data: null, error: json };
  } catch (error) {
    return { data: null, error: error };
  }
}

export { GET, POST, PUT, DELETE };
