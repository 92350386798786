import { makeStyles } from "@mui/styles";

const SelectStyles = makeStyles({
    container: {
        background: '#fff',
        width: '100%',
    },
    items: {
        '&.MuiMenuItem-root': {
            height: 30,
        }
    }
})

export default SelectStyles
