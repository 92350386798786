import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu';
import DrawerList from '../components/DrawerList/DrawerList'
import NavbarStyle from '../themes/js/Navbar'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import I18n from 'i18n-js'

const Navbar = ({open, setOpen, verifyToken}) => {
    const classes = NavbarStyle()

    const [anchorEl, setAnchorEl] = useState(null)

    const openMenu = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const history = useHistory()

    const logout = () => {
        try {
            localStorage.clear()

            history.push('/')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box>
            <DrawerList open={open} setOpen={setOpen} verifyToken={verifyToken}/>

            <AppBar position="static" color="inherit" className={classes.container}>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.menu}>
                        <IconButton onClick={() => setOpen(!open)} size="large" edge="start" color="inherit" aria-label="menu">
                            <MenuIcon className={classes.menuIcon}/>
                        </IconButton>
                    </Box>

                    <Box className={classes.userInfo}>
                        <Typography variant="body1" className={classes.user}>
                            {I18n.t('navbar.welcome')} {localStorage.getItem('name')}
                            <br />
                            {localStorage.getItem('email')}
                        </Typography>

                        <AccountCircleIcon alt="avatar" className={classes.avatar} onClick={handleClick} />

                        <Menu
                            id="basic-menu"
                            sx={{position: 'absolute', top: 5, left: -45,}}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={logout} >
                                <ExitToAppIcon sx={{ marginRight: 1, fontSize: 18 }} />

                                <Typography>{I18n.t('navbar.logout')}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Navbar
