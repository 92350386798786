import {
  Box,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import I18n from "i18n-js";
import { memo, useCallback, useContext, useState } from "react";
import QuestionnairesContext from "../../context/questionnairesContext";
import useSaveSurveys from "../../hooks/useSaveSurveys";
import { colors, icons } from "../../utils";
import { LayoutButton } from "../btnBN/layoutButton";
import useStyles from "./styles";
import { styles } from "./styles";
import MapQuestions from "./mapQuestions";

const InformationCards = (props) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState("");
  const { element, index } = props;

  const {
    disableRemov,
    filteredQuestions,
    setFilteresQuestions,
    list,
    noQuestions,
    data,
  } = useContext(QuestionnairesContext);

  const {
    handleSections,
    addQuestionToSection,
    deleteSection,
    returnSections,
    addSections,
  } = useSaveSurveys();

  const handleCheck = useCallback(
    (id) => {
      const filtered_ = filteredQuestions.map((item) => {
        if (id === item.id) {
          item.used = !item.used;
          item.key = item.used ? element.key : null;
        }
        return item;
      });

      setFilteresQuestions(filtered_);
      addQuestionToSection(filtered_, element.key);
    }, //eslint-disable-next-line
    [data.sections.length, filteredQuestions]
  );

  const filteredElement = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <Box>
      <Paper
        elevation={5}
        className={classes.bodyStepTwo}
        sx={{ borderRadius: "20px" }}
      >
        <Box className={classes.iconClose}>
          <IconButton
            disabled={disableRemov}
            onClick={() => deleteSection(element.key, element.questions)}
          >
            {icons("close")}
          </IconButton>
        </Box>
        <Typography fontWeight={500} color="#1565c0">
          {I18n.t("questionnaries.title.section") +
            " " +
            (data.sections.length - index)}
        </Typography>
        <TextField
          fullWidth
          required
          value={returnSections("title", element) ?? ""}
          name="title"
          margin="normal"
          label={I18n.t("questionnaries.form.title")}
          onChange={(e) => {
            handleSections(e, element.key);
          }}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          fullWidth
          required
          name="description"
          value={returnSections("description", element) ?? ""}
          multiline
          rows={4}
          margin="normal"
          label={I18n.t("questionnaries.form.description")}
          onChange={(e) => {
            handleSections(e, element.key);
          }}
          inputProps={{ maxLength: 255 }}
        />
        <Box className={classes.searchBarContainer}>
          <Typography fontSize="12px" color="#1565c0">
            {I18n.t("questionnaries.title.availableQuestions")}
          </Typography>

          <TextField
            onChange={(e) => {
              filteredElement(e);
            }}
            size="small"
          />
          <Icon sx={styles.iconSearch}>{icons("search")}</Icon>
        </Box>
        <Box className={classes.questionList}>
          <MapQuestions
            list={list}
            element={element}
            searchKey={searchKey}
            handleCheck={handleCheck}
          />
        </Box>
      </Paper>
      <Box
        sx={{ display: index === 0 ? "flex" : "none", alignItems: "center" }}
      >
        <LayoutButton
          variant="text"
          label={I18n.t("questionnaries.button.addSection")}
          click={() => addSections()}
          disabled={noQuestions}
        />
        <Typography
          sx={{ display: noQuestions ? "block" : "none" }}
          fontSize="11px"
          color={colors.palette.error}
        >
          {I18n.t("questionnaries.errors.noQuestionsAvailable")}
        </Typography>
      </Box>
    </Box>
  );
};
export default memo(InformationCards);
