export const PT = {
  languages: {
    french: "Francês",
    portuguese: "Português",
  },
  general: {
    step1: "Passo 1",
    step2: "Passo 2",
    step3: "Passo 3",
    step4: "Passo 4",
    cancelBtn: "Cancelar",
    nextBtn: "Próximo",
    backBtn: "Voltar",
    search: "Pesquisa",
    actions: "Acções",
    add: "Adicionar",
    edit: "Editar",
    yes: "Sim",
    success: "Pedido processado",
    traductions: {
      missingTraductionTitle: "Campos ainda por traduzir!",
      missingTraductiontext:
        "Há ainda alguns campos que não estão traduzidos. Tem a certeza de que quer enviar esta informação? ",
    },
  },
  navbar: {
    welcome: "Receber",
    logout: "Sair",
  },
  drawer: {
    dashboard: "Painel de bordo",
    answers: "Respostas",
    view: "Ver",
    setting: "Configuração",
    users: {
      primary: "Pesquisador",
      update: "Actualização",
      create: "Criar",
      associate: "Associação",
    },
    roles: "Papéis",
    question: "Pergunta",
    polls: "Sondagens",
    group: "Grupo",
    groupOptions: "Grupo de opções",
    exclusionConditions: "Exclusão",
    questions: "Perguntas",
    questionnaries: "Questionários",
  },
  listAnswer: {
    title: "Listas de respostas",
    table: {
      name: "Nome",
      lastname: "Último nome",
      email: "Mail",
      birthdate: "Data de nascimento",
      braceletId: "Pulseirat Id",
      questionnaries: "Questionário",
    },
    searchPlaceholder: "Digite o texto de pesquisa",
    messages: {
      urlEmpty: "Nenhum arquivo para download foi encontrado",
    },
  },
  listView: {
    title: "Resultados do participante",
    dowload: "Relógio de dados",
    survey: "Enquete",
    date: "Data",
    searchPlaceholder: "Introduza a data a pesquisar",
    messages: {
      empty: "Aucune donnée d'horloge trouvée",
    },
    graphic: {
      view: "Ver gráfico",
      response: "Resposta enviada",
      value: "Valor",
      time: "Hora (s)",
      close: "Fechar",
    },
    watchData: {
      accelerometer: "Acelerómetro",
      temperature: "Temperatura",
      eda: "Eda",
    },
  },
  breadcrumbs: {
    listAnswer: "Listas de respostas",
    listView: "Resultado dos testes",
    optionGroup: "Grupo de opções",
  },
  searchPlaceholder: "Digite o texto de pesquisa",
  messages: {
    create: "criação bem sucedida de um novo grupo",
    update: "Sucesso de atualização do grupo",
    delete: "Exclusão de grupo com sucesso",
    error: "Ocorreu um erro na solicitação",
    empty: "Não pode criar um grupo vazio",
  },
  tableColumn: {
    name: "Nome",
    email: "Mail",
    totalOfVisits: "Total de visitas",
    surveysApplied: "Inquéritos aplicados",
  },
  group: {
    title: "Grupo",
    create: "Adicionar um novo grupo",
    update: "Grupo de atualização",
    newGroup: "Novo grupo",
    add: "Adicionar",
    accept: "Aceitar",
    cancel: "Cancelar",
    deleteTitle: "Tem certeza de que deseja excluir o grupo?",
    delete: "Limpar",
    search: "Digite o texto de pesquisa",
    inputUpdate: "Grupo de atualização",
    inputCreate: "Escreva o grupo para adicionar",
  },
  users: {
    title: "Pesquisador",
    btn: "Adicionar pesquisador",
    input: "Introduzir texto de pesquisa",
    informationTitle: "Informação ao pesquisador",
    inputName: "Nome",
    inputEmail: "Mail",
    inputLocal: "Idioma",
    passwordTitle: "Criar uma palavra-passe",
    newPassword: "Nova palavra-passe",
    updatePassword: "Actualizar palavra-passe",
    inputPassword: "Palavra-passe",
    inputConfirm: "Confirmar palavra-passe",
    requiremens: {
      title: "A senha deve conter",
      length: "- 8 caracteres ou mais",
      letter: "- Pelo menos uma letra maiúscula e uma minúscula",
      special:
        "- É permitido pelo menos um dígito e um carácter especial @$!%*?&",
      weak: "Baixo",
      strong: "Forte",
    },
    create: {
      title: "Adicionar pesquisador",
    },
    update: {
      title: "Actualizar pesquisador",
    },
    delete: {
      accept: "Aceitar",
      cancel: "Cancelar",
      deleteTitle: "Tem a certeza de que quer eliminar o pesquisador?",
    },
    locale: {
      French: "Francês",
      portuguese: "Português",
    },
    finally: {
      associate: "O grupo está associado com sucesso a",
      return: "Voltar à lista de pesquisador",
    },
    btnCancel: "Cancelar",
    btnCreate: "Criar",
    btnUpdate: "Actualização ",
    messages: {
      name: "O nome do enumerador é obrigatório.",
      validName: "O nome só pode conter letras e números",
      email: "formato de e-mail inválido",
      emailExist: "Já existe correio electrónico",
      locale: "É necessária uma língua",
      password:
        "A palavra-chave não está em conformidade com a política de segurança",
      confirm: "As palavras-passe não são idênticas",
      existUser: "O pesquisador já está a ser utilizado",
      required: "Campo obrigatório",
      success: {
        create: "Pesquisador criado com sucesso",
        update: "A informação foi actualizada com sucesso",
        delete: "Remoção bem sucedida de pesquisador",
      },
      error: {
        create: "Tem havido uma falha na criação do agrimensor",
        update: "Não é possível actualizar a sondagem",
        delete: "Ocorreu um erro ao apagar a sondagem",
      },
    },
  },
  exclusionConditions: {
    columnLabel: {
      description: "Descrição",
      recommendations: "Recomendações",
    },
    header: {
      create: "Criar uma nova exclusão",
      update: "Alterar a exclusão actual",
    },
    breadcrumbs: {
      title: "Exclusão",
      create: "Adicionar exclusão",
      update: "Mudar exclusão",
      associate: "Parceiro do inquérito",
    },
    btn: {
      create: "Adicionar exclusão",
    },
    form: {
      add: "Acrescentar uma exclusão",
      labelTextField: "Descrição da exclusão",
      exclusionInstruction: "Instrução de exclusão",
      step1: "Criar exclusão",
      step1M: "Modificação da exclusão",
      step2: "Traduzir",
    },
    finally: {
      title: "Demande traitée",
      subtitle: "L'exclusion a été modifiée avec succès",
      return: "Retour à la liste des exclusions",
    },
    associate: {
      indicators: "Indicateurs",
      indicatorsTitle: "Vincular os indicadores",
      indicatorsSelection:
        "Seleccione os indicadores que deseja acrescentar a este inquérito.",
      deleteTitle: "Você tem certeza de que deseja excluir esta exclusão?",
      finally: "O inquérito foi associado ao sucesso",
      createFinally: "A exclusão foi criada com sucesso",
      originalText: "Texto em",
    },
  },
  optionGroup: {
    columnLabel: {
      group: "Nome do Grupo",
      options: "Opções",
    },
    breadcrumbs: {
      title: "Lista de grupos",
      create: "Ajouter une option de groupe",
      update: "Changement d'option de groupe",
      associate: "Partenaire de l'enquête",
      edit: "Modifier l'exclusion",
    },
    steps: {
      stepOne: "Passo 1",
      stepTwo: "Passo 2",
      generalInformation: "Informações gerais",
      translate: "Tradução",
      created: "O grupo de opções foi criado",
      edited: "O grupo de opções foi modificado",
      return: "Voltar à lista de grupos de opções",
    },
    modal: {
      deleteGroup: "Tem a certeza de que quer remover este grupo de opções?",
      validation:
        " O grupo de opções está a ser utilizado. Para o retirar é necessário dissociá-lo das questões que o utilizam.",
    },
    inputs: {
      category: "Nome da categoria principal",
      subCategory: "Subcategoria",
      csv: "Encontrar um CSV",
      listItem: "Lista de subcategorias",
      missingItem: "Traduções em curso",
      translate: "Tradução",
      translateInstruction:
        "Pode Digitar cada tradução na linha respectiva, ou se preferir pode deixar cair um ficheiro CSV na caixa de traduções",
      instruction:
        "Pode adicionar tantas opções quantas precisar. Separe cada opção usando uma vírgula ou simplesmente arraste e largue um ficheiro CSV aqui",
    },
    btn: {
      create: "Novo grupo",
    },
  },
  footer: {
    text: "Survey App - © Copyright todos os direitos reservados",
  },
  countries: {
    fr: "França",
    pt: "Português",
  },
  traductions: {
    missingTraductionTitle: "Campos ainda por traduzir!",
    missingTraductiontext:
      "Há ainda alguns campos que não estão traduzidos. Tem a certeza de que quer enviar esta informação? ",
    cancel: "Cancelar",
    yes: "Sim",
  },

  question: {
    stepper: {
      informationGenerales: "Gerar informações",
      questionTranslations: "Tradução da pergunta",
      answers: "Respostas",
      answersTranslations: "Respostas de tradução",
    },
    breadcrumbs: {
      sample: "Provar",
      newGroup: "Nova pergunta",
    },
    title: {
      newQuestion: "Nova pergunta",
      editQuestion: "Editar pergunta",
      sampleQuestionTitle: "Exemplo de título de pergunta",
      min: "Mínimo",
      max: "Máximo",
      createdQuestion: "A questão foi criada com sucesso",
      return: "Voltar à lista de perguntas",
      editedQuestion: "A questão foi editada com sucesso",
    },
    form: {
      title: "Título da pergunta",
      description: "Descrição da pergunta",
      instructions: "Instrução de perguntas",
      sampleQuestionTitle: "Exemplo de título de pergunta",
      sampleDescription: "Descrição da amostra",
      sampleInstructions: "Instruções de perguntas",
      typeOfQuestion: "Tipo de pergunta",
      value: "Valor",
      sampleGroupName: "Nome do grupo de amostra",
      answer: "Resposta",
    },
    translatorAsks: {
      pleaseTranslateTheFollowingTexts: "Traduza os seguintes textos",
      textToTranslate: "Texto a ser traduzido",
    },

    useAnExistingGroupOfAnswers: "Use um grupo de resposta existente",
    pleaseSortTheAnswersThat:
      "Por favor, classifique as respostas que estarão disponíveis para esta pergunta de acordo com as suas necessidades. Por favor indique abaixo o valor de cada resposta",

    yes: "sim",

    list: {
      type: "Tipo de pergunta",
      questionList: "Lista de perguntas",
    },

    scale: {
      lowestValueLabel: "Rótulo de valor mais baixo",
      hightestValueLabel: "Rótulo de valor mais elevado",
    },
    modal: {
      changeTheAnswerOption:
        "Se alterar a opção de resposta, a informação previamente carregada é apagada.",
      doYouWishContinue: "Deseja continuar?",
      theQuestionIsBeingUsed:
        "A questão está em uso. Para o remover, deve desvinculá-lo dos inquéritos que o utilizam.",
      areYouSure: "Êtes-vous sûr de vouloir supprimer ce pergunta?",
      updateQuestion: "Pergunta de atualização",
    },
  },
  typeOfQuestion: {
    titles: {
      typeOfQuestion: "Type de question",
      listQuestionTypes: "Lista de tipos de perguntas",
      addQuestionType: "Adicionar um tipo de pergunta",
      UpdateQuestionType: "Actualizar o tipo de pergunta",
    },
    buttons: {
      newType: "Novo tipo de pergunta",
    },
    listTypesQuestions: {
      name: "Nome",
      key: "Chave",
    },
    form: {
      enterTheKey: "Chave do tipo ",
      typeName: "Nome do tipo",
    },
    modal: {
      questionTypeIsInUse:
        "O tipo de pergunta está em uso. Para o remover, deve ser desvinculado das questões que o utilizam.",
      areYouSure: "Êtes-vous sûr de vouloir supprimer ce tipo de pergunta?",
    },
    errors: {
      keyAlreadyUse: "A chave já está em uso.",
      noResultsFound: "Sem resultados",
    },
  },
  questionnaries: {
    flow: "Fluxo",
    lastQuestionNotHaveRedirects:
      "A última questão não pode ter sido redireccionada.",
    cyclesNotAllowedQuestions: "Ciclos não são permitidos em questões",
    cetteQuestionProvoqueCycle: "Esta questão provoca um ciclo",
    questions: "Perguntas",
    thereFurtherQuestions: "Não há mais perguntas",
    qstionnaireHasNoSections: "Este questionário não tem secções",
    thisSectionHasQuestions: "Esta secção não tem perguntas",
    translations: {
      pleaseTranslateTexts: "Por favor, traduza os seguintes textos",
      textTranslated: "Texto a ser traduzido",
      translations: "Traduções dos questionários",
      questionnaireFlow: "Fluxo de questionários",

      sampleQuestionnaireTitle: "Exemplo de título do questionário",
      title: "Título",
      description: "Descrição",
      instructions: "Instruções",
    },
    modal: {
      questionTypeIsInUse: "O questionário está a ser utilizado. ",
      areYouSure:
        "Tem a certeza de que quer eliminar este tipo de questionário?",
      youStillNeedTranslations:
        "Ainda precisa de adicionar traduções. Quer continuar?",
    },
    questionnaries: "Questionnaires",
    step: {
      generalInformation: "Informações gerais",
      section: "Seção",
      lookAndFeel: "Olhar e sentir",
    },
    title: {
      createLogicThatRequire: "Por favor, crie a lógica e o fluxo que deseja",
      action: "Ação",
      section: "Secção",
      sectioN: "SECÇÃO",
      questions: "Perguntas",
      listOfQuestionnaires: "Lista de questionários",
      sampleQuestionnaire: "Exemplo de título do questionário",
      availableQuestions: "Perguntas disponíveis (Seleccione pelo menos uma) *",
      generalColors: "Cores gerais",
      textFormat: "Cores contrastantes",
      stockColors: "Cores do texto",
      backgroundSettings: "Configurações de fundo",
      logo: "Logótipo",
      questionnaireSuccessfully: "O questionário foi criado com sucesso",
      questionnaireTitle: "Título do questionário",
      dateUpdate: "Data de actualização",
      editQuestionnaire: "Editar questionário",
      beenSuccessfullyEdited: "Le questionnaire a été édité avec succès",
    },
    button: {
      newQuestionnaire: "Novo questionário",
      addSection: "ADICIONE UMA SEÇÃO",
      backListQuestionnaires: "Voltar à lista de questionários",
    },
    form: {
      title: "Título",
      description: "Descrição",
      instructions: "Instruções",
      questionnaireTitle: "Título do questionário",
      descriptionQuestionnaire: "Descrição do questionário",
      questionnaireInstructions: "Instruções para o questionário",
      useMainColorSolidBackground:
        "Utilizar as cores principais como fundo sólido",
      createGradientMainSecondary:
        "Criar um gradiente entre a cor principal e secundária",
      useBackgroundImage:
        "utilizar uma imagem de fundo ( Recomendado 1024x1024)",
      mainBackgroundImage: "Imagem de fundo principal",
      logoImage: "Imagem de logotipo",
      mainColor: "Cor principal",
      secondaryColor: "Cor secundária",
      errorColor: "Cor do erro",
      colorOptions: "Opções de cor ",
      contrastImage: "Imagem contrastada",
      backgroundImage: "Imagem de fundo",
      nextSection: "Próxima secção",
      none: "Nenhuma ação",
    },
    errors: {
      noQuestionsAvailable: "Não há perguntas disponíveis",
      insertDecimalNumber: "Inserir um número decimal",
    },
  },
};
