import { useContext } from "react";
import QuestionnairesContext from "../context/questionnairesContext";

const useSaveSurveys = () => {
  const { data, setData, userLocale, list, setList, setFilteresQuestions } =
    useContext(QuestionnairesContext);
  const date = new Date();
  const addData = (value) => {
    return { value: value, locale: userLocale };
  };
  const handleSurvey = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: [addData(value)] ?? [] });
  };
  const returnData = (name) => {
    if (data[name])
      return data[name]?.find((item) => item.locale === userLocale)?.value;
  };
  const returnNewSection = (name) => {
    if (newSection[name])
      return newSection[name]?.find((item) => item.locale === userLocale)
        ?.value;
  };
  const returnSections = (name, item) => {
    if (item && item[name])
      return item[name]?.find((item) => item.locale === userLocale)?.value;
  };
  const uploadSections = (newItem, value) => {
    return newItem.map((item) => {
      if (item.locale === userLocale) item.value = value;
      return item;
    });
  };
  const handleSections = (e, key) => {
    const { value, name } = e.target;
    const uploadSection = data.sections.map((item) => {
      if (key === item.key) {
        item[name] = uploadSections(item[name], value, name);
      }
      return item;
    });

    setData({ ...data, sections: uploadSection });
  };
  const addSections = () => {
    setData({ ...data, sections: [...(data.sections ?? []), newSection()] });
    window.scroll({
      top: 285,
      behavior: "smooth",
    });
  };

  const newSection = () => {
    return {
      title: [addData(null)],
      description: [addData(null)],
      questions: [],
      key: date.getTime(),
    };
  };

  const cleanList = () => {
    const filteresQuestions_ = list.filter((item) => item.used === false);
    setFilteresQuestions(filteresQuestions_);
  };

  const deleteSection = (key, questions) => {
    const list_ = list.map((item) => {
      questions.forEach((element) => {
        if (item.id === element.question_id) {
          item.used = false;
          item.key = null;
        }
      });
      return item;
    });

    const newSections = data.sections.filter((item) => item.key !== key);
    setData({ ...data, sections: newSections });
    setList(list_);
    /*   const filteresQuestions_ = list_.filter((item) => item.used === false);*/
    setFilteresQuestions(list_);
  };

  const addQuestionToSection = (list, key_) => {
    const questions_ = list.filter(
      (item) => item.used === true && item.key === key_
    );
    const newQuestions = questions_.map((element, key) => {
      return {
        question_id: element.id,
        position: key,
        value: element.title,
        used: true,
        key: key_,
      };
    });
    const newSection = data.sections.map((item) => {
      if (item.key === key_) item.questions = newQuestions;
      return item;
    });
    setData({ ...data, sections: newSection });
  };

  return {
    addQuestionToSection,
    handleSections,
    handleSurvey,
    returnData,
    addSections,
    returnNewSection,
    newSection,
    deleteSection,
    cleanList,
    returnSections,
  };
};

export default useSaveSurveys;
