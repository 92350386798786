import { makeStyles } from "@mui/styles";
import colors from "../../../themes/colors/colors";

const tableStyles = makeStyles({
  header: {
    // width: '100%',
    display: "flex",
    flexWrap: "wrap",
    background: colors.palette.white,
    padding: "40px 10px 20px 20px",
    boxShadow: "3px 2px #BBBBBB",
  },
  container: {
    padding: "10px 30px 0 30px",
    marginBottom: 10,
    width: "85%",
    margin: "auto",
  },
  cell: {
    borderRight: "6px solid #f2f2f2",
    maxWidth: "150px",
    overflow: "hidden",

    overflowWrap: "break-word",
    textOverflow: "ellipsis",
  },
  normal: {
    borderRight: "6px solid #f2f2f2",
    maxWidth: "150px",
    overflow: "hidden",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
  },
  boxSearchCreate: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 40,
    paddingRight: "10%",
    width: "100%",
  },
  searchIcon: {
    "&.MuiSvgIcon-root": {
      color: colors.icons.secondary,
    },
  },
  color: {
    background: colors.typography.primary,
  },
  button: {
    "&.MuiButton-root": {
      backgroundColor: colors.buttons.primary,
      borderRadius: 0,
      height: 36,
      marginTop: 2,
      "&:hover": {
        backgroundColor: colors.buttons.secondary,
      },
    },
  },
  subtitle: {
    "&.MuiTypography-root": {
      color: colors.palette.general,
    },
  },
  input: {
    backgroundColor: colors.palette.white,
    "&.MuiTextField-root": {
      marginRight: 10,
      marginBottom: 10,
    },
    "& .MuiInputLabel-root": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "80%",
      "&.Mui-focused": {
        width: "100%",
      },
    },
  },
  inputSearch: {
    "&.MuiTextField-root": {
      width: 420,
    },
  },
  delete: {
    display: "flex",
    flexWrap: "wrap",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    boxShadow: "3px 2px #5F6E6D",
  },
  CU: {
    display: "flex",
    flexWrap: "wrap",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#fff",
    boxShadow: "3px 2px #5F6E6D",
  },
  CUText: {
    width: "100%",
    "&.MuiTypography-root": {
      padding: "10px 20px",
    },
  },
  CUInput: {
    width: "100%",
    "&.MuiTextField-root": {
      padding: "10px 0 20px 0",
    },
    "& .MuiOutlinedInput-root": {
      height: 35,
      margin: "auto",
      width: "90%",
    },
  },
  CUContainerButtons: {
    padding: "10px 0",
    display: "flex",
    width: "100%",
  },
  CUButtons: {
    "&.MuiButton-root": {
      marginRight: 10,
      height: 30,
      borderRadius: 0,
    },
  },
  CUButtonsCancel: {
    "&.MuiButton-root": {
      border: `1px solid ${colors.buttons.outline}`,
      color: colors.buttons.outline,
      "&:hover": {
        border: `1px solid ${colors.buttons.outline}`,
        color: colors.buttons.outline,
      },
    },
  },
  CUButtonsAccept: {
    "&.MuiButton-root": {
      backgroundColor: colors.buttons.primary,
      "&:hover": {
        backgroundColor: colors.buttons.secondary,
      },
    },
  },
  totalSurveys: {
    "&.MuiTypography-root": {
      marginRight: 10,
      fontSize: 20,
      color: colors.palette.primary,
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: 10,
  },
  flexGrow: {
    flexGrow: 1,
    minWidth: "10px",
  },
  textNoResults: {
    marginLeft: "16px",
    marginTop: "12px",
  },

  // TABLE VERIFY
});

export default tableStyles;
