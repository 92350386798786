import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TableUsers from "../../user/tableUsers";
import Roles from "../../roles/roles";
import Polls from "../../polls/polls";
import Group from "../../group/group";
import NotFound from "../../404";
import Condition from "../../conditions";
import FormExclusion from "../../conditions/form";
import ExclusionAssociate from "../../conditions/exclusionAssociate";
import OptionGroup from "../../optionGroup";
import OptionGroupForm from "../../optionGroup/form";
import UserAssociate from "../../user/associate";
import QuestionForm from "../../question/question";
import QuestionList from "../../question/questionList";
import TypeQuestions from "../../typeQuestions/typeQuestions";
import Questionnaires from "../../questionnaires";
import QuestionnairesForm from "../../questionnaires/form";
import { Translations } from "../../questionnaires/translations";
import { FlowConfiguration } from "../../questionnaires/flowConfiguration";

const routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/dashboard/questionnaires/flowConfiguration/:id"
        component={FlowConfiguration}
      />
      <Route
        exact
        path="/dashboard/questionnaires/translations/:id"
        component={Translations}
      />
      <Route
        exact
        path="/dashboard/questionnaires/edit/:id"
        component={QuestionnairesForm}
      />
      <Route
        exact
        path="/dashboard/questionnaires/create"
        component={QuestionnairesForm}
      />
      <Route
        exact
        path="/dashboard/questionnaires"
        component={Questionnaires}
      />

      <Route exact path="/dashboard/typeQuestions" component={TypeQuestions} />

      <Route
        exact
        path="/dashboard/question/questionlist"
        component={QuestionList}
      />

      <Route exact path="/dashboard/question/create" component={QuestionForm} />
      <Route
        exact
        path="/dashboard/question/edit/:id"
        component={QuestionForm}
      />

      <Route
        exact
        path="/dashboard/pollsters/associate/:id"
        component={UserAssociate}
      />

      <Route
        exact
        path="/dashboard/exclusion/create"
        component={FormExclusion}
      />

      <Route
        exact
        path="/dashboard/exclusion/edit/:id"
        component={FormExclusion}
      />

      <Route
        exact
        path="/dashboard/exclusion/associate/:id"
        component={ExclusionAssociate}
      />

      <Route
        exact
        path="/dashboard/option-group/create"
        component={OptionGroupForm}
      />

      <Route
        exact
        path="/dashboard/option-group/edit/:id"
        component={OptionGroupForm}
      />

      <Route exact path="/dashboard/pollsters" component={TableUsers} />

      <Route exact path="/dashboard/roles" component={Roles} />

      <Route exact path="/dashboard/polls" component={Polls} />

      <Route exact path="/dashboard/group" component={Group} />

      <Route exact path="/dashboard/exclusion" component={Condition} />

      <Route exact path="/dashboard/option-group" component={OptionGroup} />

      <Route path="/404" component={NotFound} />

      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
};

export default routes;
