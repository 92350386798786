import { Drawer, List, Typography, Box, Collapse, ListItemIcon } from '@mui/material'
import DrawerListStyle from '../../themes/js/DrawerListeStyle'
import logo from '../../static/logo.png'
import { list } from './linkList'
import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import I18n from 'i18n-js';
import LinkStyles from '../../themes/js/Link'
import colors from '../../themes/colors/colors'

const DrawerList = ({ open, setOpen, verifyToken }) => {
    const classes = DrawerListStyle(open)

    const [collapseSettings, setCollapseSettings] = useState(false)

    return (
        <Drawer
            open={true}
            // onMouseEnter={() => setOpen(true)}
            // onMouseLeave={() => setOpen(false)}
            variant="persistent"
            sx={{ marginRight: 7 }}
        >
            <List className={classes.list}>
                <Box className={classes.logo}>
                    <img src={logo} alt="" width="100%" height="100%" />
                </Box>

                {list.map((link) => (
                    <div key={link.label}>
                        {link.sub === undefined ? (
                            // Options
                            <Box>
                                <Box onClick={() => verifyToken(link)}/* to={link.path} */ style={{ ...LinkStyles, color: colors.typography.primary}}>
                                    {link.icon(`${classes.icons} `)}

                                    {open === true && (
                                        <Typography varian="body2" className={classes.text}>
                                            {I18n.t(link.label)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        ) : (
                            // Setting
                            <>
                                <Box sx={{cursor: 'pointer', color: colors.typography.primary}} onClick={() => setCollapseSettings(!collapseSettings)}>
                                    {link.icon(classes.icons)}

                                    {open === true && (
                                        <Typography variant="body1" className={classes.text}>
                                            {I18n.t(link.label)}
                                        </Typography>
                                    )}

                                    {collapseSettings ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>

                                <Collapse in={collapseSettings} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {link.sub.map((sub) => (
                                            <Box onClick={() => verifyToken(sub)}/* to={sub.path} */ key={sub.label} className={classes.collapse} style={{ textDecoration: 'none', color: '#E6E8F5' }}>
                                                <ListItemIcon className={classes.collapseIcon}>{sub.icon}</ListItemIcon>
                                                {open === true && <Typography variant="body1" className={classes.subtext}>{I18n.t(sub.label)}</Typography>}
                                            </Box>
                                        ))}
                                    </List>
                                </Collapse>
                            </>
                        )}
                    </div>
                ))}
            </List>
        </Drawer>
    )
}

export default DrawerList
