import { Box } from "@mui/material"
import homeStyles from './themes/home'

const Home = () => {
    const classes = homeStyles()

    return (
        <Box className={classes.container}>
            <h1>Home</h1>
        </Box>
    )
}

export default Home
