import { Box, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, TablePagination, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import tableStyles from './themes/table'
import SearchIcon from '@mui/icons-material/Search'
import tableViewStyles from './themes/tableview'

const TableView = ({ columns = [], rows = [], crud, data, filter = '', table }) => {
  const classes = tableStyles()

  const [text, setText] = useState('')

  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const newRow = rows.filter((row) => row[filter].includes(text))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Box>
      {/* ============================= Table ======================== */}
      <Box className={classes.header} mb={0}>
        <Box sx={{ flexGrow: 1 }} mr={2}>
          <Typography sx={{ ...table.title }}>{data.title}</Typography>

          <Typography variant="body1" className={classes.subtitle}>
            {data.subtitle}
          </Typography>
        </Box>

        <TextField sx={tableViewStyles} className={classes.input} size="small" label={data.search} value={text} onChange={(e) => setText(e.target.value)} InputProps={{ endAdornment: <SearchIcon className={classes.searchIcon} /> }} />
      </Box>

      <TableContainer sx={table.tableC}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead style={{ height: 40 }} sx={table.tableH}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} sx={table.cellH}>
                  <Typography variant="body1" sx={table.typografyH}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={classes.color} sx={table.tableB}>
            {newRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ind) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={ind}>
                  {columns.map((column) => (
                    <TableCell key={column.id} sx={table.cellB}>
                      <Typography variant="caption" sx={table.typografyB}>
                        {row[column.id]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        sx={table.tableP}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        className={classes.color}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

TableView.defaultProps = {
  data: {
    title: '',
    subtitle: '',
    search: '',
  },
  table: {
    title: {},
    tableC: {},
    tableP: {},
    tableH: {},
    tableB: {},
    cellH: {},
    cellB: {},
    typografyH: {},
    typografyB: {},
  },
}

export default TableView
