import { Box, Breadcrumbs, Typography } from "@mui/material";
import I18n from "i18n-js";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { SectionFlowCard } from "../../../components/sectionCards/sectionFlowCard";
import { questionnaires } from "../../../service/questionnaire";
import LinkStyles from "../../../themes/js/Link";
import { colors } from "../../../utils";
import useStyles, { styles } from "./styles";
import { LayoutButton } from "../../../components/btnBN/layoutButton";
import { LayoutButtonLink } from "../../../components/btnBN/layoutButtonLink";
import ViewFinally from "../../../components/finallyView/finally";
import Loading from "../../../components/loading";
import SimpleError from "../../../components/errors/simple";

export const FlowConfiguration = () => {
  const { id } = useParams();
  const userLocale = localStorage.getItem("lang");
  const [title, setTitle] = useState();
  const [sectionList, setSectionList] = useState();
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState("");
  const [error, setError] = useState(null);
  const [questionError, setQuestionError] = useState(null);
  const [displayArray, setDisplayArray] = useState();
  const classes = useStyles();

  useEffect(() => {
    setErrors("");
    for (let i = 0; i < sectionList?.length; i++) {
      for (let j = 0; j < sectionList[i]?.questions?.length; j++) {
        const isCycle = validateLogicSteps(
          sectionList[i]?.questions,
          [],
          sectionList[i]?.questions[j]?.id,
          sectionList[i]?.questions[j]?.id
        );

        if (!isCycle) {
          i = sectionList.length;
          break;
        } else {
          setError(null);
          setQuestionError(null);
        }
      }
    }
  }, [sectionList]);

  const handleChange = (e, sectionID, questionID, idd = id, index) => {
    //---------------------------------------------------------------------------
    const section = sectionList.find((item) => item.id === sectionID);
    const question = section.questions.find((item) => item.id === questionID);
    const options = question.options.map((item, i) => {
      if (item.id === idd) {
        let idRed;
        if (!e) {
          section.questions.forEach((item, i) => {
            if (item.id === questionID) {
              idRed = section.questions[i + 1].id;
            }
          });
        }

        return { ...item, redirect_question: e ? e.target.value : idRed };
      }
      return item;
    });

    const question_ = section.questions.map((item) => {
      if (item.id === questionID)
        return { ...item, options: options, used: true };
      return item;
    });

    const section_ = sectionList.map((item) => {
      if (item.id === sectionID) return { ...item, questions: question_ };
      return item;
    });
    //----------------------------------------------------------------------------
    const sectionD = displayArray.find((item) => item.id === sectionID);
    const questionD = sectionD.questions.find((item) => item.id === questionID);
    const optionsD = questionD.options.map((item) => {
      if (item.id === idd) {
        return { ...item, redirect_question: e ? e.target.value : null };
      }

      return item;
    });

    const question_D = sectionD.questions.map((item) => {
      if (item.id === questionID)
        return { ...item, options: optionsD, used: true };
      return item;
    });
    const section_D = displayArray.map((item) => {
      if (item.id === sectionID) return { ...item, questions: question_D };
      return item;
    });

    setDisplayArray(section_D);
    setSectionList(section_);
  };

  useEffect(() => {
    questionnaires.detailQuestionnaire(id).then(({ data: detail }) => {
      const title_ = detail?.survey?.translations.find(
        (item) => item.locale === userLocale
      )?.title;

      const sectionsArray = detail?.survey?.survey_sections ?? [];

      //--------------------------------------------------------------

      const sectionsDisplay = sectionsArray.map((item) => {
        const titles = item?.translations?.find(
          (title) => title?.locale === userLocale
        )?.title;

        const question_ = item.steps.map((element, i) => {
          const titleQuestion = element?.question?.translations?.find(
            (trad) => trad?.locale === userLocale
          )?.title;
          const options = element?.question?.question_options?.map(
            (element_) => {
              let redirect;

              if (element_?.step_id) {
                redirect = item?.steps?.find((sec) => {
                  return sec?.id === element_?.step_id;
                })?.question_id;
              }
              const titleAnws = element_?.option?.translations?.find(
                (anws) => anws.locale === userLocale
              )?.title;

              return {
                title: titleAnws,
                id: element_?.option?.id,
                redirect_question: redirect ?? null,
              };
            }
          );
          return {
            position: element.position,
            question: titleQuestion,
            options: options,
            id: element?.question?.id,
            used: false,
          };
        });

        return {
          title: titles ?? "",
          id: item?.id ?? "",
          questions: question_,
        };
      }, []);

      //--------------------------------------------------------------

      const sections = sectionsArray.map((item) => {
        const titles = item?.translations?.find(
          (title) => title?.locale === userLocale
        )?.title;

        const question_ = item.steps.map((element, i) => {
          const titleQuestion = element?.question?.translations?.find(
            (trad) => trad?.locale === userLocale
          )?.title;
          const options = element?.question?.question_options?.map(
            (element_) => {
              let redirect;

              if (element_?.step_id) {
                redirect = item?.steps?.find((sec) => {
                  return sec?.id === element_?.step_id;
                })?.question_id;
              } else {
                if (item.steps[i + 1]) {
                  redirect = item.steps[i + 1].question.id;
                } else {
                  redirect = null;
                }
              }
              const titleAnws = element_?.option?.translations?.find(
                (anws) => anws.locale === userLocale
              )?.title;

              return {
                title: titleAnws,
                id: element_?.option?.id,
                redirect_question: redirect ?? null,
              };
            }
          );
          return {
            question: titleQuestion,
            options: options,
            id: element?.question?.id,
            used: false,
          };
        });

        return {
          title: titles ?? "",
          id: item?.id ?? "",
          questions: question_,
        };
      }, []);
      setSectionList(sections);
      setDisplayArray(sectionsDisplay);
      setTitle(title_);
    }); //eslint-disable-next-line
  }, []);

  const submitData = async () => {
    const body_ = displayArray.map((item) => {
      const { title, ...newI } = item;
      const questions = item.questions.map((item_) => {
        const { title, ...newIt } = item_;
        const options = item_.options.map((element) => {
          const { title, ...newIte } = element;
          if (
            element.redirect_question !== "none" &&
            element.redirect_question !== null
          ) {
          }
          if (element.redirect_question === "none")
            newIte.redirect_question = null;
          return newIte;
        });
        newIt.options = options;
        return newIt;
      });
      newI.questions = questions;
      return newI;
    });

    const body = { sections: body_ };

    const res = await questionnaires.saveFlow(id, body);

    if (res?.error?.error.includes("logic redirect_question")) {
      setErrors(I18n.t("questionnaries.cyclesNotAllowedQuestions"));
    }

    if (res.data) setSaved(true);
  };

  const validateLogicSteps = (
    steps,
    ids = [],
    step_id = null,
    idd = null,
    stepsID
  ) => {
    for (let i = 0; i < steps.length; i++) {
      if ((step_id && steps[i].id === step_id) || !step_id) {
        if (ids.length === 0) {
          ids.push(steps[i].id);
        } else {
          if (ids.includes(steps[i].id)) {
            const idError = steps[i].options.find(
              (item) =>
                ids.includes(item.redirect_question) &&
                item.redirect_question !== steps[i + 1].id
            );

            if (idError) {
              setQuestionError(steps[i].id);
              setError(idError.id);
            } else {
              setQuestionError(stepsID);
              setError(idd);
            }

            return false;
          } else {
            ids.push(steps[i].id);
          }
        }
        for (let j = 0; j < steps[i].options.length; j++) {
          if (steps[i].options[j].redirect_question) {
            let ids_option = [...ids];
            const isValidate = !validateLogicSteps(
              steps,
              ids_option,
              steps[i].options[j].redirect_question,
              steps[i].options[j].id,
              steps[i].id
            );

            if (isValidate) {
              return false;
            }
          }
        }
        break;
      }
    }
    return true;
  };

  return (
    <Box sx={styles.body}>
      <Box className={classes.title}>
        <Breadcrumbs>
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t("drawer.setting")}
          </Link>

          <Link
            style={LinkStyles}
            className={classes.linkSelect}
            to="/dashboard/questionnaires"
          >
            {I18n.t("drawer.questionnaries")}
          </Link>
          <Link
            style={LinkStyles}
            className={classes.linkSelect}
            to={`/dashboard/questionnaires/flowConfiguration/${id}`}
          >
            {I18n.t("questionnaries.flow")}
          </Link>
        </Breadcrumbs>
        <Box>
          <Typography color="GrayText">
            {I18n.t("questionnaries.translations.questionnaireFlow")}
          </Typography>
          <Typography
            sx={styles.titleMain}
            variant="h5"
            color={colors.blueLighten}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      {saved ? (
        <Box height="300px">
          <ViewFinally
            title={I18n.t("exclusionConditions.finally.title")}
            subtitle={I18n.t("questionnaries.title.beenSuccessfullyEdited")}
            to="/dashboard/questionnaires"
            btn={I18n.t("questionnaries.button.backListQuestionnaires")}
          />
        </Box>
      ) : (
        <Box sx={styles.body}>
          <Box sx={styles.bottomSection}>
            <Typography>
              {I18n.t("questionnaries.title.createLogicThatRequire")}
            </Typography>
            {displayArray ? (
              displayArray.length > 0 ? (
                displayArray.map((item, index) => (
                  <Box key={item.id}>
                    <SectionFlowCard
                      handleChange={handleChange}
                      key={item.id}
                      sectionID={item.id}
                      index={index}
                      section={item}
                      error={error}
                      questionError={questionError}
                    />
                  </Box>
                ))
              ) : (
                <Typography sx={styles.existenceText}>
                  {I18n.t("questionnaries.qstionnaireHasNoSections")}
                </Typography>
              )
            ) : (
              <Loading />
            )}
          </Box>
          <Box sx={styles.containerButton}>
            <LayoutButtonLink
              link={"/dashboard/questionnaires"}
              label={I18n.t("general.cancelBtn")}
            />
            <LayoutButton
              click={() => {
                submitData();
              }}
              variant={"contained"}
              label={I18n.t("general.nextBtn")}
              disabled={error ? true : false}
            />
          </Box>
          <SimpleError error={errors} message={errors} />
        </Box>
      )}
    </Box>
  );
};
