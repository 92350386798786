export const listGroup = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/groups`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            },
        })

        const data = await res.json()

        return {
            data,
            response: 200,
        }
    } catch (error) {
        return { response: 404 }
    }
}

export const createGroup = async (data) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/groups`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({"name": data}),
        })

        return {
            status: res.status
        }
    } catch (error) {
        return { status: 404 }
    }
}

export const updateGroup = async (data) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/groups/${data.id}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({"name": data.name}),
        })

        return {
            status: res.status
        }
    } catch (error) {
        return {
            status: 404
        }
    }
}

export const deleteGroup = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_URL}/groups/${id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem('token')}`,
            }
        })

        return {
            status: res.status
        }
    } catch (error) {
        return {
            status: 404
        }
    }
}
