import { Box, Modal } from "@mui/material";
import { colors } from "../../utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: colors.palette.white,
  pt: 4,
  borderRadius: "5px",
};

const BaseModal = ({ children, open, onClose, width = 400 }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box width={width} sx={style}>
        {children}
      </Box>
    </Modal>
  );
};

export default BaseModal;
