import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  buttons: {
      marginTop: 10,
      float: 'right'
  },
})

export default useStyles
