import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import I18n from "i18n-js";
import React, { useContext } from "react";
import QuestionContext from "../../context/questionContext";
import { icons } from "../../utils";
import useStyles from "./style";
import { styles } from "./style";
import {
  CheckBoxOutlineBlank,
  RadioButtonUnchecked,
} from "@mui/icons-material";

const FormAnswer = (props) => {
  const { type, option, deleteOption, valueSwitch } = props;
  const { userLocale, data, setData } = useContext(QuestionContext);
  const classes = useStyles();

  const handleChange = (e) => {
    const newAnswer = data.options.map((item) =>
      item.key === option.key ? { ...item, weight: e.target.value } : item
    );
    setData({ ...data, options: newAnswer });
  };

  const label =
    option?.title?.find(({ locale }) => locale === userLocale)?.value ?? "";
  const validarEntradasConPunto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9-]|(?<=.)-/, "");
  };

  switch (type) {
    case "radio":
      return (
        <Box className={classes.create}>
          <Paper
            elevation={4}
            className={
              valueSwitch ? classes.paperAnswerCheck : classes.paperAnswer
            }
            sx={{
              textOverflow: "ellipsis",
            }}
          >
            <RadioButtonUnchecked sx={{ mr: 2 }} />
            <Typography sx={styles.textArea}>{label}</Typography>
          </Paper>
          <Box sx={{ display: "flex" }}>
            <TextField
              onInput={validarEntradasConPunto}
              label={I18n.t("question.form.value")}
              name="weight"
              defaultValue={option.weight}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 9 }}
            />
            {!!deleteOption && (
              <IconButton
                className={classes.x}
                onClick={() => {
                  deleteOption(option.key);
                }}
              >
                {icons("x")}
              </IconButton>
            )}
          </Box>
        </Box>
      );

    case "check":
      return (
        <Box className={classes.create}>
          <Paper
            elevation={4}
            className={
              valueSwitch ? classes.paperAnswerCheck : classes.paperAnswer
            }
          >
            <CheckBoxOutlineBlank sx={{ mr: 2 }} />
            <Typography sx={styles.textArea}>{label}</Typography>
          </Paper>
          <Box sx={{ display: "flex" }}>
            <TextField
              onInput={validarEntradasConPunto}
              label={I18n.t("question.form.value")}
              name="weight"
              defaultValue={option.weight}
              onChange={handleChange}
              fullWidth
              inputProps={{ maxLength: 9 }}
            />
            {!!deleteOption && (
              <IconButton
                className={classes.x}
                onClick={() => {
                  deleteOption(option.key);
                }}
              >
                {icons("x")}{" "}
              </IconButton>
            )}
          </Box>
        </Box>
      );

    case "open":
      return (
        <Box className={classes.create}>
          <Paper
            elevation={4}
            className={
              valueSwitch ? classes.paperAnswerCheck : classes.paperAnswer
            }
          >
            <Typography sx={styles.textArea}>{label}</Typography>
          </Paper>
          <Box>
            <TextField
              onInput={validarEntradasConPunto}
              label={I18n.t("question.form.value")}
              name="weight"
              defaultValue={option.weight}
              onChange={handleChange}
              sx={{ width: "20%" }}
              inputProps={{ maxLength: 9 }}
            />
            {!!deleteOption && (
              <IconButton
                className={classes.x}
                onClick={() => {
                  deleteOption(option.key);
                }}
              >
                {icons("x")}{" "}
              </IconButton>
            )}
          </Box>
        </Box>
      );

    default:
      return <></>;
  }
};

export default FormAnswer;
