import { Box, Breadcrumbs } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import I18n from "i18n-js";
import LinkStyles from "../../themes/js/Link";
import useStyles from "./styles";
import { questionnaires } from "../../service/questionnaire";

import Loading from "../../components/loading";
import { ModalQuestion } from "../../components/modal/modalQuestion";
import { ModalUsedItem } from "../../components/modal/usedItem";
import TableVerify from "../../components/tables/tableLink";

const Questionnaires = () => {
  const userLocale = localStorage.getItem("lang");
  const dateFormat = new Intl.DateTimeFormat(userLocale);

  const [list, setList] = useState([]);
  const [isLoadid, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [trash, setTrash] = useState();
  const classes = useStyles();
  const columns = [
    {
      id: "title",
      label: I18n.t("questionnaries.title.questionnaireTitle"),
      minWidth: 170,
    },
    {
      id: "date",
      label: I18n.t("questionnaries.title.dateUpdate"),
      minWidth: 170,
    },
  ];

  useEffect(() => {
    questionnaires.list().then(({ data }) => {
      const list_ = data.surveys.map((item, key) => {
        const date_ = new Date(item.updated_at);
        return {
          title: item.title,
          date: dateFormat.format(date_),
          id: item.id,
        };
      });
      setList(list_);
      setIsLoading(false);
    }); //eslint-disable-next-line
  }, []);

  const data = {
    title: I18n.t("questionnaries.title.listOfQuestionnaires"),
    search: I18n.t("users.input"),
    searchBtn: I18n.t("questionnaries.button.newQuestionnaire"),
    to: "/dashboard/questionnaires/create",
    to2: "/dashboard/questionnaires/edit",
    to3: "option-group/associate",
    titleM: I18n.t("question.modal.areYouSure"),
    btnCancel: I18n.t("users.delete.cancel"),
    btnAccept: I18n.t("users.delete.accept"),
  };

  const askDeletedQuestionnaires = async (id) => {
    const exists = await questionnaires.validateQuestionnaire(id.id);

    if (exists.data.survey_used) {
      setOpenModal(true);
    } else {
      setTrash(id);
      setOpenDelete(true);
    }
  };
  const delateItem = async (obj) => {
    const res = await questionnaires.deleteQuestionnaire(obj.id);
    if (res.data !== null) setList(list.filter((item) => item.id !== obj.id));
    setOpenDelete(false);
  };

  if (isLoadid) return <Loading />;
  return (
    <Box className={classes.container}>
      <ModalUsedItem
        openModal={openModal}
        setOpenModal={setOpenModal}
        eventAccept={() => setOpenModal(false)}
        label={I18n.t("questionnaries.modal.questionTypeIsInUse")}
      />
      <ModalQuestion
        title={I18n.t("questionnaries.modal.areYouSure")}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("group.delete")}
        sendDelete={delateItem}
        trash={trash}
      />
      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t("drawer.setting")}
        </Link>

        <Link
          style={LinkStyles}
          className={classes.linkSelect}
          to="/dashboard/questionnaires"
        >
          {I18n.t("questionnaries.title.listOfQuestionnaires")}
        </Link>
      </Breadcrumbs>
      <TableVerify
        parameter="title"
        columns={columns}
        rows={list}
        data={data}
        check={askDeletedQuestionnaires}
        translations={"/dashboard/questionnaires/translations"}
        configure={"/dashboard/questionnaires/flowConfiguration"}
      />
    </Box>
  );
};

export default Questionnaires;
