import { TextField } from '@mui/material'
import React from 'react'

const DinamicForm = ({ inputs, handleChange, values }) => {
  return (
    <>
      {inputs?.map((input, i) => {
        if (input.type === 'phone') {
          const { styles, ...rest } = input
          return <TextField key={i} className={styles} {...rest} />
        } else {
          const { styles, onChange, ...rest } = input

          return (
            <TextField 
                key={i}
                className={styles} 
                value={values[input.name] ?? ''}
                { ...{onChange: onChange ?? handleChange}}
                {...rest} 
            />
          )
        }
      })}
    </>
  )
}

export default DinamicForm
