import { Box, Typography } from '@mui/material'
import useStyles from './styles'

const SimpleError = ({ error = false, message, type = "error"}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={`${classes.text} ${classes[type]}`}> {error && message}</Typography>
    </Box>
  )
}

export default SimpleError
