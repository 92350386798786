import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material'
import { ReportProblemOutlined } from '@mui/icons-material'
import I18n from 'i18n-js'
import useStyles from './style'
const AlertModal = ({ open, close, title, message, SuccessText, success }) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={close} className={classes.root}>
      <DialogTitle className={classes.header} id="alert-dialog-title">
        <ReportProblemOutlined className={classes.icon} />
        {title}
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          variant="outlined"
          // color="secondary"
          className={classes.cancel}
          size="small"
          onClick={close}
        >
          {I18n.t('traductions.cancel')}
        </Button>
        <Button
          variant="contained"
          // color="secondary"
          className={classes.submit}
          size="small"
          onClick={() => {
            close()
            success()
          }}
        >
          {SuccessText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertModal
