import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SelectStyles from './themes/select';

const BasicSelect = ({option = [{value: '', label: ''}], selectedOp = '', selection, label = 'Label'}) => {
    const classes = SelectStyles()

  const [selected, setSelected] = useState('');

  const handleChange = ({ target }) => {
    const { value } = target

    setSelected(value);
    
    selection(value)
  };

  useEffect(() => {
    setSelected(selectedOp)
  }, [selectedOp])
  
  return (
    <Box sx={{ minWidth: 120, width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{top: -6}}>{label}</InputLabel>
        <Select
          size="small"
          className={classes.container}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          label="Age"
          onChange={handleChange}
        >
          {
              option.map((item) => <MenuItem className={classes.items} key={item.value} value={item.value}>{item.label}</MenuItem>)
          }
        </Select>
      </FormControl>
    </Box>
  );
}


export default BasicSelect