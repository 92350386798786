import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const useStyles = makeStyles({
  container: {
    minHeight: window.innerHeight - 220,
    marginTop: 45,
    margin: 'auto',
    width: '80%',
    position: 'relative',
    paddingBottom: 65,
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  link: {
    '&.MuiBreadcrumbs-root': {
      width: '100%',
      marginBottom: 20,
    },
  },
  containerTitle: {
    width: '35%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    '&.MuiTypography-root': {
      fontWeight: 400,
      fontSize: '1.2em',
      color: colors.blueLighten,
    },
  },
  headerSections: {
    width: '50%',
    '&:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexGrow: {
    flexGrow: 1,
    width: 100,
  },
  btns: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
})

export default useStyles
