import { Box, TextField, Typography } from "@mui/material";
import I18n from "i18n-js";
import { icons } from "../../utils/icons";
import { styles } from "./styles";

export const WrapperLanguages = (props) => {
  const {
    language,
    keysObj = null,
    pages,
    saveData,
    name,
    showData,
    id = null,
  } = props;

  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  return (
    <Box sx={styles.containerOfTheLegunage}>
      <Box sx={styles.translationLanguage}>
        {icons(language.locale)}
        <Typography color="GrayText">{I18n.t(language.name)}</Typography>
      </Box>
      <TextField
        name={name}
        onChange={(e) => saveData(e, language.locale, id)}
        onInput={(e) => validateInput(e)}
        value={showData(keysObj, pages, language.locale, id) ?? ""}
        inputProps={{ maxLength: 255 }}
        size="small"
        multiline
        fullWidth
      />
    </Box>
  );
};
