import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LinkStyles from "../../../themes/js/Link";
import I18n from "i18n-js";
import { questionnaires } from "../../../service/questionnaire";
import { styles } from "./styles";
import { colors } from "../../../utils";
import { useStyles } from "./styles";
import { useQuestionnaireTranslations } from "../../../hooks/useQuestionnaireTranslations";
import TranslationContainer from "../../../components/viewTemplates/translationContainer";
import Loading from "../../../components/loading";
import ViewFinally from "../../../components/finallyView/finally";
import { LayoutButtonLink } from "../../../components/btnBN/layoutButtonLink";
import { LayoutButton } from "../../../components/btnBN/layoutButton";
import { VersionModal } from "../../../components/modal/modalQuestion/versionModal";

export const Translations = () => {
  const [dataSections, setDataSections] = useState();
  const [allData, setAllData] = useState();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const userLocale = localStorage.getItem("lang");
  const { id: idPage } = useParams();
  const {
    handleTranslations,
    showMainData,
    showSectionData,
    handleSectionData,
    data,
    saved,
    dataSubmit,
    loadData,
    dataControl,
    isSection,
  } = useQuestionnaireTranslations();

  useEffect(() => {
    if (idPage)
      questionnaires.detailQuestionnaire(idPage).then(({ data: detail }) => {
        loadData(detail, setAllData, setDataSections);
      }); //eslint-disable-next-line
  }, []);

  if (!data) return <Loading />;

  return (
    <Box sx={styles.layoutBody}>
      <VersionModal
        openModal={open}
        setOpenModal={setOpen}
        title={I18n.t("questionnaries.modal.youStillNeedTranslations")}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("group.delete")}
        eventClick={() => dataSubmit(idPage, setOpen)}
      />
      <Box className={classes.title}>
        <Breadcrumbs sx={{ marginBottom: "15px" }}>
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t("drawer.setting")}
          </Link>

          <Link
            style={LinkStyles}
            className={classes.linkSelect}
            to="/dashboard/questionnaires"
          >
            {I18n.t("drawer.questionnaries")}
          </Link>
          <Link
            style={LinkStyles}
            className={classes.linkSelect}
            to={`/dashboard/questionnaires/translations/${idPage}`}
          >
            {I18n.t("questionnaries.translations.translations")}
          </Link>
        </Breadcrumbs>
        <Box>
          <Typography color="GrayText">
            {I18n.t("questionnaries.translations.translations")}
          </Typography>
          <Typography
            sx={styles.titleMain}
            variant="h5"
            color={colors.blueLighten}
          >
            {data.title.find((item) => item.locale === userLocale)?.value ??
              I18n.t("questionnaries.translations.sampleQuestionnaireTitle")}
          </Typography>
        </Box>
      </Box>
      {saved ? (
        <Box height="300px">
          <ViewFinally
            title={I18n.t("exclusionConditions.finally.title")}
            subtitle={I18n.t("questionnaries.title.beenSuccessfullyEdited")}
            to="/dashboard/questionnaires"
            btn={I18n.t("questionnaries.button.backListQuestionnaires")}
          />
        </Box>
      ) : (
        <Box className={classes.body}>
          <Box className={classes.sectionUp}>
            <TranslationContainer
              mainArray={allData}
              saveData={handleTranslations}
              next={() => isSection(idPage, setOpen)}
              borderRadius="18px"
              elevation={4}
              showData={showMainData}
              returnFalse={true}
            />
          </Box>

          <Box className={classes.carousel}>
            <Carousel
              showArrows={true}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
            >
              {dataSections?.map((item, i) => {
                const { id, ...item_ } = item;
                return (
                  <TranslationContainer
                    key={item.id}
                    id={item.id}
                    index={i + 1}
                    mainArray={item_}
                    saveData={handleSectionData}
                    showData={showSectionData}
                    returnFalse={true}
                  />
                );
              })}
            </Carousel>
            <Box className={classes.containerButton}>
              <LayoutButtonLink
                label={I18n.t("general.cancelBtn")}
                link={"/dashboard/questionnaires"}
              />

              <LayoutButton
                click={() => dataControl(idPage, setOpen)}
                variant="contained"
                label={I18n.t("general.nextBtn")}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
