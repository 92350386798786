import { Box, CircularProgress } from "@mui/material";
import { styles } from "./styles";

export const SmallSpinner = (props) => {
  const { sx } = props;
  return (
    <Box sx={sx ?? styles.containerSmallSpinner}>
      <CircularProgress />
    </Box>
  );
};
