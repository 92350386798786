import { FormGroup } from "@mui/material";
import { memo } from "react";
import useStyles from "./styles";
import ItemGroupCheckBox from "./itemGroupCheckbox";

const MapQuestions = (props) => {
  const { list, element, searchKey, handleCheck } = props;
  const classes = useStyles();

  return (
    <FormGroup className={classes.formList}>
      {list
        .filter((item) =>
          item?.title
            ?.toString()
            .toLowerCase()
            .includes(searchKey.toLowerCase())
        )
        .map((item) => (
          <ItemGroupCheckBox
            key={item.id}
            item={item}
            handleCheck={handleCheck}
            element={element}
          />
        ))}
    </FormGroup>
  );
};

export default memo(MapQuestions);
