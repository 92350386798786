import { makeStyles } from '@mui/styles'
import colors from '../colors/colors'

const LoginStyle = makeStyles((theme) => ({
  inputBg: {
    background: colors.palette.white,
    
  },
  success: {
    '& .MuiInputLabel-root': {
      color: colors.palette.primary,
    },
    '& fieldset':{
      borderColor: colors.palette.primary,
      '&:hover': {
        borderColor: colors.palette.primary,
      }
    },

  },
  error: {
    '& .MuiInputLabel-root': {
      color: colors.palette.error,
    },
    '& fieldset':{
      borderColor: colors.palette.error,
      '&:hover': {
        borderColor: colors.palette.error,
      }
    }
  },
  form: {
    width: '75%',
    [theme.breakpoints.down('lg')]: {
      width: '85%',
    },
  },
  wFull: {
    width: '100%',
  },
  py: {
    padding: '4px 0',
  },
  py1: {
    padding: '8px 0',
  },
  py2: {
    padding: '16px 0',
  },
  container: {
    '&.MuiGrid-root': {
      backgroundColor: 'fff',
      height: '100vh',
    },
  },
  title: {
    color: colors.palette.primary,
    '&.MuiTypography-root': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  resetPassTitle: {
    '&.MuiTypography-root': {
      fontWeight: 500,
    },
  },
  submit: {
    width: 150,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    height: 45,
    '&.MuiButton-root': {
      marginTop: 10,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: colors.buttons.secondary,
      },
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    '&.MuiButton-root': {
      backgroundColor: colors.buttons.primary,
    },
  },
  sendBtnDisabled: {
    '&.MuiLoadingButton-root': {
      backgroundColor: 'none',
    },
  },
  reset: {
    '&.MuiButton-root': {
      width: 300,
      margin: '50px auto',
      color: colors.palette.primary,
    },
  },
  bg: {
    background: colors.palette.primary,
    minHeight: '100vh',
  },
}))

export default LoginStyle
