import { useContext, useEffect, useState } from "react";
import QuestionContext from "../context/questionContext";
import { useAuxTranslations } from "./useAuxTranslations";

const useOptionsTranslation = () => {
  const {
    data: contextData,
    setData: setContextData,
    userLocale,
  } = useContext(QuestionContext);
  const { handleChangeTraslation, data, setData } = useAuxTranslations();
  const [currentId, setId] = useState(0);

  const handleChangeOption = (e, locale, id) => {
    handleChangeTraslation(e, locale);
    if (currentId !== id) setId(id);
  };

  const updateTranslationValue = (options, newOption) => {
    const userLocaleOptions =
      options.title?.find(({ locale }) => locale === userLocale) ?? {};
    if (!newOption.find(({ locale }) => locale === userLocale))
      return [...newOption, userLocaleOptions];
    else
      return newOption.map((ans) =>
        ans.locale === userLocale ? userLocaleOptions : ans
      );
  };

  useEffect(() => {
    if (Object.values(data ?? {}).length) {
      const options = contextData.options;
      const currentOption = options.find(({ key }) => key === currentId);
      const newOption = updateTranslationValue(currentOption, data.title);

      const newOptions = contextData.options.map((options) =>
        options.key === currentId ? { ...options, title: newOption } : options
      ); //eslint-disable-next-line
      setContextData({ ...contextData, options: newOptions });
    }
    //eslint-disable-next-line
  }, [data]);

  const deleteData = () => {
    setData({});
  };

  const getOptionLocale = (locale, dataId) => {
    const options = contextData.options;

    const currentOption = options?.find(({ key }) => key === dataId);

    if (currentOption?.title) {
      const value = currentOption.title.find(
        (options) => options.locale === locale
      )?.value;
      return value;
    }
    return "";
  };

  return { handleChangeOption, getOptionLocale, deleteData, setData };
};
export default useOptionsTranslation;
