import { Breadcrumbs } from "@mui/material";
import { Box } from "@mui/system";
import I18n from "i18n-js";
import useStyles from "./style";
import { Link } from "react-router-dom";
import LinkStyles from "../../../themes/js/Link";
import TableVerify from "../../../components/tables/tableLink";
import { questions } from "../../../service/question";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { ModalUsedItem } from "../../../components/modal/usedItem";
import { ModalQuestion } from "../../../components/modal/modalQuestion";

const QuestionList = () => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [isLoadid, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [trash, setTrash] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const columns = [
    {
      id: "title",
      label: I18n.t("question.form.sampleQuestionTitle"),
      minWidth: 170,
    },
    {
      id: "type",
      label: I18n.t("question.list.type"),
      minWidth: 170,
    },
  ];

  useEffect(() => {
    questions.list().then(({ data }) => {
      const list = data.questions.map((item, key) => {
        //eslint-disable-next-line
        const typeQuestion = item.question_type?.name ?? "";
        return { title: item.title, type: typeQuestion, id: item.id };
      });
      setList(list);
      setIsLoading(false);
    }); //eslint-disable-next-line
  }, []);

  const data = {
    title: I18n.t("question.list.questionList"),
    search: I18n.t("users.input"),
    searchBtn: I18n.t("question.title.newQuestion"),
    to: "/dashboard/question/create",
    to2: null,
    to3: "option-group/associate",
    titleM: I18n.t("question.modal.areYouSure"),
    btnCancel: I18n.t("users.delete.cancel"),
    btnAccept: I18n.t("users.delete.accept"),
  };

  const askDeletedQuestion = async (id) => {
    const exists = await questions.validateQuestion(id.id);
    if (exists.data.question_used) {
      setOpenModal(true);
    } else {
      setTrash(id);
      setOpenDelete(true);
    }
  };
  const editItem = (flag, id) => {
    setTrash(id);
    setModalEdit(flag);
  };

  const delateItem = async (obj) => {
    const res = await questions.deleteQuestions(obj.id);
    if (res.data !== null) setList(list.filter((item) => item.id !== obj.id));
    setOpenDelete(false);
  };

  if (isLoadid) return <Loading />;

  return (
    <Box className={classes.container}>
      <ModalUsedItem
        openModal={openModal}
        setOpenModal={setOpenModal}
        eventAccept={() => setOpenModal(false)}
        label={I18n.t("question.modal.theQuestionIsBeingUsed")}
      />
      <ModalQuestion
        title={I18n.t("question.modal.updateQuestion")}
        openDelete={modalEdit}
        setOpenDelete={setModalEdit}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("group.accept")}
        link={"/dashboard/question/edit"}
        trash={trash}
      />
      <ModalQuestion
        title={I18n.t("question.modal.areYouSure")}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        labelCancel={I18n.t("users.delete.cancel")}
        labelAccept={I18n.t("group.delete")}
        sendDelete={delateItem}
        trash={trash}
      />
      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t("drawer.setting")}
        </Link>

        <Link
          style={LinkStyles}
          className={classes.linkSelect}
          to="/dashboard/question/questionlist"
        >
          {I18n.t("drawer.questions")}
        </Link>
      </Breadcrumbs>

      <TableVerify
        columns={columns}
        rows={list}
        data={data}
        separator={false}
        parameter="title"
        actions={true}
        check={askDeletedQuestion}
        editingModal={editItem}
      />
    </Box>
  );
};

export default QuestionList;
