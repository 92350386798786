import { makeStyles } from "@mui/styles"

const dashboardStyles = makeStyles({
    container: (open) => ({
        marginLeft: open ? 228 : 90.3,
        transition: '.3s',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px'
    }),
    section: { flex: 1 },

})

export default dashboardStyles
