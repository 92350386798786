import { makeStyles } from "@mui/styles";
import colors from "../../themes/colors/colors";

const useStyles = (widthBar, color = "secondary") => {
  const styles = makeStyles((theme) => ({
    iconDisabled: {
      backgroundColor: colors.palette.white,
      zIndex: 1,
      border: "#ccc solid 1px",
      width: "25px",
      height: "25px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      color: "#ccc",
    },
    iconActive: {
      background: color === "secondary" ? colors.blueLighten : colors.primary,
      border:
        color === "secondary"
          ? colors.blueLighten
          : colors.primary + " solid 1px",
    },
    iconComplete: {
      border:
        color === "secondary"
          ? colors.blueLighten
          : colors.primary + " solid 1px",
      background: color === "secondary" ? colors.blueLighten : colors.primary,
    },
    roundedIcon: {
      "&.MuiSvgIcon-root": {
        fontSize: "21px",
      },
    },
    roundedActive: { color: colors.palette.white },
    roundedComplete: { color: colors.palette.white },

    stepper: {
      background: "none",
      padding: "10px 0 0 0px",
      "& .MuiStepConnector-root": {
        "& .MuiStepConnector-line": {
          borderTopWidth: "2px",
        },
      },
    },
    step: {
      "&.MuiStep-root": {
        padding: 0,
        width: "auto",
      },
    },
    stepLabel: {
      "&.MuiStepLabel-root": {
        display: "block",
      },
    },
    stepEnd: {
      "&.MuiStep-root": {
        padding: 0,
        position: "relative",
        maxWidth: "27px",
      },
    },
    textStep: {
      textAlign: "start",
      display: "block",
      textTransform: "uppercase",
    },
    textLabelStep: {
      [theme.breakpoints.down(1480)]: {
        width: "100px",
      },

      textAlign: "start",
      display: "block",
      color: colors.blueLighten,
    },
    connector: {
      "&.MuiStepConnector-root": {
        left: `-${widthBar}px`,
        right: "0",
        width: `${widthBar}px`,
        top: 13,
        position: "absolute",
      },
      "&.Mui-active": {
        borderColor: colors.blueLighten,
      },
    },
    connectorActive: {
      "& span": {
        borderColor:
          color === "secondary" ? colors.blueLighten : colors.primary,
      },
    },
    connectorComplete: {
      "& span": {
        borderColor:
          color === "secondary" ? colors.blueLighten : colors.primary,
      },
    },
  }));
  return styles();
};

export default useStyles;
