import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import I18n from "i18n-js";
import { questionnaires } from "../../../service/questionnaire";
import LinkStyles from "../../../themes/js/Link";
import { steps } from "../../../enums/steps";
import useStyles from "./styles";
import { styles } from "./styles";
import { colors } from "../../../utils";
import ProviderQuestionnaires from "../../../provider/providerQuestionnaires";
import QuestionnairesContext from "../../../context/questionnairesContext";
import StepperCreate from "../../../components/stepperCreate";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import ViewFinally from "../../../components/finallyView/finally";
import Loading from "../../../components/loading";
import useSaveSurveys from "../../../hooks/useSaveSurveys";

const QuestionnairesLayout = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { step, userLocale, setData, data, setDataStyles } = useContext(
    QuestionnairesContext
  );
  const { returnData } = useSaveSurveys();

  const stepsPart = [
    {
      step: "general.step1",
      label: "questionnaries.step.generalInformation",
    },
    {
      step: "general.step2",
      label: "questionnaries.step.section",
    },
    {
      step: "general.step3",
      label: "questionnaries.step.lookAndFeel",
    },
  ];

  useEffect(() => {
    if (id) {
      questionnaires.detailQuestionnaire(id).then(({ data: data_ }) => {
        const colors_ = data_.survey?.styles?.colors;
        const translations = data_.survey?.translations.find(
          (item) => item.locale === userLocale
        );
        const sections_ = data_.survey.survey_sections.map((item, index) => {
          const date_ = new Date().getTime() + index;
          const trans = item.translations.find(
            (item_) => item_.locale === userLocale
          );
          const questions_ = item.steps.map((item_) => {
            return {
              question_id: item_.question_id,
              position: item_.position,
              key: date_,
              value: item_?.question?.translations?.find(
                (translation) => translation.locale === userLocale
              )?.title,
            };
          });
          return {
            title: [{ locale: userLocale, value: trans.title }],
            description: [{ locale: userLocale, value: trans.description }],
            questions: questions_,
            key: date_,
          };
        });

        setData({
          title: [{ locale: userLocale, value: translations.title }],
          description: [
            { locale: userLocale, value: translations.description },
          ],
          instructions: [
            { locale: userLocale, value: translations.instructions },
          ],
          sections: sections_,
        });
        setDataStyles({
          primary: colors_?.primary ?? null,
          secondary: colors_?.secondary ?? null,
          contrast_primary: colors_?.contrast_primary ?? "",
          contrast_secondary: colors_?.contrast_secondary ?? "",
          option_text: colors_?.option_text ?? null,
          error_text: colors_?.error_text ?? "",
          contrast_background_url: colors_?.contrast_background_url ?? "",
          background_url: data_?.survey?.styles?.background_url ?? null,
          logo_url: data_.survey?.styles?.logo_url ?? null,
        });
      });
    }

    //eslint-disable-next-line
  }, []);

  const View = () => {
    switch (step) {
      case steps.one:
        return <StepOne />;
      case steps.two:
        return <StepTwo />;
      case steps.three:
        return <StepThree />;
      case steps.four:
        return (
          <Box height="300px">
            <ViewFinally
              title={I18n.t("exclusionConditions.finally.title")}
              subtitle={
                id
                  ? I18n.t("questionnaries.title.beenSuccessfullyEdited")
                  : I18n.t("questionnaries.title.questionnaireSuccessfully")
              }
              to="/dashboard/questionnaires"
              btn={I18n.t("questionnaries.button.backListQuestionnaires")}
            />
          </Box>
        );
      default:
        return "Page not found";
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.headerQuestionnaires}>
        <Box className={classes.title}>
          <Breadcrumbs>
            <Link style={LinkStyles} to="/dashboard">
              {I18n.t("drawer.setting")}
            </Link>

            <Link
              style={LinkStyles}
              className={classes.linkSelect}
              to="/dashboard/questionnaires"
            >
              {I18n.t("drawer.questionnaries")}
            </Link>
            <Link
              style={LinkStyles}
              className={classes.linkSelect}
              to={
                id
                  ? `/dashboard/questionnaires/edit/${id}`
                  : "/dashboard/questionnaires/create"
              }
            >
              {id
                ? I18n.t("question.title.editQuestion")
                : I18n.t("questionnaries.button.newQuestionnaire")}
            </Link>
          </Breadcrumbs>
          <Box>
            <Typography color="GrayText">
              {id
                ? I18n.t("questionnaries.title.editQuestionnaire")
                : I18n.t("questionnaries.button.newQuestionnaire")}
            </Typography>
            <Typography
              sx={styles.titleMain}
              variant="h5"
              color={colors.blueLighten}
            >
              {!returnData("title") || returnData("title") === ""
                ? I18n.t("questionnaries.title.sampleQuestionnaire")
                : returnData("title")}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.stepper}>
          <StepperCreate activeStep={step} steps={stepsPart} />
        </Box>
      </Box>
      {id && !data.instructions ? <Loading /> : <Box>{View(step)}</Box>}
    </Box>
  );
};

const QuestionnairesForm = () => {
  return (
    <ProviderQuestionnaires>
      <QuestionnairesLayout />
    </ProviderQuestionnaires>
  );
};

export default QuestionnairesForm;
