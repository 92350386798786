import { Box } from "@mui/material";
import { useContext } from "react";
import QuestionnairesContext from "../../../../context/questionnairesContext";
import AddSection from "./addSection";
import Sections from "./sections";

const StepTwo = () => {
  const { viewTwo, setViewTwo } = useContext(QuestionnairesContext);

  return (
    <Box>
      {!viewTwo && <AddSection setView={setViewTwo} />}
      {viewTwo && <Sections setView={setViewTwo} />}
    </Box>
  );
};

export default StepTwo;
