import { GET, POST } from "../utils/crud"

const getUserGroups = async (id) => {
    const res = await GET(`/users/${id}/get-groups`)

    return res
}

const addUserGroups = async (id, data) => {
    const res = await POST(`/users/${id}/add-groups`, data)

    return res
}

export const user = {
    getUserGroups,
    addUserGroups
}