import { makeStyles } from '@mui/styles'
import colors from '../../../../themes/colors/colors'

const useStyles = makeStyles({
  card: {
    width: '100%',
    minHeight: 70,
    marginBottom: 20,
    // border: `1px solid ${colors.palette.greyPlaceholder}`,
    // borderRadius: 0,
    // boxShadow: `1px 1px ${colors.palette.grey}`,
  },
  cardContainer: {
    '&.MuiGrid-root': {
      width: `98%`,
    },
  },
  cardTranslate: {
    // width: '95%',
    minHeight: 70,
    borderRadius: 10,
    marginBottom: 10,
  },
  header: {
    '&.MuiTypography-root': {
      fontSize: '1em',
      color: colors.blueLighten,
    },
  },
  colorLabel: {
    '&.MuiTypography-root': {
      color: colors.blueLighten,
    },
  },
  break: {
    '&.MuiTypography-root': {
      wordBreak: 'break-all',
      fontSize: '0.95em',
      fontWeight: 100,
    }
  },
  containerPrincipalData: {
    '&.MuiBox-root': {
      // paddingLeft: 37,
      width: '94%',
    },
  },
  principalData: {
    '&.MuiGrid-root': {
      // border: `1px solid ${colors.palette.greyPlaceholder}`,
      padding: '0 0 10px 10px',
      position: 'relative',
    },
  },
  padding2: {
    paddingTop: 30,
    paddingLeft: 10,
  },
  input: {
    '&.MuiTextField-root': {
      width: '100%',
      background: colors.palette.white,
    },
  },
  btnAdd: {
    '&.MuiButton-root': {
      color: colors.blueLighten,
      '&:hover': {
        color: colors.blueLighten,
      },
    },
  },
  information: {
    '&.MuiTypography-root': {
      padding: '0px 30px 10px 30px',
      color: colors.disabled,
    },
  },
  delete: {
    '&.MuiIconButton-root': {
      width: 20,
      height: 20,
      marginTop: 10,
      marginLeft: 10,
      color: colors.palette.error,
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  AIC: {
    alignItems: 'center',
  },
  flexGrow: {
    flexGrow: 1,
    width: 100,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    marginTop: 50,
  },
  icon: {
    '&.MuiIcon-root': {
      color: colors.blueLighten,
      fontSize: '3em',
      width: '100%',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  title: {
    '&.MuiTypography-root': {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.4em',
      margin: '20px 0',
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      fontSize: '1em',
      width: '100%',
      textAlign: 'center',
      color: colors.palette.greyDark,
    },
  },
  btn: {
    '&.MuiButton-root': {
      borderRadius: 0,
      marginTop: 40,
    },
  },
  link: {
    textDecoration: 'none',
    color: colors.palette.white,
  },
  translateContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  translateName: {
    '&.MuiTypography-root': {
      fontSize: '.9em',
      marginLeft: 5,
    },
  },
  pl3: {
    '&.MuiTypography-root': {
      paddingLeft: 30,
    },
  },
  tcd: {
    '&.MuiTypography-root': {
      color: colors.palette.greyMedium,
      wordBreak: 'break-word',
      maxWidth: '96%',
    },
  },
  tf08: {
    '&.MuiTypography-root': {
      fontSize: '.8em',
    },
  },
})

export default useStyles
