import { Box, Breadcrumbs } from '@mui/material'
import groupStyles from './themes/group'
import { listGroup, createGroup, updateGroup, deleteGroup } from '../../service/group'
import { useCallback, useEffect, useState } from 'react'
import TableSimple from '../../components/tables/tableSimple'
import SnackSimple from '../../components/snack/SnackSimple'
import Loading from '../../components/loading'
import I18n from 'i18n-js'
import { Link } from 'react-router-dom'
import LinkStyles from '../../themes/js/Link'

const Group = () => {
    const data = {
        title: I18n.t('group.title'),
        search: I18n.t('group.search'),
        searchBtn: I18n.t('group.add'),
        btnM1: I18n.t('group.cancel'),
        btnM2: I18n.t('group.accept'),
        createM: {
            title: I18n.t('group.create'),
            input: I18n.t('group.inputCreate'),
        },
        updateM: {
            title: I18n.t('group.update'),
            input: I18n.t('group.inputUpdate'),
        },
        thirdM: {
            title: I18n.t('group.deleteTitle'),
            btn1: I18n.t('group.cancel'),
            btn2: I18n.t('group.delete'),
        },
    }

    const columns = [{ id: 'name', label: I18n.t('tableColumn.name'), minWidth: 170 }]
    
    const [rows, setRows] = useState([])

    const [load, setLoad] = useState(true)

    const [open, setOpen] = useState(false)

    const [type, setType] = useState('info')

    const classes = groupStyles()

    const [message, setMessage] = useState('')

    const crud = async (value) => {
        let res = {}

        setType('success')

        if (value.method === 'create') {
            if (value.name !== '') {
                res = await createGroup(value.name.trim())
                setMessage(I18n.t('messages.create'))
            } else {
                setMessage(I18n.t('messages.empty'))
                setType('error')
                setOpen(true)
                return
            }
        }

        if (value.method === 'update') {
            res = await updateGroup(value)
            setMessage(I18n.t('messages.update'))
        }

        if (value.method === 'delete') {
            res = await deleteGroup(value.id)
            setMessage(I18n.t('messages.delete'))
        }

        if (res.status === 200) {
            dataGroup()
            setOpen(true)
            return
        }

        setMessage(I18n.t('messages.error'))
        setType('error')
        setOpen(true)
    }

    const dataGroup = useCallback(async () => {
        const data = await listGroup()

        setRows(data.data.groups.sort((a, b) => (a.id > b.id ? 1 : -1)))

        setLoad(false)
    }, [])

    useEffect(() => {
        dataGroup()
    }, [dataGroup])

    if (load === true) {
        return <Loading />
    }

    return (
        <Box className={classes.container}>
            <SnackSimple open={open} close={setOpen} message={message} type={type} />

            <Breadcrumbs className={classes.link} aria-label="breadcrumb">
                <Link style={LinkStyles} to="/dashboard">
                    {I18n.t('drawer.dashboard')}
                </Link>

                <Link style={LinkStyles} to={`/dashboard/group`}>
                    {I18n.t('drawer.group')}
                </Link>
            </Breadcrumbs>

            <TableSimple columns={columns} rows={rows} crud={crud} data={data} />
        </Box>
    )
}

export default Group
