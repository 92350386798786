import { colors } from "../../utils";

export const styles = {
  bodyColors: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  colorPickerCont: {
    "& .MuiInputBase-root": {
      "& .MuiFormControl-root": {
        marginRight: "12px",
        width: "40px",
      },
      "& .MuiInputBase-input": {
        height: "40px",
      },
    },
    width: "240px",
  },
  textColor: {
    width: "40px",

    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "0px",
        height: "30px",
        cursor: "pointer",
      },
    },
  },
  containerInputImage: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginTop: "10px",
    marginBottom: 5,
    position: "relative",
  },
  file: ({ error }) => ({
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
      "-webkit-text-fill-color": colors.greyStrong,
      fontSize: ".9em",
      padding: "9px 15px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "&.MuiFormControl-root.MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      width: "100%",
      background: "transparent",
      border: error ? `1px solid ${colors.orangeStrong}` : "",
      borderRadius: 3,
      "& svg": {
        color: colors.greyStrong,
      },
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      background: error
        ? "linear-gradient(transparent 5%, white 60%, transparent 5%)"
        : "transparent",
    },
  }),
  buttonFile: {
    "&.MuiButton-root": {
      width: 400,
    },
  },

  imageViewImput: {
    width: "70px",
    height: "70px",
    border: "black solid 1px",
    //borderRadius: "100%",
    marginTop: "4px",
    cursor: "pointer",
    // position: "absolute",
  },

  inputHiddenColors: {
    visibility: "hidden",
    position: "absolute",
    width: "35px",
    height: "35px",
    right: 1,
    top: 17,
  },
  //----------- WrapperLanguages ---------
  translationLanguage: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  containerOfTheLegunage: {
    display: "flex",
    alignItems: { xs: "flex-start", md: "center" },
    gap: { xs: "15px", md: "45px" },
    marginTop: "30px",
    flexDirection: { xs: "column", md: "row" },
  },
};
