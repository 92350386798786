import { makeStyles } from '@mui/styles'
import colors from '../../../themes/colors/colors'

const ListViewStyle = makeStyles({
  container: {
    width: '86%',
    margin: 'auto',
  },
  containerBM: {
    display: 'flex',
    borderTop: `1px solid ${colors.footer.lineTop}`,
    padding: '15px 10px',
  },
  buttonCancel: {
    '&.MuiButton-root': {
      color: colors.palette.primary,
      borderColor: colors.palette.primary,
      '&:hover': {
        color: colors.palette.primary,
        borderColor: colors.palette.primary,
      },
    },
  },
  THGraphic: {
    borderBottom: `1px solid ${colors.footer.lineTop}`,
    padding: '40px 0 10px 50px',
    marginBottom: 20,
  },
  titleGraphic: {
    '&.MuiTypography-root': {
      fontSize: 24,
      maxWidth: 550
    },
  },
  graphicContainer: {
    width: 600,
    margin: '0 30px',
    minHeight: 330,
  },
  title: {
    '&.MuiTypography-root': {
      margin: '20px 0',
    },
  },
  subtitle: {
    '&.MuiTypography-root': {
      color: colors.palette.general,
    },
  },
  dowload: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dowloadInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 4,
  },
  dowloadIcon: {
    color: colors.buttons.outline,
  },
  buttonDowload: {
    height: 40,
    '&.MuiButton-root': {
      border: `1px solid ${colors.buttons.outline}`,
      borderRadius: 15,
      marginRight: 15,
      marginTop: 22,
      padding: 12,
    },
  },
  dowloadText: {
    '&.MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 5,
      marginTop: 0,
      color: colors.buttons.outline,
    },
  },
  survey: {
    border: `1px solid ${colors.buttons.outline}`,
    borderRadius: 15,
    padding: 8,
    fontSize: 15,
    height: 21,
    '&.MuiTypography-root': {
      marginTop: 22,
      fontWeight: 450,
      color: colors.buttons.outline,
    },
  },
  cardContainer: {
    margin: 'auto',
    marginTop: 20,
    '&.MuiPaper-root': {
      borderRadius: 30,
      border: '1px solid #ddd',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',
    marginTop: 0,
  },
  contentTime: {
    width: '20%',
    minWidth: 100,
  },
  contentAnswer: {
    width: '20%',
  },
  contentTimestamp: {
    width: '55%',
  },
  cardTypography: {
    '&.MuiTypography-root': {
      fontSize: 11,
    },
  },
  cardTypographyColor: {
    '&.MuiTypography-root': {
      color: colors.palette.general,
    },
  },
  trackContent: {
    paddingTop: 30,
  },
  track: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    marginTop: -20,
  },
  trackTimestamp: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  trackTimestampText: {
    '&.MuiTypography-root': {
      fontSize: 9,
    },
  },
  graphic: {
    '&.MuiIconButton-root': {
      '&:hover': {
        borderRadius: 40,
      },
      '& .MuiSvgIcon-root': {
        marginRight: 10,
      },
    },
  },
  fullScreen: {
    width: 35,
    height: 35,
    position: 'absolute',
    right: 15,
    bottom: 5,
    '&.MuiIconButton-root': {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  buttonsMdoal: {
    position: 'relative',
    right: -10,
    '&.MuiSvgIcon-root': {
      fontSize: 20,
      color: '#666',
      '&:hover': {
        color: colors.palette.general,
        cursor: 'pointer',
      },
    },
  },
  btnClose: ({saveModal}) => ({
    position: 'absolute',
    right: saveModal === 0 ? 40 : 20,
    top: saveModal === 0 ? 55 : 45,
    '&.MuiSvgIcon-root': {
      color: '#666',
      '&:hover': {
        color: colors.palette.error,
        cursor: 'pointer',
      },
    },
  }),
})

export default ListViewStyle
