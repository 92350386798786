import { makeStyles } from "@mui/styles";
import colors from "../../themes/colors/colors";

const useStyles = makeStyles((theme) => ({
  bodyStepTwo: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    marginBottom: "8px",
    padding: "30px 50px",
  },

  iconSearch: {
    position: "absolute",
    left: { xs: "90%", lg: "95%" },
    top: { xs: "26px", lg: "9px" },
  },

  listQuestions: {
    overflowY: "scroll",
    height: "200px",
    marginTop: "7px",
  },
  itemQuestions: {
    border: "1px solid #666666",
    margin: "5px 0px",
    borderRadius: "10px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "12px",
    [theme.breakpoints.down("lg")]: {
      width: "450px",
    },
  },
  iconClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  itemsQuestions: {
    border: "1px solid #666666",
    margin: "5px 0px",
    borderRadius: "10px",
    height: "50px",
    paddingLeft: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffff",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "93%",
  },
  itemsInTheList: {
    border: "1px solid #666666",
    width: "100%",
    margin: "5px 0px",
    borderRadius: "10px",
    height: "50px",
    whiteSpace: "nowrap",
    paddingRight: "5px",
  },
  formList: {
    padding: "13px",
    width: "700px",
    [theme.breakpoints.down("lg")]: {
      width: "450px",
    },
  },
  questionList: {
    display: "flex",
    flexDirection: "column",
    margin: "15px 0px",
    overflowY: "scroll",
    height: "200px",
  },
  searchBarContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    position: "relative",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  searchIcon: {
    position: "absolute",
    left: "95%",
  },
  searchBar: {
    width: "40%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
}));

export const styles = {
  iconSearch: {
    position: "absolute",
    left: { xs: "90%", lg: "95%" },
    top: { xs: "26px", lg: "9px" },
  },
  //-------------- FLOW ---------------
  bodyFlowItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  listFlowItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
  },
  questionTitle: {
    marginTop: "40px",
    width: "66%",
  },
  ItemAnswer: {
    display: "flex",
    alignItems: "center",
    width: "66%",
  },
  pointAnswer: {
    marginLeft: "19px",
    "&.MuiSvgIcon-root": {
      fontSize: "0.6rem",
    },
  },
  answerTitle: {
    marginLeft: "19px",
    display: "flex",
  },
  flowSections: {
    borderRadius: "13px",
    marginTop: "20px",
    padding: "30px",
  },
  questionSections: {
    marginBottom: "20px",
    color: colors.blueLighten,
    fontWeight: 600,
    fontSize: "19px",
  },
  buttonClean: {
    "& .MuiSvgIcon-root": {
      fontSize: "19px",
    },
  },
  titlesHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  textItemSelect: {
    marginLeft: "12px",
    fontWeight: "bolder",
  },
  textNotRedic: {
    fontWeight: 600,
  },
  conttextNotRedic: {
    width: "250px",
  },
};

export const classStyles = (item) => {
  return {
    itemSelect: {
      width: "250px",
      "&.MuiFormControl-root": {
        "& .MuiInputBase-root": {
          "& .MuiSelect-select": {
            whiteSpace: "break-spaces",
            paddingRight: "0px",
          },
        },
      },
      "& .MuiSelect-iconOutlined": {
        display: item.redirect_question ? "none" : "",
      },
    },
  };
};

export default useStyles;
