import { theme } from '../../themes'
import colors from '../../themes/colors/colors'


export const useStyles = {
  bg: {
    background: colors.palette.primary,
    minHeight: '100vh',
    [theme.breakpoints.down('mobile')]: {
      minHeight: 0
    }
  },
  wFull: {
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      width: '85%',
      padding: 0,
    },
    [theme.breakpoints.down('mobile')]: {
      width: '90%',
      padding: 0,
    },
    width: '75%',
    margin: 'auto',
  },
  input: {
    '&.MuiTextField-root': {
      width: '100%',
    },
  },
  typografyColor: {
    color: colors.typography.secondary,
  },
  infoText:{
    [theme.breakpoints.down('md')]: {
      fontSize: '.9em',
    },
    [theme.breakpoints.down('mobile')]: {
      fontSize: '.85em',
      '& .MuiAlertTitle-root': {
        fontSize: '1.2em',
      }
    }
  },
  form: {
    backgroundColor: 'fff',
    height: '100vh',
  },
  containerEasyStrong: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  required: {
    width: '90%',
    margin: '16px 0',
  },
  containerHeader: {
    width: '100%',
    [theme.breakpoints.down('mobile')]: {
      textAlign: 'center',
    },
    marginBottom: '16px',
  },
  success: {
    '& .MuiInputLabel-root': {
      color: colors.palette.primary,
    },
    '& fieldset':{
      borderColor: colors.palette.primary,
      '&:hover': {
        borderColor: colors.palette.primary,
      }
    }
  },
  error: {
    '& .MuiInputLabel-root': {
      color: colors.palette.error,
    },
    '& fieldset':{
      borderColor: colors.palette.error,
      '&:hover': {
        borderColor: colors.palette.error,
      }
    }
  },
  resetPassTitle: {
    '&.MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  btn: {
    '&.MuiLoadingButton-root': {
      backgroundColor: colors.buttons.primary,
      width: '100%',
    },
  },
  btnDisabled: {
    '&.MuiLoadingButton-root': {
      backgroundColor: 'none',
      width: '100%',
    },
  },
}
