import { Box, Button, Modal, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

export const ModalQuestion = (props) => {
  const {
    title = null,
    openDelete = null,
    setOpenDelete = null,
    labelCancel = null,
    labelAccept = null,
    sendDelete = null,
    trash = null,
    link = null,
  } = props;
  const styles = useStyles();

  return (
    <Modal
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className={styles.delete}>
        <Typography variant="subtitle1" className={styles.CUText}>
          {title}
        </Typography>

        <Box className={styles.CUContainerButtons}>
          <Box sx={{ flexGrow: 1 }}></Box>

          <Button
            className={`${styles.CUButtons} ${styles.CUButtonsCancel}`}
            variant="outlined"
            onClick={() => setOpenDelete(false)}
          >
            {labelCancel}
          </Button>
          {link && (
            <Button
              className={`${styles.CUButtons} ${styles.CUButtonsAccept}`}
              variant="contained"
            >
              <Link to={`${link}/${trash}`} className={styles.link}>
                {labelAccept}
              </Link>
            </Button>
          )}
          {!link && (
            <Button
              className={`${styles.CUButtons} ${styles.CUButtonsAccept}`}
              variant="contained"
              onClick={() => sendDelete(trash)}
              to={link}
            >
              {labelAccept}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
