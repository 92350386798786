import { makeStyles } from "@mui/styles";

export const styles = {
  containerBody: {
    padding: "25px 35px",
  },

  textSection: {
    fontWeight: 500,
    fontSize: 21,
    textAlign: "start",
  },

  titleControl: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  countingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  counter: {
    fontSize: "21px",
  },
  containerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "39px",
    "& .internalButtons": {
      display: "flex",
      gap: "18px",
    },
  },
};

export const useStyles = makeStyles((theme) => ({
  containerPaper: {
    // width: "100%",
    padding: "25px 35px",
    [theme.breakpoints.down(1231)]: {
      width: "790px",
    },
    [theme.breakpoints.down(900)]: {
      width: "600px",
    },
    width: "949px",
  },
}));
