import i18n from 'i18n-js'
import { isEmail, isName } from '../../../validator/validator'

export const userErrors = (user) => {
  let errors = {}

  if (!user.name) errors.name = i18n.t('users.messages.required')
  else if (isName(user.name)) errors.name = i18n.t('users.messages.validName')

  if (user.email !== null) {
    if (!user.email) errors.email = i18n.t('users.messages.required')
    else if (!isEmail(user.email)) errors.email = i18n.t('users.messages.email')
  }

  if (!user.locale) errors.locale = i18n.t('users.messages.locale')

  return errors
}

export const disabled = (error) => {
  if (error?.name || error?.email || error?.locale) return true
  return false
}
