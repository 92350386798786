import france from '../static/france.png'
import portugal from '../static/portugal.png'
import arrow from '../static/arrow.svg'
import hand from '../static/hand.svg'
import spanish from '../static/espanol.png'
import english from "../static/ingles.png"

export const image = {
  france,
  portugal,
  arrow,
  hand,
  spanish,
  english,
}
