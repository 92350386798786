import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  language: {
    display: "flex",
    gap: "10px",
    marginBottom: "12px",

    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
    alignItems: "center",
    marginRight: "25px",
  },
}));

export default useStyles;
