import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { useEffect } from "react";
import I18n from "i18n-js";
import { useCallback } from "react";
import useStyles from "../styles";
import useSaveSurveys from "../../../../hooks/useSaveSurveys";
import { questions } from "../../../../service/question";
import QuestionnairesContext from "../../../../context/questionnairesContext";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";
import { LayoutButtonLink } from "../../../../components/btnBN/layoutButtonLink";
import Loading from "../../../../components/loading";
import InformationCards from "../../../../components/sectionCards/informationCards";

const AddSection = (props) => {
  const {
    step,
    setStep,
    data,
    list,
    setList,
    filteredQuestions,
    setDisableRemov,
    disableNextTwo,
    setDisableNextTwo,
    setFilteresQuestions,

    setNoQuestions,
  } = useContext(QuestionnairesContext);
  const { addSections, deleteSection } = useSaveSurveys();
  const { id } = useParams();
  const { setView } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!list) {
      questions.list().then(({ data: data_ }) => {
        const list_ = data_.questions.map((item, key) => {
          const typeQuestion = item.question_type?.name ?? "";
          return {
            title: item.title,
            type: typeQuestion,
            id: item.id,
            used: false,
            key: null,
          };
        });
        setList(list_);
        setFilteresQuestions(list_);
        if (id && !(id && data.sections.length === 0)) {
          let aux;
          const auxList = list_.map((item) => {
            let i = 0;
            do {
              let j = 0;
              do {
                if (data.sections[i].questions[j].question_id === item.id) {
                  aux = {
                    ...item,
                    used: true,
                    key: data.sections[i].questions[j].key,
                  };

                  i = data.sections.length - 1;
                  break;
                } else {
                  aux = item;
                }

                j = j + 1;
              } while (j < data.sections[i]?.questions.length);

              i = i + 1;
            } while (i < data.sections.length);
            return aux;
          });

          setList(auxList);
          setFilteresQuestions(auxList);
        }
      });
      if (!id || (id && data.sections.length === 0)) addSections();
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (validateFields()) {
      setDisableNextTwo(true);
    } else {
      setDisableNextTwo(false);
    }
    data.sections && data.sections.length > 1
      ? setDisableRemov(false)
      : setDisableRemov(true); //eslint-disable-next-line
  }, [data.sections]);

  useEffect(() => {
    const isExiste = filteredQuestions?.filter((item) => item?.used === false);

    isExiste?.length === 0 ? setNoQuestions(true) : setNoQuestions(false); //eslint-disable-next-line
  }, [filteredQuestions]);

  const validateFields = useCallback(() => {
    return data?.sections?.some((element) => {
      return (
        element.title.filter((item) => !item.value || item.value === "")
          .length >= 1 ||
        element.description.filter((item) => !item.value || item.value === "")
          .length >= 1 ||
        element.questions.length === 0
      );
    });
  }, [data.sections]);

  if (!filteredQuestions) return <Loading />;
  return (
    <Box>
      {data?.sections
        ?.slice(0)
        .reverse()
        .map((item, key) => (
          <InformationCards
            deleteSection={deleteSection}
            element={item}
            index={key}
            key={item.key}
          />
        ))}

      <Box className={classes.buttons}>
        <LayoutButtonLink
          label={I18n.t("general.cancelBtn")}
          link={"/dashboard/questionnaires"}
        />
        <Box className={classes.nextBack}>
          <LayoutButton
            variant="outlined"
            label={I18n.t("general.backBtn")}
            click={() => setStep(step - 1)}
          />
          <LayoutButton
            variant="contained"
            label={I18n.t("general.nextBtn")}
            click={() => setView(true)}
            disabled={disableNextTwo}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddSection;
