import TYPE from '../tipe.json'

const newUser = (state, action) => {
    switch (action.type) {
        case TYPE.EMAIL:
            return {...state, email: action.payload.trim(), error: { ...state.error, infEmail: '' }}
        case TYPE.PASSWORD:
            return {...state, password: action.payload.trim(), error: { ...state.error, infPass: '' }}
        case TYPE.EMAIL_VERIFY: {
            return { ...state, email: '', error: { ...state.error, infEmail: 'wrong email format' } }
        }
        case TYPE.PASSWORD_VERIFY: {
            return { ...state, password: '', error: { ...state.error, infPass: 'password is required' } }
        }
        default:
            return state
    }
}

export default newUser
