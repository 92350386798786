import { Button, FormControlLabel, Paper, Switch } from "@mui/material";
import { Box } from "@mui/system";
import I18n from "i18n-js";
import { useContext, useState } from "react";
import numStep from "../../../../utils/questionStep";
import useStyles from "../../question/styles";
import Checked from "./checked";
import NotChecked from "./notChecked";
import QuestionContext from "../../../../context/questionContext";
import enumsTypeQuestion from "../../../../enums/typeOfQuestion";
import { questions } from "../../../../service/question";
import { Link, useParams } from "react-router-dom";
import { ModalYouSure } from "../../../../components/modal/modalYouSure/index";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";

const StepThree = (props) => {
  const classes = useStyles();
  const [disabledOne, setDisabledOne] = useState(true);
  const [openModalGroup, setOpenModalGroup] = useState(false);
  const [actSpinner, setActSpinner] = useState(false);
  const { id } = useParams();
  const {
    typeOfQuestion,
    setTypeOfQuestion,
    data,
    valueSwitch,
    setValueSwitch,
    setGroupName,
    isEdition,
    setDataOpen,
    setArrayTranslations,
  } = useContext(QuestionContext);

  const changeSwitch = () => {
    const isEscalarNoCheck =
      typeOfQuestion === enumsTypeQuestion.escalar_numeric && !valueSwitch;
    const isOptions = data.options && data.options.length > 0;
    if (isOptions) {
      if (isEscalarNoCheck) {
        findEmpty() ? setOpenModalGroup(true) : resetData();
      } else {
        setOpenModalGroup(true);
      }
    } else {
      resetData();
    }
  };

  const resetData = () => {
    setDataOpen("");
    setActSpinner(false);
    delete data.option_group_id;
    delete data.options;
    setGroupName("");
    setValueSwitch(!valueSwitch);
    setTypeOfQuestion("");
    setDisabledOne(true);
    setArrayTranslations(null);
  };

  const findEmpty = () => {
    let dataElement;
    const auxItem = data.options.map((item) => {
      const auxElement = item.title.filter((element) => element.value);
      return auxElement.length !== 0 || item.weight
        ? { auxElement, weight: item.weight }
        : undefined;
    });
    dataElement = auxItem?.filter((element) => element);
    if (dataElement.length === 0) return false;
    if (dataElement.length > 0) return true;
  };

  const buttonNext = async () => {
    if (valueSwitch) {
      const body = { ...data };
      const options = body.options;
      const newAnswers = options.map((option) => {
        const newAnswer = { ...option };
        if (newAnswer.weight === null) newAnswer.weight = null;
        if (newAnswer.weight)
          newAnswer.weight = Number.parseInt(newAnswer.weight);
        return newAnswer;
      });
      body.options = newAnswers;
      let resp;
      if (isEdition) {
        resp = await questions.editQuestion(id, body);
      } else {
        resp = await questions.createQuestion(body);
      }
      if (resp.data) {
        props.setStepsAct(numStep.five);
      }
    } else {
      props.setStepsAct(numStep.four);
    }
  };

  const delateOptionsGruop = () => {
    resetData();
    setOpenModalGroup(false);
  };

  return (
    <Paper
      elevation={5}
      className={classes.formThree}
      sx={{ borderRadius: "20px" }}
    >
      <ModalYouSure
        openModal={openModalGroup}
        setOpenModal={setOpenModalGroup}
        eventAccept={delateOptionsGruop}
        actionText={I18n.t("question.modal.changeTheAnswerOption")}
      />
      <FormControlLabel
        control={
          <Switch
            disabled={typeOfQuestion === enumsTypeQuestion.open ? true : false}
            checked={valueSwitch}
            onClick={() => changeSwitch()}
          />
        }
        label={I18n.t("question.useAnExistingGroupOfAnswers")}
        sx={{ width: { xs: "100%", md: "350px" } }}
      />
      <Box className={classes.bodyText}>
        {valueSwitch ? (
          <Checked
            actSpinner={actSpinner}
            setActSpinner={setActSpinner}
            setDisabledOne={setDisabledOne}
            valueSwitch={valueSwitch}
          />
        ) : (
          <NotChecked
            setDisabledOne={setDisabledOne}
            valueSwitch={valueSwitch}
          />
        )}

        <Box className={classes.buttonPart}>
          <Button>
            <Link
              className={classes.link}
              to={"/dashboard/question/questionlist"}
            >
              {I18n.t("general.cancelBtn")}
            </Link>
          </Button>
          <Box className={classes.internalButtonBox}>
            <LayoutButton
              variant="outlined"
              click={() => {
                props.setStepsAct(numStep.two);
              }}
              label={I18n.t("general.backBtn")}
            />
            <LayoutButton
              disabled={disabledOne}
              variant="contained"
              click={() => {
                buttonNext();
              }}
              label={I18n.t("general.nextBtn")}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default StepThree;
