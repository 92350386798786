import { useLayoutEffect, useState } from 'react'

//router
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'

//material ui
import { Box } from '@mui/material'

// layout
import Footer from '../../layout/Footer'
import Navbar from '../../layout/Navbar'

//pages
import ListAnswer from '../answers/ListAnswer'
import ListView from '../answers/ListView'
import Home from './home'
import Setting from './settingRoutes/routes'
import NotFound from '../404'
import { isValidToken } from '../../service/validateToken'
import { useCallback } from 'react'
import Loading from '../../components/loading'

// Styles
import dashboardStyles from './themes/dashboard'

const Dashboard = () => {
  const [open, setOpen] = useState(false)

  const history = useHistory()
  const [load, setLoad] = useState(true)
  const classes = dashboardStyles(open)
  const logOut = useCallback(() => history.push('/logout'), [history])

  const verifyToken = async (item) => {
    if (history.location.pathname !== item.path) {
      setLoad(true)
      const response = await isValidToken()
      if (response.data) history.push(item.path)
      setLoad(false)
    }
  }

  useLayoutEffect(() => {
    setLoad(true)
    isValidToken()
      .then((response) => {
        if (!response?.data) logOut()
        setLoad(false)
      })
      .catch((e) => logOut())
  }, [logOut])

  return (
    <Box className={classes.container}>
      <Navbar verifyToken={verifyToken} open={open} setOpen={setOpen} />

      <Box className={classes.section}>
        {!load ? (
          <Switch>
            <Route exact path="/dashboard" component={Home} />

            <Route exact path="/dashboard/answers/view/:id" component={ListView} />

            <Route exact path="/dashboard/answers" component={ListAnswer} />

            <Setting />

            <Route path="/404" component={NotFound} />

            <Route path="*">
              <Redirect to="/404" />
            </Route>
          </Switch>
        ) : (
          <Loading />
        )}
      </Box>

      <Footer />
    </Box>
  )
}

export default Dashboard
