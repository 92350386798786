import i18n from 'i18n-js'
import { isEmpty } from '../../../../validator/validator'

export const handleErrors = (errors) => {
  let error = {}

  Object.entries(errors).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      error[key] = i18n.t(`users.messages.required`)
    }
  })

  return error
}

export const handleSendData = (data) => {
  let body = {
    title: [],
    options: [],
  }

  Object.entries(data).forEach(([key, value]) => {
    if (!key.includes('File')) {
      body = {
        ...body,
        title: [
          ...body.title,
          {
            locale: key,
            value,
          },
        ],
      }
    } else {
      let temporalArray = []

      value?.split(',')?.forEach((item) => {
        temporalArray.push({
          title: [
            {
              locale: key.replace('File', ''),
              value: item,
            },
          ],
        })
      })

      if (body.options.length > 0) {
        temporalArray.forEach((item, ind) => {
          body.options[ind] = {
            title: body.options?.[ind]?.title?.[0] ? [...body.options?.[ind]?.title, item?.title[0]] : [item?.title[0]],
          }
        })
      } else {
        body.options = temporalArray
      }
    }
  })

  return body
}
