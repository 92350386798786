const styles = {
  arrayNumbers: {
    display: "flex",
    justifyContent: "space-between",
    margin: "40px 0px 10px 0px",
  },
  numbers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    border: "3px solid #078368",
    height: { xs: "20px", md: "39px" },
    width: { xs: "20px", md: "39px" },
    color: " #078368",
    fontWeight: 600,
  },
  minMax: {
    display: "flex",
    justifyContent: "space-between",
  },

  labels: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
    margin: "50px 0px",
    gap: "12px",
  },
  inputs: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
    marginBottom: "12px",
  },
};

export default styles;
