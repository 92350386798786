import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { icons } from "../../utils/icons";
import useStyles from "./style";
const LanguagesText = (props) => {
  const classes = useStyles();

  const { name, locale, handleChangeTraslation, text, getDataIdiom, bam, id } =
    props;

  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.language}>
        {icons(locale)}
        <Typography sx={{ fontSize: "15px" }} color="GrayText">
          {text}
        </Typography>
      </Box>

      <TextField
        value={
          !bam
            ? getDataIdiom(locale)?.[name] ?? ""
            : getDataIdiom(locale, id?.key) ?? ""
        }
        fullWidth
        multiline
        name={name}
        size="small"
        onChange={(e) => handleChangeTraslation(e, locale, id.key)}
        onInput={(e) => validateInput(e)}
        inputProps={{ maxLength: 255 }}
      />
    </Box>
  );
};

export default LanguagesText;
