import { useContext, useEffect, useState } from "react";
import TranslatorAsks from "../../../../components/translatorAsks";
import QuestionContext from "../../../../context/questionContext";
import I18n from "i18n-js";
import numStep from "../../../../utils/questionStep";
import { Paper } from "@mui/material";
import useStyles from "../../question/styles";

const StepTwo = (props) => {
  const {
    setSteps,
    getDataIdiom,
    userLocale,
    localesList,
    handleChangeTraslation,
    data,
  } = useContext(QuestionContext);
  const deleteData = () => {
    return;
  };
  const dataImpor = getDataIdiom(userLocale);
  const { ...newData } = dataImpor;
  const [nameVar, setNameVar] = useState([]);
  const classes = useStyles();
  const labelContent = [
    I18n.t("question.form.title"),
    I18n.t("question.form.description"),
    I18n.t("question.form.instructions"),
  ];
  const arrayData = Object.entries(newData);
  const dataFiltered = arrayData.filter((item) => {
    return item[1] !== "" || item[1];
  });

  const dataFiltered_ = dataFiltered.reduce((target, key) => {
    target[key[0]] = key[1];
    return target;
  }, {});
  useEffect(() => {
    if (data.instructions) {
      setNameVar(["title", "description", "instructions"]);
    } else {
      setNameVar(["title", "description"]);
    } //eslint-disable-next-line
  }, []);

  return (
    <Paper elevation={5} className={classes.contTranslation}>
      <TranslatorAsks
        contentAsk={dataFiltered_}
        labelContent={labelContent}
        setSteps={setSteps}
        nextStep={numStep.three}
        backStep={numStep.one}
        arrayElement={localesList}
        handleChangeTraslation={handleChangeTraslation}
        getDataIdiom={getDataIdiom}
        nameVar={nameVar}
        bam={false}
        data={data}
        deleteData={deleteData}
      />
    </Paper>
  );
};

export default StepTwo;
