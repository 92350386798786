import QuestionLayout from "./questionLayout";
import ProviderQuestion from "../../../provider/providerQuestion";

const QuestionForm = () => {
  return (
    <ProviderQuestion>
      <QuestionLayout />
    </ProviderQuestion>
  );
};

export default QuestionForm;
