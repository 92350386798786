import { Box, Breadcrumbs, CircularProgress, InputAdornment,/*  Tab, Tabs, */ Typography } from '@mui/material'
import I18n from 'i18n-js'
import { useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import AlertModal from '../../../components/alertModal'
import CrudsBtn from '../../../components/crudsBtn'
import StepperCreate from '../../../components/stepperCreate'
import Step1 from './steps/step1'
import Steps2 from './steps/step2'
import useStyles from './style'
import { icons, languages } from '../../../utils'
import { CheckCircle } from '@mui/icons-material'
import { useEffect } from 'react'
import LinkStyles from '../../../themes/js/Link'
import { useHistory } from 'react-router-dom'
import { handleErrors, handleSendData } from './methods'
import { optionGroup } from '../../../service/optionGroup'
import Loading from '../../../components/loading'

const Form = () => {
  const history = useHistory()
  const { id } = useParams()
  const locale = localStorage.getItem('lang')
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [length, setLegth] = useState({})
  const [errors, setErrors] = useState()
  const [arrFiles, setArrFiles] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [finish, setFinish] = useState(false)
  const [flat, setFlat] = useState(false)
  const [flatTranslate, setFlatTranslate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [current/* , setCurrent */] = useState(languages.find((lang) => lang.locale !== locale).locale)

  const [btnD, setBtnD] = useState(true)

  let [inputs, setInputs] = useState([
    {
      name: locale,
      label: `${I18n.t('optionGroup.inputs.category')} (*)`,
      fullWidth: true,
      error: errors?.[locale],
      InputProps: {
        startAdornment: <InputAdornment position="start">{icons(locale, true)}</InputAdornment>,
      },
      styles: classes.input,
    },
    {
      name: `${locale}File`,
      label: `${I18n.t('optionGroup.inputs.subCategory')} (*)`,
      fullWidth: true,
      error: errors?.[`${locale}File`],
      multiline: true,
      rows: 3,
    },
  ])

  const steps = [
    {
      step: 'optionGroup.steps.stepOne',
      label: 'optionGroup.steps.generalInformation',
    },
    {
      step: 'optionGroup.steps.stepTwo',
      label: 'optionGroup.steps.translate',
      styles: classes.textArea,
    },
  ]

  const getData = async () => {
    let objLength = {}

    languages.forEach((locale) => {
      objLength = {
        ...objLength,
        [locale.locale]: 0,
      }
    })

    setLegth(objLength)

    if (id) {
      const res = await optionGroup.detail(id)

      if (res.data) {
        let obj = {}
        let objLength = {}

        res.data.option_group.translations.forEach((item) => {
          obj = {
            ...obj,
            [item.locale]: item.title,
          }
        })

        res.data.option_group.options.forEach((item) => {
          item.translations.forEach((item2) => {
            const localeFile = `${item2.locale}File`
            obj = {
              ...obj,
              [localeFile]: obj[[localeFile]] ? `${obj[[localeFile]]},${item2.title}` : item2.title,
            }
          })
        })

        Object.entries(obj).forEach(([key, item]) => {
          if(key.includes('File')) {
            objLength = {
              ...objLength,
              ...handleLength(item, key)
            }
          }
        })

        setLegth(objLength)
        setValues(obj)
      }
    }

    setLoading(false)
  }

  const handleFileLocale = (values) => setArrFiles(values?.split(',').filter((item) => item.trim() !== ''))

  const handleSteps = (value) => {
    if (value < 0) setBtnD(true)

    if (activeStep === 0 && value > 0) {
      handleFileLocale(values?.[`${locale}File`])

      let maxTranslateItem = {}
      let newObjectLenght = {}

      Object.entries(values).forEach(([key, item]) => {
        if (key.includes('File')) {
          newObjectLenght = {
            ...newObjectLenght,
            ...handleLength(item, key),
          }

          maxTranslateItem = {
            ...maxTranslateItem,
            [key]: item
              .split(',')
              .splice(0, length[locale])
              .filter((item) => item.trim() !== '')
              .join(','),
          }
        } else {
          maxTranslateItem = {
            ...maxTranslateItem,
            [key]: item,
          }
        }
      })
      setLegth(newObjectLenght)
      setValues(maxTranslateItem)
    } else if (activeStep === 1 && value > 0) {
      const validateSend = []
      Object.entries(values).forEach(([key, value]) => {
        if (!key.includes('File')) {
          if (value === '' || length[key] < length[locale]) {
            validateSend.push(key)
          }
        }
      })

      if (validateSend?.length > 0) {
        setOpenModal(true)
        setActiveStep(1)
      } else {
        handleSend()
      }
    }
    if (activeStep < 1 || value < 0) {
      setActiveStep(activeStep + value)
    }
  }

  const handleSend = async () => {
    const resp = await handleSendData(values)
    let optionResp

    if (id) {
      optionResp = await optionGroup.updateOptionGroup(resp, id)
    } else {
      optionResp =  await optionGroup.createOptionGroup(resp)
    }

    if(optionResp.data) {
      setFinish(true)
      setActiveStep(activeStep + 1)
    }
  }

  // const handleChangeLang = (_, value) => setCurrent(value)

  const handleChange = async ({ target }, modify = false) => {
    const { name, value, current } = target

    if (name === 'file') {
      const resp = await handleFile(current, value)
      const valueFile = current.includes(locale) ? resp : resp.split(',').splice(0, length[locale]).join(',')

      setLegth({ ...length, ...handleLength(valueFile, current) })

      setValues({ ...values, [current]: valueFile })
    } else if (modify) {
      handleFileLocale(value)

      let copyValues = {}
      let copyLength = {}

      Object.entries(values).forEach(([key, val]) => {
        if (key.includes('File')) {
          if (!key.includes(locale)) {
            let arrayFilter = val.split(',').filter((item, i) => i !== modify.index && item.trim() !== '')

            copyLength = { ...copyLength, ...handleLength(arrayFilter.join(), key) }

            copyValues = {
              ...copyValues,
              [key]: arrayFilter.join(','),
            }
          } else {
            copyLength = { ...copyLength, ...handleLength(value, key) }

            copyValues = {
              ...copyValues,
              [key]: value,
            }
          }
        } else {
          copyValues = {
            ...copyValues,
            [key]: val,
          }
        }
      })

      setValues(copyValues)
      setLegth(copyLength)
    } else {
      const valueFile = name.includes(locale)
        ? value
        : value
            .split(',')
            .filter((item) => item.trim() !== '')
            .splice(0, length[locale])
            .join(',')

      if (name.includes('File')) {
        setLegth({ ...length, ...handleLength(valueFile, name) })
      }

      setValues({
        ...values,
        [name]: valueFile,
      })
    }
  }

  const handleLength = (value, name = null) => {
    let lenght = value.split(',').filter((item) => item.trim() !== '').length

    return {
      [name.replace('File', '')]: lenght,
    }
  }

  const handleFile = (name, value) => {
    if (value.type === 'application/vnd.ms-excel' || value.type === 'text/csv') {
      const reader = new FileReader()

      return new Promise((res) => {
        reader.onload = function (e) {
          let currentValue = values[name] && values[name] !== '' ? values[name] : ''
          currentValue = currentValue
            .split(',')
            .filter((item) => item.trim() !== '')
            .join(',')

          let text = e.target.result

          let first = text.slice(0, text.indexOf('\r\n'))

          if (first.includes(',')) {
            first = first
              .split(',')
              ?.map((item) => {
                if (item.includes('"')) {
                  return item.replace(/"/g, '')
                }
                return item
              })
              ?.filter((item) => item !== '')
              ?.join(',')

            currentValue = handleUnion(',', currentValue, first)
          } else {
            text = text
              ?.split('\r\n')
              ?.map((item) => {
                if (item.includes('"')) {
                  return item.replace(/"/g, '')
                }
                return item
              })
              ?.filter((item) => item.trim() !== '')
              ?.join(',')

            currentValue = handleUnion(',', currentValue, text)
          }

          res(currentValue
            .split(',')
            .filter((item) => item.trim() !== '')
            .join(','))
        }

        reader.readAsText(value)
      })
    }
  }

  const handleUnion = (union, fileExist, newFile) => {
    return fileExist !== '' ? fileExist + union + newFile : newFile
  }

  const handleDisabled = () => length?.[locale] <= 0 || values[locale] === undefined || values[locale] === ''

  const addTranslate = () => {
    let langTranslate = []
    let valueTranslate = {}

    languages.forEach((values) => {
      if (values.locale !== locale) {
        valueTranslate = {
          ...valueTranslate,
          [values.locale]: '',
          [`${values.locale}File`]: '',
        }

        langTranslate.push({
          name: values.locale,
          label: I18n.t('optionGroup.inputs.category'),
          fullWidth: true,
          // error: errors?.[values.locale],
          InputProps: {
            startAdornment: <InputAdornment position="start">{icons(values.locale, true)}</InputAdornment>,
          },
          styles: `${classes.inputM} ${classes.input}`,
        })
      }
    })

    setInputs([...inputs, ...langTranslate])
    setValues({ ...values, ...valueTranslate })
  }

  const handleError = useCallback(
    (error) => {
      const errors = handleErrors(values)
      setErrors(errors)

      const newInputs = inputs?.map((item) => {
        if (errors?.[item?.name] && item?.name?.includes(locale)) {
          return {
            ...item,
            error: true,
          }
        }
        return {
          ...item,
          error: false,
        }
      })

      setInputs(newInputs)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  )

  useEffect(() => {
    if (!flat) {
      getData()
      setFlat(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flat])

  useEffect(() => handleError(), [handleError])

  useEffect(() => {
    if (!flatTranslate) {
      addTranslate()
      setFlatTranslate(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatTranslate])

  if (loading) return <Loading />

  return (
    <Box className={classes.container}>
      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t('drawer.dashboard')}
        </Link>

        <Link style={LinkStyles} to="/dashboard/option-group">
          {I18n.t('optionGroup.breadcrumbs.title')}
        </Link>

        <Link style={LinkStyles} to={history.location.pathname}>
          {I18n.t('optionGroup.breadcrumbs.create')}
        </Link>
      </Breadcrumbs>
      {/* header */}
      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            <Typography variant="h6">{id ? I18n.t('optionGroup.breadcrumbs.edit') : I18n.t('optionGroup.breadcrumbs.create')}</Typography>
          </Box>
          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
      </Box>

      {id && JSON.stringify(values) === '{}' ? (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      ) : (
        <>
          {activeStep !== 2 && !finish ? (
            <Box className={`${classes.card}`/*  ${classes.cardLeftCorner}` */}>
              {/* {activeStep > 0 && (
                <Tabs value={current} onChange={handleChangeLang} className={classes.tabs}>
                  {languages.map((values) => {
                    if (values.locale !== locale) {
                      return <Tab key={values.locale} icon={icons(values.locale)} iconPosition="start" value={values.locale} className={classes.tab} />
                    } else {
                      return null
                    }
                  })}
                </Tabs>
              )} */}

              {activeStep === 0 && <Step1 inputs={inputs.slice(0, 2)} handleChange={handleChange} values={values} errors={errors} length={length?.[locale]} current={`${locale}File`} locale={locale} />}

              {activeStep === 1 && (
                <Steps2
                  inputs={inputs.slice(
                    inputs.findIndex((item) => item.name === current),
                    inputs.findIndex((item) => item.name === current) + 1
                  )}
                  values={values}
                  file={arrFiles}
                  locale={locale}
                  onChange={handleChange}
                  length={length}
                  errors={errors}
                  current={current}
                />
              )}

              <Box className={classes.containerFlex}>
                <Box className={classes.fullRight} />

                <Box mt={3}>
                  {activeStep > 0 && <CrudsBtn text={I18n.t('general.cancelBtn')} click={() => handleSteps(-1)} variant="outlined" />}

                  <CrudsBtn disabled={handleDisabled() && btnD === true} text={I18n.t('general.nextBtn')} className={classes.button} click={() => handleSteps(1)} />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classes.finishContainer}>
                <CheckCircle className={classes.chekCircle} />
                <Typography variant="h5" color="textPrimary">
                  {I18n.t('general.success')}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {!id ? I18n.t('optionGroup.steps.created') : I18n.t('optionGroup.steps.edited')}
                </Typography>
              </Box>

              <Box className={classes.endButtonContainer}>
                <CrudsBtn text={I18n.t('optionGroup.steps.return')} to={'/dashboard/option-group'} component={Link} variant="contained" />
              </Box>
            </>
          )}
        </>
      )}

      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('general.traductions.missingTraductionTitle')}
        message={I18n.t('general.traductions.missingTraductiontext')}
        SuccessText={I18n.t('general.yes')}
        success={handleSend}
      />
    </Box>
  )
}

export default Form
