import { isValidPassword } from '../../../validator/validator'

export const ressetError = (error) => {
  const { password, password_confirmation } = error

  let listError = {}

  if (password !== null) {
    let count = isValidPassword(error.password)

    listError.password = count !== 6
    listError.completedStep = count
  }

  if (password_confirmation !== null) listError.password_confirmation = password_confirmation !== password

  return listError
}
