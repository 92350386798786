import { GET, POST, PUT, DELETE } from "../utils/crud";

const list = async () => {
  const res = await GET("/questions");

  return res;
};

const validateQuestion = async (id) => {
  const res = await GET(`/validate/question/used?question_id=${id}`);

  return res;
};

const createQuestion = async (body) => {
  const res = await POST("/questions", body);

  return res;
};

const detailQuestion = async (id) => {
  const res = await GET(`/questions/${id}`);

  return res;
};

const editQuestion = async (id, body) => {
  const res = await PUT(`/questions/${id}`, body);

  return res;
};

const deleteQuestions = async (id) => {
  const res = await DELETE(`/questions/${id}`);

  return res;
};

export const questions = {
  list,
  createQuestion,
  deleteQuestions,
  detailQuestion,
  editQuestion,
  validateQuestion,
};
