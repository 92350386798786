import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import styles from "./styles";
import I18n from "i18n-js";
import QuestionContext from "../../context/questionContext";
import { useContext, useEffect } from "react";
import { colors } from "../../utils";

const NumericScale = (props) => {
  const { disabled, arrayTranslations = null, setDisabledOne } = props;
  const maxMin = { max: 1, min: 0 };
  const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { setData, data, userLocale, isEdition } = useContext(QuestionContext);

  useEffect(() => {
    if (disabled) setDisabledOne(true);
    if (disabled && !data.options) {
      setData({
        ...data,
        options: [
          createOptions(null, maxMin.min),
          createOptions(null, maxMin.max),
        ],
      });
    }
    if (arrayTranslations) {
      loadInitialData();
    }

    if (isEdition) {
      if (!data.options) {
        loadInitialData();
      } else {
        loadInitialData();
      }
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!disabled) loadInitialData(); // eslint-disable-next-line
  }, [arrayTranslations]);

  useEffect(() => {
    if (data.options) {
      if (data?.options[0] && data?.options[1]) {
        const options0 = data.options[0].title.find(
          (item) => item.locale === userLocale
        ).value;
        const options1 = data.options[1].title.find(
          (item) => item.locale === userLocale
        ).value;
        if (options0 && options1 && options0 !== "" && options1 !== "")
          setDisabledOne(false);

        if (options0 === "" || options1 === "") setDisabledOne(true);
      }
    } //eslint-disable-next-line
  }, [data?.options]);

  const loadInitialData = () => {
    if (arrayTranslations) {
      setData({
        ...data,
        options: [
          createOptions(
            arrayTranslations[0]?.id,
            maxMin.min,
            arrayTranslations[0]?.title,
            arrayTranslations[0]?.weight
          ),
          createOptions(
            arrayTranslations[1]?.id,
            maxMin.max,
            arrayTranslations[1]?.title,
            arrayTranslations[1]?.weight
          ),
        ],
      });
    } else {
      setData({
        ...data,
        options: [
          createOptions(null, maxMin.min, null, null),
          createOptions(null, maxMin.max, null, null),
        ],
      });
    }
  };

  const buildOptions = (newData, e) => {
    const newOption = newData.map((item) => {
      return item.key === Number(e.name)
        ? { ...item, title: [{ locale: userLocale, value: e.value }] }
        : { ...item };
    });
    return newOption;
  };

  const validarEntradasConPunto = (e) => {
    e.target.value = e.target.value.replace(/[^0-9-]|(?<=.)-/, "");
  };
  const createOptions = (id_ = null, e, value = null, weight = null) => {
    let newOption;
    if (value) {
      newOption = value;
    } else {
      newOption = [{ locale: userLocale, value: value }];
    }

    return {
      id: id_,
      key: e,
      option_group_id: data.option_group_id,
      title: newOption,
      weight: weight,
    };
  };
  const handleChange = (e) => {
    if (data.options) {
      const newData = data.options;
      setData({
        ...data,
        options: buildOptions(newData, e.target),
      });
    }
  };

  const seekValue = (key) => {
    const options_ = data.options;
    if (!options_) return undefined;
    if (disabled) {
      let auxOptions = options_.find((item) => {
        if (!item) return undefined;
        return key === item.key;
      });
      if (auxOptions) {
        return auxOptions?.title?.find((item) => item?.locale === userLocale)
          .value;
      } else {
        return undefined;
      }
    }
    if (!disabled) {
      let auxOptions = options_.map((item) => {
        return item?.title?.find((element) => element?.locale === userLocale)
          .value;
      });

      return auxOptions[key];
    }
  };

  const handleChangeWeight = (e) => {
    const options_ = data.options;
    const { name, value } = e.target;
    const newData = options_.map((item) => {
      return item.key === Number(name)
        ? { ...item, weight: value }
        : { ...item };
    });
    setData({ ...data, options: newData });
  };

  const seeValue = (key) => {
    const options_ = data.options;
    const res = options_?.find((item) => item?.key === key);
    if (!options_ || !res?.weight) return undefined;
    return res.weight;
  };
  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  if (!data.options) return <></>;
  return (
    <Box>
      <Box sx={styles.arrayNumbers}>
        {list.map((item, key) => (
          <Box key={key} sx={styles.numbers}>
            {item}
          </Box>
        ))}
      </Box>
      <Box sx={styles.minMax}>
        <Typography color={colors.palette.greyDark}>
          {I18n.t("question.title.min")}
        </Typography>
        <Typography color={colors.palette.greyDark}>
          {I18n.t("question.title.max")}
        </Typography>
      </Box>
      <Box sx={styles.labels}>
        <Box sx={styles.inputs}>
          <TextField
            margin="normal"
            label={I18n.t("question.scale.lowestValueLabel")}
            sx={{ width: { xs: "auto", md: "65%" } }}
            name="0"
            value={seekValue(0) ?? ""}
            disabled={!disabled}
            onChange={handleChange}
            onInput={(e) => validateInput(e)}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            onInput={validarEntradasConPunto}
            label={I18n.t("question.form.value")}
            margin="normal"
            sx={{ width: { xs: "auto", md: "30%" } }}
            name="0"
            value={seeValue(0) ?? ""}
            onChange={handleChangeWeight}
            inputProps={{ maxLength: 9 }}
          />
        </Box>
        <Box sx={styles.inputs}>
          <TextField
            margin="normal"
            fullWidth
            label={I18n.t("question.scale.hightestValueLabel")}
            sx={{ width: { xs: "auto", md: "65%" } }}
            name="1"
            value={seekValue(1) ?? ""}
            disabled={!disabled}
            onChange={handleChange}
            onInput={(e) => validateInput(e)}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            onInput={validarEntradasConPunto}
            label={I18n.t("question.form.value")}
            margin="normal"
            sx={{ width: { xs: "auto", md: "30%" } }}
            name="1"
            value={seeValue(1) ?? ""}
            onChange={handleChangeWeight}
            inputProps={{ maxLength: 9 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NumericScale;
