const functionToObject = (value) => {
  const step1 = value.split('(')

  const step2 = step1[1].split(')')

  const step3 = step2[0].split(', ')
  let step4 = '{'

  const length = step3.length - 1
  step3.forEach((step, ind) => {
    const step5 = step.split('=')

    step4 += `"${step5[0]}": "${step5[1]}"`

    if (ind < length) {
      step4 += ','
    }
  })
  step4 += `}`

  return JSON.parse(step4)
}

const timestampRest = (stamp, newTimeStandAccelerometer, idData, idTrack, idStamp) => {
  const timestamp2 = stamp[idData].tracking[idTrack].timestamp2
  const newData = stamp[idData].tracking[idTrack]
  let newTracking = {}

  if (timestamp2 === undefined) {
    newTracking = { ...newData, timestamp2: [newTimeStandAccelerometer[idStamp]] }
  } else {
    newTracking = { ...newData, timestamp2: [...timestamp2, newTimeStandAccelerometer[idStamp]] }
  }

  return newTracking
}

export const sumary = (sumary) => {
  const watch_sumary = sumary.filter((sumary) => sumary.type === 'BiomarkersSummaryData')

  let copy = watch_sumary.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))

  copy.forEach((value) => {
    const res = functionToObject(value.value)

    value.value = { ...res, date: value.timestamp }
  })

  return copy
}

export const timestamp = (stamp, data) => {
  // acclerometer values are deconstructed in a json format
  const newTimeStandAccelerometer = stamp.map((value) => {
    if (value.type === 'accelerometer') {
      return { ...value, value: functionToObject(value.value) }
    }

    return value
  })

  let newData = [...data]

  let cont = 0

  let idData = 0,
    idTrack = 0,
    timeS = 0,
    timeTrackCurrent = 0,
    timeTrackBack = 0

  newTimeStandAccelerometer.forEach((valueS, idS) => {
    cont = 0

    data.forEach((valueD, idD) => {
      valueD.tracking.forEach((valueT, idT) => {
        if (cont === 0) {
          if (Date.parse(valueS.timestamp) <= Date.parse(valueT.timestamp) || (idD === data.length - 1 && idT === valueD.tracking.length - 1 && Date.parse(valueS.timestamp) > Date.parse(valueT.timestamp))) {
            timeS = Date.parse(valueS.timestamp)
            timeTrackCurrent = Date.parse(valueT.timestamp)
            cont += 1
            idData = idD
            idTrack = idT
          }
        }
      })
    })

    if (cont === 1) {
      if (idTrack > 0) {
        timeTrackBack = Date.parse(newData[idData].tracking[idTrack - 1].timestamp)

        if (timeS - timeTrackBack > timeTrackCurrent - timeS) {
          newData[idData].tracking[idTrack] = timestampRest(newData, newTimeStandAccelerometer, idData, idTrack, idS)
        } else {
          newData[idData].tracking[idTrack - 1] = timestampRest(newData, newTimeStandAccelerometer, idData, idTrack - 1, idS)
        }
      }

      if (idData > 0 && idTrack === 0) {
        const lenght = newData[idData - 1].tracking.length - 1
        timeTrackBack = Date.parse(newData[idData - 1].tracking[lenght].timestamp)

        if (timeS - timeTrackBack > timeTrackCurrent - timeS) {
          newData[idData].tracking[idTrack] = timestampRest(newData, newTimeStandAccelerometer, idData, idTrack, idS)
        } else {
          newData[idData - 1].tracking[lenght] = timestampRest(newData, newTimeStandAccelerometer, idData - 1, lenght, idS)
        }
      }

      if (idData === 0 && idTrack === 0) {
        newData[idData].tracking[idTrack] = timestampRest(newData, newTimeStandAccelerometer, idData, idTrack, idS)
      }
    }
  })

  return newData
}

export const timestampGraphic = (data) => {
  let arr = {}

  data.forEach(({ type, timestamp, value }, ind) => {
    if (type !== 'BiomarkersSummaryData') {
      let newValue = parseFloat(value)
      let minSec = parseFloat(`${new Date(timestamp).getMinutes()}.${new Date(timestamp).getSeconds()}`)
      let color = '#8884d8'
      if (type === 'accelerometer') {
        newValue = Math.sqrt(
          Object.values(functionToObject(value))
            .map((item) => Math.pow(item, 2))
            .reduce((a, b) => a + b)
        )

        color = '#FF2F18'
      }

      if (type === 'temperature') color = '#4AC751'

      arr = {
        ...arr,
        [type]: {
          type: 'line',
          label: type?.[0]?.toUpperCase() + type?.slice(1),
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          name: type,
          fill: false,
          data: arr[type]?.data
            ? [
                ...arr[type].data,
                {
                  x: minSec,
                  y: newValue,
                },
              ]
            : [
                {
                  x: minSec,
                  y: newValue,
                },
              ],
        },
      }

    }
  })

  let newArr = {...arr}

  for(const a in newArr) {
    newArr = {...newArr, [a]: {...newArr[a], data: [...newArr[a].data.sort((a, b) => a.x - b.x)]}}
  }
  
  return newArr
}
