import { Box, Typography } from '@mui/material'
import I18n from 'i18n-js'
import React from 'react'
import DinamicForm from '../../../../components/dinamicForm'
import SimpleError from '../../../../components/errors/simple'
import CSV from '../csv'
import useStyles from '../style'

const Step1 = ({ inputs, handleChange, values, errors, length, current, locale }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.localeContainer}>
        <DinamicForm values={values} handleChange={handleChange} inputs={inputs.slice(0, 1)} />

        <SimpleError error={errors?.[locale]} message={errors?.[locale]} />
      </Box>

      <Typography className={classes.instructions}>{I18n.t('optionGroup.inputs.instruction')}</Typography>

      <CSV errors={errors} handleChange={handleChange} current={current} />

      <DinamicForm values={values} handleChange={handleChange} inputs={inputs.slice(1, 2)} />

      <SimpleError error={errors?.[current]} message={errors?.[current]} />

      <Box className={classes.containerMessage}>
        {length > 0 && (
          <Typography className={classes.message}>
            {length} {I18n.t('optionGroup.inputs.listItem')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Step1
