export const FR = {
  languages: {
    french: "Français",
    portuguese: "Portugais",
  },
  general: {
    step1: "Étape 1",
    step2: "Étape 2",
    step3: "Étape 3",
    step4: "Étape 4",
    cancelBtn: "Annuler",
    nextBtn: "Suivant",
    backBtn: "Retour",
    search: "Recherche",
    actions: "Actions",
    add: "Ajouter",
    edit: "Modifier",
    yes: "Oui",
    success: "Demande traitée",
    traductions: {
      missingTraductionTitle: "Champs à traduire !",
      missingTraductiontext:
        "Tous les champs ne sont pas traduits. Êtes-vous sûr de vouloir envoyer ces informations ?",
    },
  },
  navbar: {
    welcome: "Bienvenue",
    logout: "Se déconnecter",
  },
  drawer: {
    dashboard: "Tableau de bord",
    answers: "Réponses",
    view: "Voir",
    setting: "Réglage",
    users: {
      primary: "Les Sondeurs",
      update: "Mise à jour",
      create: "Créer",
      associate: "Association",
    },
    roles: "Rôles",
    question: "Type de questions",
    polls: "Sondages",
    group: "Grouper",
    groupOptions: "Groupe d'options",
    exclusionConditions: "Exclusion",
    questions: "Questions",
    questionnaries: "Questionnaires",
  },
  listAnswer: {
    title: "Listes de réponses",
    table: {
      name: "Nom",
      lastname: "Nom de famille",
      email: "E-mail",
      birthdate: "Date de naissance",
      braceletId: "Bracelet Id",
      questionnaries: "Questionnaire",
    },
    searchPlaceholder: "Saisir le texte de recherche",
    messages: {
      urlEmpty: "Aucun fichier à télécharger n'a été trouvé",
    },
  },
  listView: {
    title: "Résultats des participants",
    dowload: "Veille des données",
    survey: "Enquête",
    date: "Date",
    searchPlaceholder: "Entrez la date à rechercher",
    messages: {
      empty: "Não foram encontrados dados de relógio",
    },
    graphic: {
      view: "Voir le graphique",
      response: "Réponse envoyée",
      value: "Valeur",
      time: "Temps (s)",
      close: "Fermer",
    },
    watchData: {
      accelerometer: "Accéléromètre",
      temperature: "Température",
      eda: "Eda",
    },
  },
  breadcrumbs: {
    listAnswer: "Listes de réponses",
    listView: "Résultats de test",
    optionGroup: "Groupe d'options",
  },
  searchPlaceholder: "Saisir le texte de recherche",
  messages: {
    create: "Succès création d'un nouveau groupe",
    update: "Succès lors de la mise à jour du groupe",
    delete: "Suppression réussie du groupe",
    error: "Une erreur s'est produite dans la demande",
    empty: "Impossible de créer un groupe vide",
  },
  tableColumn: {
    name: "Nom",
    email: "E-Mail",
    totalOfVisits: "Total des visites",
    surveysApplied: "Enquêtes appliquées",
  },
  group: {
    title: "Groupe",
    create: "Ajouter un nouveau groupe",
    update: "Groupe de mise à jour",
    newGroup: "Nouveau groupe",
    add: "Ajouter",
    accept: "J'accepte",
    cancel: "Annuler",
    deleteTitle: "Êtes-vous sûr de vouloir supprimer le groupe?",
    delete: "Effacer",
    search: "Saisir le texte de recherche",
    inputUpdate: "Groupe de mise à jour",
    inputCreate: "Ecrire le groupe à ajouter",
  },
  users: {
    title: "Les Sondeurs",
    btn: "Ajouter un géomètre",
    input: "Saisir le texte de recherche",
    informationTitle: "Informations sur l'géomètre",
    inputName: "Nom",
    inputEmail: "Mail",
    inputLocal: "Langue",
    passwordTitle: "Création d'un mot de passe",
    newPassword: "Nouveau mot de passe",
    updatePassword: "Mise à jour du mot de passe",
    inputPassword: "Mot de passe",
    inputConfirm: "Confirmer",
    requiremens: {
      title: "Le mot de passe doit contenir",
      length: "- 8 caractères ou plus",
      letter: "- Au moins une lettre majuscule et une lettre minuscule",
      special:
        "- Au moins un chiffre et un caractère spécial sont autorisés @$!%*?&",
      weak: "Faible",
      strong: "Fort",
    },
    create: {
      title: "Ajouter un géomètre",
    },
    update: {
      title: "Mise à jour de l'géomètre",
    },
    delete: {
      accept: "Accepter",
      cancel: "Annuler",
      deleteTitle: "Êtes-vous sûr de vouloir supprimer l'géomètre ?",
    },
    locale: {
      French: "Français",
      portuguese: "Portugais",
    },
    finally: {
      associate: "Le groupe est associé avec succès à",
      return: "Retour à la liste des les sondeurs",
    },
    btnCancel: "Annuler",
    btnCreate: "Créer",
    btnUpdate: "Mise à jour",
    messages: {
      name: "Le nom d'géomètre est requis",
      validName: "Le nom ne peut contenir que des lettres et des chiffres",
      email: "format d'email invalide",
      emailExist: "Le courriel existe déjà",
      locale: "Une langue est nécessaire",
      password: "Le mot de passe n'est pas conforme à la politique de sécurité",
      confirm: "Les mots de passe ne sont pas identiques",
      existUser: "L'géomètre est déjà utilisé",
      required: "Champ requis",
      success: {
        create: "Géomètre créé avec succès",
        update: "Les informations ont été mises à jour avec succès",
        delete: "Suppression réussie des le sondeur",
      },
      error: {
        create: "Il y avait un bug dans la création de l'géomètre",
        update:
          "Il y a eu un pépin dans la mise à jour de l'institut de sondage.",
        delete:
          "Une erreur s'est produite lors de la suppression de l'géomètre.",
      },
    },
  },
  exclusionConditions: {
    columnLabel: {
      description: "Description",
      recommendations: "Recommandations",
    },
    header: {
      create: "Créer une nouvelle exclusion",
      update: "Modifier l'exclusion actuelle",
    },
    breadcrumbs: {
      title: "Exclusion",
      create: "Ajouter une exclusion",
      update: "Changement d'exclusion",
      associate: "Partenaire de l'enquête",
    },
    btn: {
      create: "Ajouter une exclusion",
    },
    form: {
      add: "Ajouter une exclusion",
      labelTextField: "Description de l'exclusion",
      exclusionInstruction: "Instruction d'exclusion",
      step1: "Créer l'exclusion",
      step1M: "Modification de l'exclusion",
      step2: "Traduire",
    },
    finally: {
      title: "Demande traitée",
      subtitle: "L'exclusion a été modifiée avec succès",
      return: "Retour à la liste des exclusions",
    },
    associate: {
      indicators: "Indicateurs",
      indicatorsTitle: "Lier les indicateurs",
      indicatorsSelection:
        "Sélectionnez les indicateurs que vous voulez ajouter à cette enquête.",
      deleteTitle: "Êtes-vous sûr de vouloir supprimer l'exclusion?",
      finally: "L'enquête a été associée au succès",
      createFinally: "L'exclusion a été créée avec succès",
      originalText: "Texte en",
    },
  },
  optionGroup: {
    columnLabel: {
      group: "Nom du groupe",
      options: "Options",
    },
    breadcrumbs: {
      title: "Liste des groupes",
      create: "Ajouter un groupe d'options",
      update: "Changement du groupe d'options",
      edit: "Modifier le groupe d'options",
      associate: "Partenaire de l'enquête",
    },
    steps: {
      stepOne: "Étape 1",
      stepTwo: "Étape 2",
      generalInformation: "Informations générales",
      translate: "Traduire",
      created: "Le groupe d'options a été créé",
      edited: "Le groupe d'options a été modifié",
      return: "Retour à la liste des groupes d'options",
    },
    modal: {
      deleteGroup: "Êtes-vous sûr de vouloir supprimer ce groupe d'options?",
      validation:
        "Le groupe d'options est en cours d'utilisation. Pour le supprimer, vous devez le dissocier des questions qui l'utilisent.",
    },
    inputs: {
      category: "Nom de la catégorie principale",
      subCategory: "Sous-catégorie",
      csv: "Trouver un CSV",
      translate: "Traduction",
      listItem: "Liste des sous-catégories",
      missingItem: "Traductions en cours",
      translateInstruction:
        "Vous pouvez taper chaque traduction sur la ligne correspondante, ou vous pouvez déposer un fichier CSV dans la boîte des traductions si vous préférez.",
      instruction:
        "Vous pouvez ajouter autant d'options que vous le souhaitez. Séparez chaque option par une virgule ou faites simplement glisser et déposer un fichier CSV ici.",
    },
    btn: {
      create: "Nouveau groupe",
    },
  },
  footer: {
    text: "Survey App - © Copyright tous droits réservés",
  },
  countries: {
    fr: "France",
    pt: "Portugais",
  },
  traductions: {
    missingTraductionTitle: "Champs à traduire !",
    missingTraductiontext:
      "Tous les champs ne sont pas traduits. Êtes-vous sûr de vouloir envoyer ces informations ?",
    cancel: "Annuler",
    yes: "Oui",
  },
  question: {
    stepper: {
      informationGenerales: "Générer des informations",
      questionTranslations: "Traduction des questions",
      answers: "Réponses",
      answersTranslations: "Réponses traductions",
    },
    breadcrumbs: {
      sample: "Goûter",
      newGroup: "Nouveau groupe",
    },
    title: {
      editedQuestion: "La question a été éditée avec succès",
      newQuestion: "Nouvelle question",
      editQuestion: "Question d'édition",
      sampleQuestionTitle: "Exemple de titre de question",
      min: "Minimum",
      max: "Maximum",
      createdQuestion: "La question a été créée avec succès",
      return: "Retour à la liste des questions",
    },
    form: {
      title: "Titre ",
      description: "Description",
      instructions: "Instructions",
      sampleQuestionTitle: "Titre de la question",
      sampleDescription: "Description de la question",
      sampleInstructions: "Instructions sur les questions",
      typeOfQuestion: "Type de question",
      value: "Valeur",
      sampleGroupName: "Nom du groupe d'échantillons",
      answer: "Réponse",
    },
    translatorAsks: {
      pleaseTranslateTheFollowingTexts:
        "Veuillez traduire les textes suivants ",
      textToTranslate: "Texte à traduire",
    },

    useAnExistingGroupOfAnswers: "Utiliser un groupe de réponses existant",
    pleaseSortTheAnswersThat:
      "Veuillez classer les réponses qui seront disponibles pour cette question en fonction de vos besoins. Veuillez indiquer la valeur de chaque réponse ci-dessous",
    yes: "Oui",

    list: {
      type: "Type de question",
      questionList: "Liste de questions",
    },

    scale: {
      lowestValueLabel: "Étiquette de la valeur la plus basse",
      hightestValueLabel: "Étiquette de la valeur la plus élevée",
    },
    modal: {
      changeTheAnswerOption:
        "Si vous modifiez l'option de réponse, les informations précédemment téléchargées sont supprimées.",
      doYouWishContinue: "Voulez-vous continuer ?",
      theQuestionIsBeingUsed:
        "La question est en cours d'utilisation. Pour le supprimer, vous devez le dissocier des enquêtes qui l'utilisent.",
      areYouSure: "Êtes-vous sûr de vouloir supprimer ce question?",
      updateQuestion: "Question de mise à jour",
    },
  },

  typeOfQuestion: {
    titles: {
      typeOfQuestion: "Type de question",
      listQuestionTypes: "Liste des types de questions",
      addQuestionType: "Ajouter un type de question",
      updateQuestionType: "Mettre à jour le type de question",
    },
    buttons: {
      newType: "Nouveau type de question",
    },

    listTypesQuestions: {
      name: "Nom",
      key: "Clé",
    },
    form: {
      enterTheKey: "Du type ",
      typeName: "Nom du type",
    },
    modal: {
      questionTypeIsInUse:
        "Le type de question est en cours d'utilisation. Pour le supprimer, il faut le dissocier des questions qui l'utilisent.",
      areYouSure: "Êtes-vous sûr de vouloir supprimer ce type de question?",
    },
    errors: {
      keyAlreadyUse: "La clé est déjà utilisée.",
      noResultsFound: "Aucun résultat",
    },
  },

  questionnaries: {
    questionnaries: "Questionnaires",
    lastQuestionNotHaveRedirects:
      "La dernière question ne peut pas avoir de redirections. ",
    thereFurtherQuestions: "Il n'y a pas d'autres questions",
    cyclesNotAllowedQuestions:
      "Les vélos ne sont pas autorisés dans les questions",
    cetteQuestionProvoqueCycle: "Cette question provoque un cycle",
    flow: "Débit",
    qstionnaireHasNoSections: "Ce questionnaire ne comporte aucune section",
    questions: "Questions",
    thisSectionHasQuestions: "Cette section n'a pas de questions",

    translations: {
      pleaseTranslateTexts: "Veuillez traduire les textes suivants",
      textTranslated: "Texte à traduire",
      translations: "Traductions de questionnaires",
      questionnaireFlow: "Flux des questionnaires",
      sampleQuestionnaireTitle: "Exemple de questionnaire Titre",
      title: "Titre",
      description: "Description",
      instructions: "Instructions",
    },

    modal: {
      questionTypeIsInUse: "Le questionnaire est en cours d'utilisation. ",
      areYouSure:
        "Êtes-vous sûr de vouloir supprimer ce type de questionnaires?",
      youStillNeedTranslations:
        "Vous devez encore ajouter des traductions. Voulez-vous continuer ?",
    },
    step: {
      generalInformation: "Informations générales",
      section: "Section",
      lookAndFeel: "Look and feel",
    },
    title: {
      action: "Action",
      createLogicThatRequire:
        "Veuillez créer la logique et le flux dont vous avez besoin",
      section: "Section",
      sectioN: "SECTION",
      questions: "Questions",
      listOfQuestionnaires: "Liste des questionnaires",
      sampleQuestionnaire: "Exemple de questionnaire Titre",
      availableQuestions: "Questions disponibles (Sélectionnez au moins une) *",
      generalColors: "Couleurs générales",
      textFormat: "Des couleurs contrastées",
      stockColors: "Couleurs du texte",
      backgroundSettings: "Paramètres d'arrière-plan",
      logo: "Logo",
      questionnaireSuccessfully: "Le questionnaire a été créé avec succès",
      questionnaireTitle: "Titre du questionnaire",
      dateUpdate: "Date de la mise à jour",
      editQuestionnaire: "Modifier le questionnaire",
      beenSuccessfullyEdited: "Le questionnaire a été édité avec succès",
    },
    button: {
      newQuestionnaire: "Nouveau questionnaire",
      addSection: "AJOUTER UNE SECTION",
      backListQuestionnaires: "Retour à la liste des questionnaires",
    },
    form: {
      title: "Titre",
      description: "Description",
      instructions: "Instructions",
      questionnaireTitle: "Titre du questionnaire",
      descriptionQuestionnaire: "Description du questionnaire",
      questionnaireInstructions: "Instructions pour le questionnaire",
      useMainColorSolidBackground:
        "Utiliser les couleurs principales comme fond uni",
      createGradientMainSecondary:
        "Créer un gradient entre la couleur principale et la couleur secondaire",
      useBackgroundImage: "utiliser une image de fond ( Recommandé 1024x1024)",
      mainBackgroundImage: "Image de fond principale",
      logoImage: "Image du logo",
      mainColor: "Couleur principale",
      secondaryColor: "Couleur secondaire",
      errorColor: "Couleur d'erreur",
      colorOptions: "Options de couleurs ",
      contrastImage: "Image de contraste",
      backgroundImage: "Image de fond",
      nextSection: "Section suivante",
      none: "Pas d'action",
    },

    errors: {
      noQuestionsAvailable: "Aucune question disponible",
      insertDecimalNumber: "Insérer un nombre décimal",
    },
  },
};
