import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import I18n from "i18n-js";
import { useState } from "react";
import { icons } from "../../utils/icons";
import languages from "../../utils/languages";
import { LayoutButton } from "../btnBN/layoutButton";
import { WrapperLanguages } from "../inputWrappers/wrapperLanguages";
import { styles } from "./styles";
import { LayoutButtonLink } from "../btnBN/layoutButtonLink";
import { colors } from "../../utils";

const TranslationContainer = (props) => {
  const {
    mainArray = null,
    saveData,
    next = null,
    back,
    index = null,
    elevation,
    showData = null,
    id = null,
    returnFalse = false,
  } = props;

  const keysObj = mainArray && Object.keys(mainArray);
  const [pages, setPages] = useState(0);
  const userLocale = localStorage.getItem("lang");
  const localesList = languages?.filter((item) => item?.locale !== userLocale);

  const addPages = () => {
    if (pages < Object.values(mainArray).length - 1) setPages(pages + 1);
  };
  const subtractPages = () => {
    if (pages > 0) setPages(pages - 1);
  };

  return (
    <Paper
      className={"containerPaper"}
      elevation={elevation}
      sx={styles.containerBody}
    >
      <Box>
        <Box sx={styles.titleControl}>
          <Box>
            {index && (
              <Typography color={colors.blueLighten} sx={styles.textSection}>
                {I18n.t("questionnaries.title.section") + " " + index}
              </Typography>
            )}
            <Typography color="GrayText">
              {I18n.t("questionnaries.translations.pleaseTranslateTexts")}
            </Typography>
          </Box>

          <Box sx={styles.countingContainer}>
            <Box color="GrayText" sx={styles.counter}>
              <IconButton onClick={subtractPages}>{icons("back")}</IconButton>
              {pages + 1}/{mainArray && Object.values(mainArray)?.length}
              <IconButton onClick={addPages}>{icons("next")}</IconButton>
            </Box>

            <Typography fontSize="11px" color="GrayText">
              {I18n.t("questionnaries.translations.textTranslated")}
            </Typography>
          </Box>
        </Box>

        {Array.isArray(mainArray) ? (
          <TextField
            value={mainArray[pages] ?? ""}
            multiline
            fullWidth
            inputProps={{ readOnly: true }}
          />
        ) : (
          <TextField
            value={
              (mainArray &&
                keysObj &&
                mainArray[keysObj[pages]]?.find(
                  (item) => item.locale === userLocale
                )?.value) ??
              undefined
            }
            multiline
            label={
              keysObj && I18n.t(`questionnaries.translations.${keysObj[pages]}`)
            }
            //label={keysObj && keysObj[pages]}
            fullWidth
            disabled
            inputProps={{ readOnly: true }}
          />
        )}
      </Box>
      <Box>
        {localesList.map((item, index) => {
          return (
            <WrapperLanguages
              id={id}
              key={index}
              pages={pages}
              name={keysObj && keysObj[pages]}
              saveData={saveData}
              keysObj={keysObj}
              language={item}
              showData={showData}
            />
          );
        })}
      </Box>
      {!returnFalse && (
        <Box sx={styles.containerButton}>
          <LayoutButtonLink
            label={I18n.t("general.cancelBtn")}
            link={"/dashboard/questionnaires"}
          />
          <Box className="internalButtons">
            <LayoutButton
              click={back}
              variant="outlined"
              label={I18n.t("general.backBtn")}
            />

            <LayoutButton
              click={next}
              variant="contained"
              label={I18n.t("general.nextBtn")}
            />
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default TranslationContainer;
