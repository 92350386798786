import { Box, Breadcrumbs, IconButton } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { exclusion } from '../../service/exclusion'
import useStyles from './styles'
import I18n from 'i18n-js'
import { Link } from 'react-router-dom'
import LinkStyles from '../../themes/js/Link'
import TableVerify from '../../components/tables/tableLink'
import { icons } from '../../utils/icons'
import Loading from '../../components/loading'

const Condition = () => {
  const classes = useStyles()

  const data = {
    title: I18n.t('exclusionConditions.breadcrumbs.title'),
    search: I18n.t('users.input'),
    searchBtn: I18n.t('exclusionConditions.btn.create'),
    to: 'exclusion/create',
    to2: 'exclusion/edit',
    to3: 'exclusion/associate',
    titleM: I18n.t('exclusionConditions.associate.deleteTitle'),
    btnCancel: I18n.t('users.delete.cancel'),
    btnAccept: I18n.t('users.delete.accept'),
  }

  const [list, setList] = useState([])
  const [flat, setFlat] = useState(false)
  const [isLoadid, setIsLoading] = useState(true)
  
  const columns = [
    {
      id: 'description',
      label: I18n.t('exclusionConditions.columnLabel.description'),
      minWidth: 170,
    },
    {
      id: 'recommendations',
      label: I18n.t('exclusionConditions.columnLabel.recommendations'),
      minWidth: 170,
    },
  ]
  const getData = useCallback(async () => {
    const res = await exclusion.list()

    if (res.data !== null) {
      setList(
        res.data.rejection_reasons.map((item) => ({
          ...item,
          actions: (
            <IconButton className={classes.iconOfTheList}>
              <Link to={`${data.to3}/${item.id}`}>{icons('list')}</Link>
            </IconButton>
          ),
        }))
      )
    }
    setIsLoading(false)
  }, [classes.iconOfTheList, data.to3])

  const remove = async (obj) => {
    const res = await exclusion.deleteReason(obj.id)

    if(res.data !== null) {
      setList(list.filter((item) => item.id !== obj.id))
    }
  }

  useEffect(() => {
    if (!flat) {
      getData()
      setFlat(true)
    }
  }, [flat, getData])

  if(isLoadid) {
    return <Loading />
  }

  return (
    <Box className={classes.container}>
      <Breadcrumbs className={classes.link} aria-label="breadcrumb">
        <Link style={LinkStyles} to="/dashboard">
          {I18n.t('drawer.dashboard')}
        </Link>

        <Link style={LinkStyles} to={`/dashboard/exclusion`}>
          {I18n.t('exclusionConditions.breadcrumbs.title')}
        </Link>
      </Breadcrumbs>

      <TableVerify columns={columns} rows={list} data={data} remove={remove} parameter="description" actions={true} />
    </Box>
  )
}

export default Condition
