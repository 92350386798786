import { Box, TextField } from "@mui/material";
import I18n from "i18n-js";
import { useStyles } from "../styles";
import SimpleError from "../../../components/errorMesage/simple";

export const SaveKey = (props) => {
  const { data, setData, keyExists } = props;
  const styles = useStyles();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };

  return (
    <Box className={styles.formPartKey}>
      <TextField
        required
        fullWidth
        label={I18n.t("typeOfQuestion.listTypesQuestions.key")}
        name="key"
        inputProps={{ maxLength: 255 }}
        value={data.key ?? ""}
        onChange={(e) => handleChange(e)}
        onInput={(e) => {
          validateInput(e);
        }}
        placeholder={I18n.t("typeOfQuestion.form.enterTheKey")}
        error={keyExists}
      />
      <SimpleError
        error={keyExists}
        message={I18n.t("typeOfQuestion.errors.keyAlreadyUse")}
      />
    </Box>
  );
};
