import { Grid, Typography } from '@mui/material'
import ListViewStyle from '../../pages/answers/themes/ListView'
import I18n from 'i18n-js'

const Track = ({ info }) => {
    const classes = ListViewStyle()

    return (
        <Grid container>
            <Grid item xs={12} className={classes.trackTimestamp}>
                <Grid item md={7} xs={12}>
                    <Grid container justifyContent="center">
                        <Typography className={classes.trackTimestampText} variant="caption">
                            {I18n.t('listView.watchData.accelerometer')}
                        </Typography>
                    </Grid>

                    {info.timestamp.accelerometer !== undefined ? (
                        <Grid container justifyContent="center">
                            <Typography className={classes.trackTimestampText} variant="caption" mr={1}>
                                x: {info.timestamp.accelerometer.value.x}
                            </Typography>

                            <Typography className={classes.trackTimestampText} variant="caption" mr={1}>
                                y: {info.timestamp.accelerometer.value.y}
                            </Typography>

                            <Typography className={classes.trackTimestampText} variant="caption">
                                z: {info.timestamp.accelerometer.value.z}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container justifyContent="center">
                            <Typography className={classes.trackTimestampText} variant="caption">
                                -
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                    <Grid container justifyContent="center">
                        <Typography className={classes.trackTimestampText} variant="caption">
                        {I18n.t('listView.watchData.temperature')}
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="center">
                        {info.timestamp.temperature !== undefined ? (
                            <Typography className={classes.trackTimestampText} variant="caption">
                                {info.timestamp.temperature.value}
                            </Typography>
                        ) : (
                            <Typography className={classes.trackTimestampText} variant="caption">
                                -
                            </Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid item md={3} sm={2} xs={12}>
                    <Grid container justifyContent="center">
                        <Typography className={classes.trackTimestampText} variant="caption">
                            {I18n.t('listView.watchData.eda')}
                        </Typography>
                    </Grid>

                    <Grid container justifyContent="center">
                        {info.timestamp.eda !== undefined ? (
                            <Typography className={classes.trackTimestampText} variant="caption">
                                {info.timestamp.eda.value}
                            </Typography>
                        ) : (
                            <Typography className={classes.trackTimestampText} variant="caption">
                                -
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Track
