import { Box, Typography } from '@mui/material'

const NotFound = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Typography variant="h1" color="initial">ERROR 404</Typography>
        </Box>
    )
}

export default NotFound
