import { Box, Button } from '@mui/material'
import I18n from 'i18n-js'
import React from 'react'
import { Link } from 'react-router-dom'
import useStyles from './styles'

const BtnBN = ({ back = '/', handleStep, step = 0, disabled = false }) => {
  const classes = useStyles()

  return (
    <Box className={classes.flex} mb={3}>
      <Button component={Link} to={back} className={classes.back}>
        {I18n.t('general.cancelBtn')}
      </Button>

      <Box className={classes.flexGrow} />

      {step > 0 && (
        <Button variant="outlined" className={classes.back} onClick={() => handleStep(-1)}>
          {I18n.t('general.backBtn')}
        </Button>
      )}

      <Button variant="contained" className={classes.next} onClick={() => handleStep(1)} disabled={disabled}>
        {I18n.t('general.nextBtn')}
      </Button>
    </Box>
  )
}

export default BtnBN
