import { makeStyles } from "@mui/styles";
import { colors } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    left: "90%",
    top: "15px",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    padding: "30px",
    flexDirection: "column",
    gap: "19px",
    alignItems: "flex-end",
    borderRadius: "35px",
  },
  textModal: {
    width: "100%",
    color: colors.palette.greyDark,
    textAlign: "flex-start",
  },
  buttonModal: {
    gap: "12px",
    display: "flex",
  },
}));
