import { StepConnector, stepConnectorClasses } from "@mui/material";
import { styled } from "@mui/styles";
import colors from "../../themes/colors/colors";

const QontoConnector = styled(StepConnector)(({ width }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    //left: `-${width}px`,
    left: "-90%",
    right: 0,
    //   width: `${width}px`,
    top: 13,
    position: "absolute",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.blueLighten,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.blueLighten,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: colors.greyFull,
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

export default QontoConnector;
