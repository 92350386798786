const steps = {

    one: "0",
    two: "1",
    three: "2",
    four: "3",
    five: "4",

}

export default steps