import { GET, POST, PUT, DELETE } from "../utils/crud";

const list = async () => {
  const res = await GET("/surveys");

  return res;
};

const createQuestionnaire = async (body) => {
  const res = await POST("/surveys", body);

  return res;
};

const detailQuestionnaire = async (id) => {
  const res = await GET(`/surveys/${id}`);

  return res;
};

const editQuestionnaire = async (id, body) => {
  const res = await PUT(`/surveys/${id}`, body);

  return res;
};

const deleteQuestionnaire = async (id) => {
  const res = await DELETE(`/surveys/${id}`);

  return res;
};

const createStyles = async (id, body) => {
  const res = await POST(`/update/survey/styles/${id}`, body, true);

  return res;
};

const validateQuestionnaire = async (id) => {
  const res = await GET(`/validate/survey/used?survey_id=${id}`);

  return res;
};

const questionnaireTranslations = async (id, body) => {
  const res = await POST(`/update/survey_sections/${id}`, body);
  return res;
};

const saveFlow = async (id, body) => {
  const res = await POST(`/surveys/logic_steps/${id}`, body);

  return res;
};

export const questionnaires = {
  list,
  createQuestionnaire,
  detailQuestionnaire,
  editQuestionnaire,
  deleteQuestionnaire,
  validateQuestionnaire,
  createStyles,
  questionnaireTranslations,
  saveFlow,
};
