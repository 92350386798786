import { Box, IconButton, Paper, Typography } from "@mui/material";
import { useContext } from "react";
import I18n from "i18n-js";
import useSaveSurveys from "../../../../hooks/useSaveSurveys";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";
import { LayoutButtonLink } from "../../../../components/btnBN/layoutButtonLink";
import { DragDropItems } from "../../../../components/sectionCards/dragDropItems";
import QuestionnairesContext from "../../../../context/questionnairesContext";
import { useMoveArrayElem } from "../../../../hooks/useMoveArrayElem";
import { icons } from "../../../../utils";
import useStyles from "../styles";
import { styles } from "../styles";

const Sections = (props) => {
  const {
    step,
    setStep,
    data,
    setData,
    setFilteresQuestions,
    filteredQuestions,
  } = useContext(QuestionnairesContext);
  const { setView } = props;
  const classes = useStyles();
  const { returnSections } = useSaveSurveys();
  const { pushUp, pushDown } = useMoveArrayElem();

  return (
    <Box marginTop={5}>
      <Box>
        {data.sections.map((item, key) => (
          <Paper
            key={key}
            elevation={5}
            className={classes.sectionBody}
            sx={{ borderRadius: "20px" }}
          >
            <Box sx={styles.moveSection}>
              <Box sx={styles.containerArrows}>
                <IconButton
                  disabled={key < 1}
                  onClick={() => pushUp(item.key, data.sections)}
                  sx={styles.arrowsUp}
                >
                  {icons("up")}
                </IconButton>

                <Typography sx={styles.arrowsText}>{key + 1}</Typography>
                <IconButton
                  disabled={key > data.sections.length - 2}
                  onClick={() => pushDown(item.key, data.sections)}
                  sx={styles.arrowsDown}
                >
                  {icons("down")}
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.secCardTitles}>
              <Typography
                sx={styles.mainTitleCard}
                fontSize={14}
                fontWeight={500}
              >
                {I18n.t("questionnaries.title.section") + " " + (key + 1)}
              </Typography>
              <Typography
                sx={styles.mainTitleCard}
                fontSize={16}
                fontWeight={600}
              >
                {returnSections("title", item)}
              </Typography>
            </Box>

            <DragDropItems
              item={item.questions}
              setData={setData}
              data={data}
              sectionsKey={item.key}
              filteredQuestions={filteredQuestions}
              setFilteredQuestions={setFilteresQuestions}
            />
          </Paper>
        ))}
      </Box>
      <Box className={classes.buttons}>
        <LayoutButtonLink
          label={I18n.t("general.cancelBtn")}
          link={"/dashboard/questionnaires"}
        />
        <Box className={classes.nextBack}>
          <LayoutButton
            variant="outlined"
            label={I18n.t("general.backBtn")}
            click={() => setView(false)}
          />
          <LayoutButton
            variant="contained"
            label={I18n.t("general.nextBtn")}
            click={() => setStep(step + 1)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Sections;
