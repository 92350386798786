import { Box, Modal, TableContainer, Table, TableRow, TableHead, TableBody, TableCell, TablePagination, IconButton, Button, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import tableStyles from './themes/table'
import CreateIcon from '@mui/icons-material/Create'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import SearchIcon from '@mui/icons-material/Search'
import colors from '../../themes/colors/colors'

const TableSimple = ({ columns = [], rows = [], crud, data, parameter = 'name' }) => {
  const classes = tableStyles()

  const [text, setText] = useState('')

  const [create, setCreate] = useState({ name: '' })

  const [trash, setTrash] = useState({})

  const [open, setOpen] = useState(false)

  const [openDelete, setOpenDelete] = useState(false)

  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const newRow = rows?.filter((row) => row?.[parameter]?.toLowerCase().includes(text?.toLowerCase())) || rows

  const [modalTitle, setModalTitle] = useState('')

  const [inputM, setInputM] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const openModal = (value) => {
    if (value.method === 'update') {
      setModalTitle(data.updateM.title)
      setInputM(data.updateM.input)
      setCreate(value)
    }

    if (value.method === 'create') {
      setModalTitle(data.createM.title)
      setInputM(data.createM.input)
      setCreate({ name: '', ...value })
    }

    setOpen(true)
  }

  const sendCrud = () => {
    crud(create)

    setOpen(false)
  }

  const sendDelete = (row) => {
    crud({ ...row, method: 'delete' })

    setOpenDelete(false)
  }

  return (
    <Box className={classes.container}>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box className={classes.CU}>
          <Typography variant="subtitle1" className={classes.CUText}>
            {modalTitle}
          </Typography>

          <TextField placeholder={inputM} className={classes.CUInput} value={create.name} onChange={(e) => setCreate({ ...create, name: e.target.value })} />

          <Box className={classes.CUContainerButtons}>
            <Box sx={{ flexGrow: 1 }}></Box>

            <Button className={`${classes.CUButtons} ${classes.CUButtonsCancel}`} variant="outlined" onClick={() => setOpen(false)}>
              {data.btnM1}
            </Button>

            <Button className={`${classes.CUButtons} ${classes.CUButtonsAccept}`} variant="contained" onClick={sendCrud}>
              {data.btnM2}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openDelete} onClose={() => setOpenDelete(false)} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box className={classes.delete}>
          <Typography variant="subtitle1" className={classes.CUText}>
            {data.thirdM.title}
          </Typography>

          <Box className={classes.CUContainerButtons}>
            <Box sx={{ flexGrow: 1 }}></Box>

            <Button className={`${classes.CUButtons} ${classes.CUButtonsCancel}`} variant="outlined" onClick={() => setOpenDelete(false)}>
              {data.thirdM.btn1}
            </Button>

            <Button className={`${classes.CUButtons} ${classes.CUButtonsAccept}`} variant="contained" onClick={() => sendDelete(trash)}>
              {data.thirdM.btn2}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box className={classes.boxSearchCreate}>
        <Typography variant="h5" sx={{ flexGrow: 1, paddingTop: 1 }} mr={2}>
          {data.title}
        </Typography>

        <Box>
          <TextField className={classes.input} size="small" label={data.search} value={text} onChange={(e) => setText(e.target.value)} InputProps={{ endAdornment: <SearchIcon className={classes.searchIcon} /> }} />

          <Button variant="contained" className={classes.button} onClick={() => openModal({ method: 'create' })}>
            {data.searchBtn}
          </Button>
        </Box>
      </Box>

      {/* ============================= Table ======================== */}
      <Box>
        <TableContainer>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead style={{ height: 40 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} className={classes.cell}>
                    <Typography>{column.label}</Typography>
                  </TableCell>
                ))}

                <TableCell sx={{ width: 100 }}>
                  <Typography variant="body1" align="center">
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {newRow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className={classes.color}>
                    {columns.map((column) => (
                      <TableCell key={column.id} className={classes.cell}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                    <TableCell sx={{ display: 'flex', height: 40, justifyContent: 'center' }}>
                      <IconButton onClick={() => openModal({ ...row, method: 'update' })} sx={{ color: colors.palette.general }}>
                        <CreateIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setTrash(row)
                          setOpenDelete(true)
                        }}
                      >
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} className={classes.color} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Box>
    </Box>
  )
}

TableSimple.defaultProps = {
  data: {
    title: '',
    search: '',
    searchBtn: '',
    btnM1: '',
    btnM2: '',
    createM: {
      title: '',
      input: '',
    },
    updateM: {
      title: '',
      input: '',
    },
    thirdM: {
      title: '',
      btn1: '',
      btn2: '',
    },
  },
}

export default TableSimple
