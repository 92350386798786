import I18n from 'i18n-js'
import { AppBar, Typography } from "@mui/material"


const Footer = () => {
    return (
        <AppBar align="center" position="relative" color="inherit" sx={{ height: 60, paddingX: 2, borderTop: '1px solid #aaa', paddingTop:2.5 }}>
          <Typography variant="body1" >{I18n.t('footer.text')}</Typography>
      </AppBar>
    )
}

export default Footer
