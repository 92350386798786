import { validEmail } from '../service/CRUD'

export const isEmpty = (data) => {
  if (data && data !== null) {
    if (data?.isArray) return data.length > 0

    if (typeof data === 'object') return Object.keys(data).length > 0

    return data !== ''
  }

  return false
}

export const isValidPassword = (password) => {
  let count = 0

  if (password.length > 0) {
    count++
  }

  if (/[a-z]{1,30}/g.test(password)) {
    count++
  }

  if (/[A-Z]{1,30}/g.test(password)) {
    count++
  }

  if (/[0-9]{1,30}/g.test(password)) {
    count++
  }

  if (/@|\$|!|%|\*|\?|&/g.test(password)) {
    count++
  }

  if (/[a-z-0-9-@\\$!%\\*\\?&]{8,30}/i.test(password)) {
    count++
  }

  return count
}

export const isEmail = (data) => {
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
  )

  return pattern.test(data)
}

export const validedEmail = async (email) => {
  if (email && isEmail(email)) {
    const valid = await validEmail('users/check-email', email)
    if (valid.data) return true
  }
  return false
}

export const isName = (data) => {
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    '^[a-zA-Z0-9_-]{1,255}$'
  )
  let string = data.replace(/\s+/g, '')
  if (pattern.test(string)) {
    return false
  } else {
    return true
  }
}
