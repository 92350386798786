import { useContext, useState } from "react";
import QuestionnairesContext from "../context/questionnairesContext";

export const useStylesSurveys = () => {
  const { dataStyles, setDataStyles } = useContext(QuestionnairesContext);
  const [logo, setLogo] = useState();
  const [background, setBackground] = useState();
  const [contrastBackground, setContrastBackground] = useState();
  const encodeFileAsBase64URL = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => {
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  };

  const saveColors = (e) => {
    const { name, value } = e.target;
    setDataStyles({
      ...dataStyles,
      [name]: value,
    });
  };
  const saveLogo = async (e) => {
    if (e) {
      setLogo(e);
      setDataStyles({
        ...(dataStyles ?? []),
        logo_url: e ?? "",
      });
    }
  };

  const saveContrastBackground = async (e) => {
    if (e) {
      setContrastBackground(e);

      setDataStyles({
        ...dataStyles,
        contrast_background_url: e ?? "",
      });
    }
  };

  const saveBackground = async (e) => {
    if (e) {
      setBackground(e);

      setDataStyles({
        ...dataStyles,
        background_url: e ?? "",
      });
    }
  };

  return {
    saveColors,
    saveLogo,
    saveBackground,
    logo,
    background,
    encodeFileAsBase64URL,
    contrastBackground,
    saveContrastBackground,
  };
};
