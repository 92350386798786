import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { colors } from "../../utils";

export const LayoutButtonLink = (props) => {
  const { label = null, link = null } = props;
  const classes = useStyles();
  return (
    <Button>
      <Link className={classes.link} to={link}>
        {label}
      </Link>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: colors.blueLighten,
  },
}));
