import { GET, POST, PUT,DELETE } from "../utils/crud"

const list = async () => {
    const res = await GET('/rejection-reasons')

    return res
}

const listSurveys = async () => {
    const res = await GET('/surveys')

    return res
}

const detail = async (id) => {
    const res = await GET(`/rejection-reasons/${id}`)

    return res
}

const detailSurveys = async (id) => {
    const res = await GET(`/rejection-reason-surveys/${id}`)

    return res
}

const createExclusion = async (body) => {
    const res = await POST('/rejection-reasons/batch', body)

    return res
}

const updateExclusion = async (body, id) => {
    const res = await PUT(`/rejection-reasons/${id}`, body)

    return res
}

const deleteReason = async (id) => {
    const res = await DELETE(`/rejection-reasons/${id}`)

    return res
}


const associateReason = async (body) => {
    const res = await POST(`/rejection-reason-surveys`, body)

    return res
}
export const exclusion = {
    list,
    listSurveys,
    createExclusion,
    updateExclusion,
    detail,
    detailSurveys,
    deleteReason,
    associateReason
}