import { makeStyles } from "@mui/styles";
import { colors } from "../../utils/index";

const useStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    gap: "5%",
  },

  paperAnswerCheck: {
    display: "flex",
    placeItems: "center",
    overflow: "hidden",
    padding: "7px",
    width: "75%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
  },

  paperAnswer: {
    display: "flex",
    overflow: "hidden",
    placeItems: "center",
    padding: "16px",
    width: "65%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  x: {
    "&.MuiIconButton-root": {
      color: colors.palette.error,
    },
  },
  create: {
    display: "flex",
    gap: "12px",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
    marginTop: "25px",
  },
}));

export const styles = {
  textArea: {
    width: "90%",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
};

export default useStyles;
