import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const tableUsers = makeStyles({
  container: {
    minHeight: window.innerHeight - 135,
    marginTop: 45,
    margin: 'auto',
    width: '95%',
  },
  link: {
    paddingLeft: '7.5%',
  },
  iconOfTheList: {
    '& .MuiSvgIcon-root': {
      color: colors.palette.primary,
    },
  },
  input: {
    '&.MuiTextField-root': {
      width: '100%',
    },
  },
  containerInput: {
    width: '100%',
  },
  modelHeader: {
    borderBottom: `1px solid ${colors.palette.greyPlaceholder}`,
    width: 'auto',
    padding: '0 30px 16px 30px',
    position: 'relative',
  },
  iconModal: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: 30,
      top: 5,
      padding: 0,
    },
  },
  modelBody: {
    padding: '40px 30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '18px',
  },
  modelFooter: {
    borderTop: `1px solid ${colors.palette.greyPlaceholder}`,
    width: 'auto',
    padding: '0 30px',
    position: 'relative',
    minHeight: '50px',
  },
  buttonFooter: {
    position: 'absolute',
    right: '30px',
    bottom: '8px',
  },
  buttonAction: {
    '&.MuiButton-root': {
      background: colors.palette.primary,
      fontSize: '.7em',
      marginLeft: '10px',
      '&:hover': {
        background: colors.palette.secondary,
      },
    },
  },
  buttonText: {
    '&.MuiButton-root': {
      color: colors.palette.primary,
      borderColor: colors.palette.primary,
      '&:hover': {
        borderColor: colors.palette.secondary,
        color: colors.palette.secondary,
      },
      fontSize: '.75em',
    },
  },
})

export default tableUsers
