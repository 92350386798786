import { Button } from "@mui/material";

export const LayoutButton = (props) => {
  const {
    variant = "text",
    width = "120px",
    click,
    label,
    color = null,
    borderColor = null,
    background = null,
    hoverBorderColor = null,
    hoverColor = null,
    backgroundColorHover = null,
    disabled = false,
  } = props;

  return (
    <Button
      disabled={disabled}
      variant={variant}
      width={width}
      onClick={click}
      sx={{
        color: color,
        borderColor: borderColor,
        background: background,
        "&:hover": {
          color: hoverColor,
          borderColor: hoverBorderColor,
          backgroundColor: backgroundColorHover,
        },
      }}
    >
      {label}
    </Button>
  );
};
