const colors = {
  palette: {
    primary: "#03a17f",
    secondary: "#239192",
    white: "#ffffff",
    black: "#000",
    general: "#17a2b8",
    error: "#d32f2f",
    grey: "#f5f5f5",
    greyLighten: "#fafafa",
    greyDark: "#666666",
    greyPlaceholder: "#C4C4C4",
    greyMedium: "#9e9e9e",
    greenLighten: "#26C36D",
    alert: "#C3263B",
    blue: "#1A8FFF",
  },
  //#1565c0
  buttons: {
    primary: "#03a17f",
    secondary: "#239192",
    outline: "#03a17f",
  },
  scroll: {
    primary: "#15B087",
    secondary: "#239192",
  },
  modal: {
    primary: "#f6f6f6",
  },
  typography: {
    primary: "#fff",
    secondary: "#444",
  },
  icons: {
    primary: "#fff",
    secondary: "#929292",
  },
  footer: {
    lineTop: "#D2D6CE",
  },
  blueLighten: "#1890ff",
  warning: "#FFCD00",
  disabled: "#666666",
};

export default colors;
