import { Box, Button, Modal, Typography } from "@mui/material";
import { useStyles } from "./styles";

export const VersionModal = (props) => {
  const {
    title = null,
    openModal = null,
    setOpenModal = null,
    labelCancel = null,
    labelAccept = null,
    eventClick = () => {},
  } = props;
  const styles = useStyles();

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className={styles.delete}>
        <Typography variant="subtitle1" className={styles.CUText}>
          {title}
        </Typography>

        <Box className={styles.CUContainerButtons}>
          <Box sx={{ flexGrow: 1 }}></Box>

          <Button
            className={`${styles.CUButtons} ${styles.CUButtonsCancel}`}
            variant="outlined"
            onClick={() => setOpenModal(false)}
          >
            {labelCancel}
          </Button>

          <Button
            className={`${styles.CUButtons} ${styles.CUButtonsAccept}`}
            variant="contained"
            onClick={() => eventClick()}
          >
            {labelAccept}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
