import { Box, Button, Icon, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { icons } from '../../../../utils'
import useStyles from './styles'

const Finally = ({title, subtitle, btn, to}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Icon className={classes.icon}>{icons('check')}</Icon>

      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>

      <Button 
        className={classes.btn} 
        variant="contained"
      >
        <Link className={classes.link} to={to}>{btn}</Link>
      </Button>
    </Box>
  )
}

export default Finally
