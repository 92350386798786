import { makeStyles } from "@mui/styles";

export const styles = {
  layoutBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
  },
};

export const useStyles = makeStyles((theme) => ({
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(1231)]: {
      width: "735px",
    },
    [theme.breakpoints.down(900)]: {
      width: "600px",
    },
    width: "950px",
    marginTop: "28px",
  },
  sectionUp: {
    "& .containerPaper": {
      borderRadius: "16px",
      [theme.breakpoints.down(1231)]: {
        width: "665px",
      },
      [theme.breakpoints.down(900)]: {
        width: "530px",
      },
      width: "885px",
    },
  },
  linkSelect: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    marginTop: 28,
    width: "80%",
    [theme.breakpoints.down(1160)]: {
      alignSelf: "flex-start",
      marginLeft: "62px",
    },
  },
  body: {
    width: "60%",
    display: "flex",
    marginTop: "60px",
    flexDirection: "column",
    alignItems: "center",
    "& .containerPaper": {
      borderRadius: "18px",
    },
  },

  carousel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "69px",

    "& .containerPaper": {
      borderRadius: "  inherit",
      boxShadow: "none",
      //  width: "100%",
      //  border: "solid red 1px",
    },
    "& .carousel-root": {
      display: "grid",
      justifyItems: "center",

      "& .carousel": {
        overflow: "inherit",

        "& .control-arrow": {
          "&:hover": {
            background: "none",
          },
        },
        "& .control-prev.control-arrow": {
          "&:before": {
            borderRight: "8px solid black",
          },

          margin: "0 0px 0 -40px",
        },
        "& .control-next.control-arrow": {
          margin: "0 -40px 0 0px",

          "&:before": {
            borderLeft: "8px solid black",
          },
        },
        "& .slider-wrapper": {
          boxShadow: "0px 0px 5px 0px rgba(156,156,156,1)",
          height: "100%",
          borderRadius: "16px",
          [theme.breakpoints.down(1231)]: {
            width: "730px",
          },
          [theme.breakpoints.down(900)]: {
            width: "600px",
          },
          width: "949px",
          //  width: "73%",
        },
        "& .slider-wrapper.axis-horizontal": {
          "& .slider": {
            "& .slide.selected": {
              margin: "0 0px",
              height: "100%",
            },
          },
        },
      },
    },
  },
}));
