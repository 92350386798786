import TimelineIcon from "@mui/icons-material/Timeline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FolderIcon from "@mui/icons-material/Folder";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  CheckCircle,
  Search,
  HighlightOffSharp,
  VisibilityOff,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { image } from "./images";
import { ListAlt, Edit, Clear } from "@mui/icons-material";

export const icons = (icon, input = false) => {
  switch (icon) {
    case "exclusion":
      return <DoNotDisturbAltIcon color="disabled" />;
    case "search":
      return <Search color="disabled" />;
    case "edit":
      return <Edit />;
    case "close":
      return <HighlightOffSharp />;
    case "clear":
      return <Clear />;
    case "list":
      return <ListAlt />;
    case "check":
      return <CheckCircle />;
    case "deleteOutline":
      return <DeleteOutlineIcon />;
    case "delete":
      return <DeleteIcon />;
    case "book":
      return <LibraryBooksIcon />;
    case "visibility":
      return <VisibilityIcon />;
    case "visibilityOff":
      return <VisibilityOff />;
    case "graphic":
      return <TimelineIcon />;
    case "back":
      return <ArrowBackIosIcon />;
    case "next":
      return <ArrowForwardIosIcon />;
    case "plus":
      return <AddCircleOutlineIcon />;
    case "x":
      return <HighlightOffIcon />;
    case "closeIcon":
      return <CloseIcon />;
    case "move":
      return <ImportExportIcon />;
    case "up":
      return <ArrowDropUpIcon />;
    case "down":
      return <ArrowDropDownIcon />;
    case "paint":
      return <FormatColorFillIcon />;
    case "folder":
      return <FolderIcon />;
    case "language":
      return <LanguageIcon />;
    case "settings":
      return <SettingsIcon />;

    case "fr":
      return (
        <Avatar
          alt="french"
          src={image.france}
          style={
            input
              ? { width: "20px", height: "20px" }
              : { width: "28px", height: "28px" }
          }
        />
      );
    case "pt":
      return (
        <Avatar
          alt="portuguese"
          src={image.portugal}
          style={
            input
              ? { width: "20px", height: "20px" }
              : { width: "28px", height: "28px" }
          }
        />
      );

    case "es":
      return (
        <Avatar
          alt="spanish"
          src={image.spanish}
          style={
            input
              ? { width: "20px", height: "20px" }
              : { width: "28px", height: "28px" }
          }
        />
      );

    case "en":
      return (
        <Avatar
          alt="spanish"
          src={image.english}
          style={
            input
              ? { width: "20px", height: "20px" }
              : { width: "28px", height: "28px" }
          }
        />
      );

    default:
      return "Icon not found";
  }
};
