import { Box, Breadcrumbs, Typography } from "@mui/material";
import I18n from "i18n-js";
import StepperCreate from "../../../components/stepperCreate";
import LinkStyles from "../../../themes/js/Link";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import StepOne from "../form/stepOne";
import StepTwo from "../form/stepTwo";
import StepThree from "../form/stepThree";
import StepFour from "../form/stepFour";
import numStep from "../../../utils/questionStep";
import QuestionContext from "../../../context/questionContext";
import { useContext } from "react";
import ViewFinally from "../../../components/finallyView/finally";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { questions } from "../../../service/question";
import { colors } from "../../../utils";
import enumsTypeOfQuestion from "../../../enums/typeOfQuestion";

const QuestionLayout = () => {
  const { id } = useParams();
  const date = new Date();
  const stepsPart = [
    {
      step: "general.step1",
      label: "question.stepper.informationGenerales",
    },
    {
      step: "general.step2",
      label: "question.stepper.questionTranslations",
    },
    {
      step: "general.step3",
      label: "question.stepper.answers",
    },
    {
      step: "general.step4",
      label: "question.stepper.answersTranslations",
    },
  ];
  const classes = useStyles();
  const {
    steps,
    setSteps,
    setData,
    setIsEdition,
    setTypeOfQuestion,
    setIdLocale,
    setDataOpen,
    getDataIdiom,
    userLocale,
  } = useContext(QuestionContext);

  useEffect(() => {
    setIdLocale(id);
    if (id) {
      questions?.detailQuestion(id)?.then(({ data }) => {
        setTypeOfQuestion(data?.question?.question_type_id);
        const options_ = data?.options?.map((item, i) => {
          let title_;

          if (item?.translations) {
            title_ = item?.translations?.map((translation) => {
              return {
                locale: translation?.locale,
                value: translation?.title,
              };
            });
          } else {
            title_ = null;
          }

          return {
            id: !item?.option_group_id ? null : item?.id,
            option_group_id: item?.option_group_id,
            title: title_,
            weight: item?.weight,
            key: date?.getTime() + i,
          };
        });
        const title_ = data?.question?.translations?.map((item) => {
          return {
            locale: item?.locale,
            value: item?.title,
          };
        });
        const description_ = data?.question?.translations?.map((item) => {
          return {
            locale: item?.locale,
            value: item?.description,
          };
        });
        const instructions_ = data?.question?.translations?.map((item) => {
          return {
            locale: item?.locale,
            value: item?.instructions,
          };
        });
        if (data?.question?.question_type_id === enumsTypeOfQuestion?.open) {
          setDataOpen(options_);
        }

        setData({
          question_type_id: data?.question?.question_type_id,
          option_group_id: data?.question?.option_group_id,
          title: title_,
          description: description_,
          instructions: instructions_,
          options: options_,
        });
      });

      setIsEdition(true);
    } //eslint-disable-next-line
  }, []);

  const View = () => {
    switch (steps) {
      case numStep.one:
        return <StepOne />;
      case numStep.two:
        return <StepTwo />;
      case numStep.three:
        return <StepThree setStepsAct={setSteps} />;
      case numStep.four:
        return <StepFour setStepsAct={setSteps} />;
      case numStep.five:
        return (
          <Box height="300px">
            <ViewFinally
              title={I18n.t("exclusionConditions.finally.title")}
              subtitle={
                id
                  ? I18n.t("question.title.editedQuestion")
                  : I18n.t("question.title.createdQuestion")
              }
              to="/dashboard/question/questionlist"
              btn={I18n.t("question.title.return")}
            />
          </Box>
        );
      default:
        return "Page not found";
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerContainer}>
          <Box className={classes.title}>
            <Breadcrumbs>
              <Link style={LinkStyles} to="/dashboard">
                {I18n.t("drawer.setting")}
              </Link>

              <Link
                style={LinkStyles}
                className={classes.linkSelect}
                to="/dashboard/question/questionlist"
              >
                {I18n.t("drawer.questions")}
              </Link>
              <Link
                style={LinkStyles}
                className={classes.linkSelect}
                to="/dashboard/question/create"
              >
                {id
                  ? I18n.t("question.title.editQuestion")
                  : I18n.t("question.title.newQuestion")}
              </Link>
            </Breadcrumbs>
            <Box>
              <Typography color="GrayText">
                {id
                  ? I18n.t("question.title.editQuestion")
                  : I18n.t("question.title.newQuestion")}
              </Typography>
              <Typography variant="h5" color={colors.blueLighten}>
                {!getDataIdiom(userLocale)?.title ||
                getDataIdiom(userLocale)?.title === ""
                  ? I18n.t("question.title.sampleQuestionTitle")
                  : getDataIdiom(userLocale)?.title}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.step}>
            <Box className={classes.stepper}>
              <StepperCreate activeStep={parseInt(steps)} steps={stepsPart} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.main}>{View()}</Box>
    </Box>
  );
};

export default QuestionLayout;
