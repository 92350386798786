import { Box, IconButton, TextField } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import useStyles from "../../pages/questionnaires/form/styles";
import { icons } from "../../utils";

export const WrapperImages = ({
  value,
  label = null,
  onChange = null,
  accept = "image/*",
  errorMessage = null,
  sx = null,
  type = null,
  image = null,
  name,
  required = false,
}) => {
  const [error, setError] = useState(false);
  const classes = useStyles({ error });
  const file = useRef(null);
  const handleFile = (value) => {
    if (type?.includes(value.type) && type) {
      onChange(value);
      setError(false);
    } else {
      setError(true);
    }
    file.current.value = "";
  };

  const handleOpen = () => {
    window.open(image, "_blank");
  };

  return (
    <Fragment>
      <Box
        className={"imputImage"}
        sx={{
          display: "flex",
        }}
      >
        <TextField
          value={value?.name ?? value ?? " "}
          id="datatel"
          type="text"
          disabled
          onClick={() => file.current.click()}
          required={required}
          error={error}
          className={classes.file}
          sx={sx ?? {}}
          label={label}
          helperText={error ? errorMessage : ""}
          InputProps={{
            endAdornment: icons("folder"),
          }}
        />
        <IconButton
          sx={{ display: image ? "block" : "none" }}
          onClick={() => handleOpen()}
        >
          {icons("visibility")}
        </IconButton>
      </Box>
      <input
        name={name ?? "file"}
        type="file"
        onChange={(e) => e.target.files[0] && handleFile(e.target.files[0])}
        hidden
        ref={file}
        accept={accept}
      />
    </Fragment>
  );
};
