export const login = async (user) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })

    const data = await res.json()

    if (data.message === undefined && data.message !== 'Unauthorized') {
      localStorage.setItem('token', data.access_token)

      localStorage.setItem('name', data.user_data.name)

      localStorage.setItem('email', data.user_data.email)

      localStorage.setItem('lang', data.user_data.locale)

      return { response: 200 }
    }

    return { response: 401 }
  } catch (error) {
    localStorage.clear()

    return { response: 404 }
  }
}

export const requestRessetPassword = async (user) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/auth/user/send-password-reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
    
    return {
      data: await res.json(),
      response: 200,
    }
  } catch (e) {
    return { response: 404 }
  }
}

export const ressetPassword = async (user) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/auth/user/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      })
  
      return {
        data: await res.json(),
        response: 200,
      }
    } catch (e) {
      return { response: 404 }
    }
  }

export const listParticipants = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/participants`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    })

    const data = await res.json()

    return {
      data,
      response: 200,
    }
  } catch (error) {
    return { response: 404 }
  }
}

export const detailPaticipant = async (id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_URL}/participants/${id}/answers`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${localStorage.getItem('token')}`,
      },
    })

    const data = await res.json()

    return {
      data: data.partipant_answers,
      response: 200,
    }
  } catch (error) {
    return { response: 404 }
  }
}
