import { Box, Paper, Typography } from "@mui/material";
import { SectionFlowItem } from "./sectionFlowItem";
import colors from "../../themes/colors/colors";
import I18n from "i18n-js";
import { styles } from "./styles";

export const SectionFlowCard = (props) => {
  const { index, section, handleChange, sectionID, error, questionError } =
    props;

  return (
    <Paper elevation={3} sx={styles.flowSections}>
      <Typography sx={{ color: colors.blueLighten }}>
        {I18n.t("questionnaries.title.sectioN")} {index + 1}
      </Typography>

      <Typography sx={styles.questionSections}>{section.title}</Typography>

      <Box>
        {section.questions &&
          section.questions.map((item, i) => (
            <SectionFlowItem
              sectionID={sectionID}
              questionID={item.id}
              steps={item}
              key={item.id}
              list={section.questions}
              handleChange={handleChange}
              error={error}
              questionError={questionError}
              see={i < section.questions.length - 1}
              index={i}
            />
          ))}
      </Box>
    </Paper>
  );
};
