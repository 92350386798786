import { Box } from "@mui/material"
import rolesStyles from "./themes/roles"

const Roles = () => {
    const classes = rolesStyles()

    return (
        <Box className={classes.container}>
            <h1>Roles</h1>
        </Box>
    )
}

export default Roles
