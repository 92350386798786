import { makeStyles } from "@mui/styles";
import { colors } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  linkSelect: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },

  headerQuestionnaires: {
    display: "flex",
    width: "79.9%",
    marginTop: 28,
    justifyContent: "space-between",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "16px",
  },
  stepper: {
    width: "59%",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  nextBack: {
    display: "flex",
    justifyContent: "space-between",
    width: "200px",
  },
  //---------------STEP ONE------------------
  bodyStepOne: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    marginBottom: "50px",
    padding: "30px 50px",
    width: "700px",
    [theme.breakpoints.down("lg")]: {
      width: "450px",
    },
  },

  buttonsContainer: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  //-----------------------STEP TWO------------------
  bodyStepTwo: {
    display: "flex",
    flexDirection: "column",
    marginTop: "90px",
    marginBottom: "8px",
    padding: "30px 50px",
  },

  sectionBody: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    marginTop: "20px",
    marginBottom: "8px",
    padding: "30px",

    width: "1000px",

    [theme.breakpoints.down("lg")]: {
      width: "500px",
    },
  },

  iconSearch: {},
  //----------STEP THREE-----------//
  bodyStepThree: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    marginTop: "20px",
    marginBottom: "8px",
    padding: "30px",

    width: "1000px",

    [theme.breakpoints.down("lg")]: {
      width: "500px",
    },
  },
  bodyInputs: {
    display: "flex",
    gap: "20px",

    flexDirection: "row",
    [theme.breakpoints.down(1280)]: {
      flexDirection: "column",
      gap: "auto",
    },
  },
  backgroundSettings: {
    //marginTop: "42px",

    [theme.breakpoints.down(1280)]: {
      // marginTop: "10px",
    },
  },
  itemsQuestions: {
    border: "1px solid #666666",
    margin: "5px 0px",
    borderRadius: "10px",
    height: "50px",
    paddingLeft: "19px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secCardTitles: {
    overflow: "hidden",
    width: "98%",
  },
}));

export const styles = {
  moveSection: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",

    alignItems: "center",
    right: "25px",
    top: "5px",
  },

  mainTitleCard: {
    width: "300px",
    color: colors.blueLighten,
    textOverflow: "ellipsis",
  },
  containerArrows: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "72px",
  },
  arrowsUp: {
    padding: "inherit",

    "& .MuiSvgIcon-root": {
      fontSize: "39px",
    },
    "&.MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
  },
  arrowsText: {
    border: "solid #666666 1px",
    padding: "0px 3px",
    fontSize: "13px",
    position: "absolute",
    left: "12px",
    top: "25px",
  },
  arrowsDown: {
    padding: "inherit",
    "& .MuiSvgIcon-root": {
      fontSize: 40,
    },
    "&.MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
    position: "absolute",
    bottom: "0px",
    left: "0px",
  },
  //------ STEP THREE--------
  bodyInputs: {
    display: "flex",
    gap: { xs: "auto", lg: "20px" },
    flexDirection: { xs: "column", lg: "row" },
  },
  firstPart: {
    display: "flex",
    gap: { xs: "auto", lg: "20px" },
  },
  colorsMainText: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  colorsLogo: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  secondPart: {
    display: "flex",
    flexDirection: "column",
  },
  backgroundFiles: {
    display: "flex",
    flexDirection: "column",

    height: { xs: "auto", lg: "100px" },
  },
  titleMain: {
    overflowWrap: "break-word",
    width: { xs: "100%", lg: "400px" },
  },
  titleLabels: {
    fontSize: "18px",
    marginBottom: "3px",
  },
};

export const Styles = {
  iconSearch: {
    position: "absolute",
    left: { xs: "90%", lg: "95%" },
    top: { xs: "26px", lg: "9px" },
  },
};

export default useStyles;
