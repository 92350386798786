import { Box, Card, CardContent, IconButton, Typography, Breadcrumbs, Button, Modal, Grid } from '@mui/material'
import { useState, useEffect, useCallback, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
// Components
import SnackMultiple from '../../components/snack/SnackMultiple'
import Track from '../../components/track/track'
import SnackSimple from '../../components/snack/SnackSimple'
import TableView from '../../components/tables/tableView'
import Loading from '../../components/loading'
// Themes
import { createTheme } from '@mui/material'
import ListViewStyle from './themes/ListView'
import LinkStyles from '../../themes/js/Link'
import ModalStyles from '../../themes/js/Modal'
import { table } from './themes/table'
// Icons
import CloseFullscreenSharpIcon from '@mui/icons-material/CloseFullscreenSharp'
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { icons } from '../../utils/index'
import { detailPaticipant } from '../../service/service'
import { sumary, timestamp, timestampGraphic } from '../../methods/watch_data'
import I18n from 'i18n-js'
import Graphic from '../../components/graphic'
const theme = createTheme()

const ListView = () => {
  // Table start
  const columns = [
    { id: 'date', label: I18n.t('listView.date') },
    { id: 'timestamp', label: 'timestamp' },
    { id: 'prvRmssd', label: 'prvRmssd' },
    { id: 'pulseRate', label: 'pulseRate' },
    { id: 'temperature', label: 'temperature' },
    { id: 'respiratoryRate', label: 'respiratoryRate' },
    { id: 'stdAccelerometers', label: 'stdAccelerometers' },
    { id: 'activityCounts', label: 'activityCounts' },
    { id: 'edaScl', label: 'edaScl' },
  ]

  const [rows, setRows] = useState([])

  const [saveModal, setSaveModal] = useState(0)

  const breakPointsModal = {
    padding: saveModal === 0 ? '50px 20px' : 0,
    maxHeight: 500,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  }

  const [track, setTrack] = useState({})

  const [titleTrack, setTitleTrack] = useState('')
  const data = {
    title: `${I18n.t('listView.dowload')}`,
    subtitle: 'BiomarkersSummaryData',
    search: I18n.t('listView.searchPlaceholder'),
  }
  // Table end
  const classes = ListViewStyle({saveModal})

  const { id } = useParams()

  const [user, setUser] = useState({
    name: '',
    lastname: '',
  })

  const [graphic, setGraphic] = useState({})

  // Message error start
  const ref = useRef(null)

  const message = I18n.t('listAnswer.messages.urlEmpty')
  // Message error end

  const [load, setLoad] = useState(true)

  const [questionInformation, setQuestionInformation] = useState([])

  const [openTrackingList, setOpenTrackingList] = useState([])

  // Modal start
  const [lenght, setLenght] = useState(0)

  const [open, setOpen] = useState(false)

  // Modal end

  let [survey, setSurvey] = useState('')

  const changeModal = (value) => {
    setSaveModal(value)
    setOpen(true)
  }

  const timeCalc = (times) => {
    let time = times

    let format = {
      seconds: 0,
      milliseconds: 0,
    }

    do {
      if (time >= 1000) {
        format.seconds += 1
        time -= 1000
      }

      if (time > 0 && time < 1000) {
        format.milliseconds = time
        time -= time
      }
    } while (time > 0)

    return format
  }

  const ParticipanDetail = useCallback(async () => {
    const data = await detailPaticipant(id)

    const answer = data?.data?.answers

    const watch_data = data?.data?.watch_data

    let newAnswer = [...answer]

    // Sumary
    if (watch_data !== null && watch_data !== undefined) {
      newAnswer = await timestamp(watch_data, answer)

      const responseSumary = await sumary(watch_data)

      setGraphic(await timestampGraphic(watch_data))

      setLenght(responseSumary.length)

      setRows(responseSumary.map((value) => value.value))
    }

    const titleSuvey = answer[0].question_option.question.step.survey_section.survey.title

    const newQuestionInformation = newAnswer.map((value) => {
      let length = value.tracking.length - 1

      const questionOptions = value.question_option.question.question_options

      const trackingList = value.tracking.filter((value) => value.option_id !== undefined)

      let newTracking = []

      let newtimestamp = {}
      // Search for the options that were tracked
      const trackOption = value.tracking.map((track) => questionOptions.find((quest) => track.option_id === quest.option_id)?.option?.title)

      trackingList.forEach((track) => {
        questionOptions.forEach((quest) => {
          if (track.option_id === quest.option_id) {
            newTracking.push({
              quest: quest,
              time: timeCalc(track.current_time),
            })
          }
        })
      })

      // Reply send time
      let timeFinish = value.tracking[length].current_time

      value.tracking.forEach((value, index) => {
        if (value.timestamp2 !== undefined) {
          value.timestamp2.forEach((val) => {
            if (val.type === 'accelerometer') {
              newtimestamp = { ...newtimestamp, accelerometer: val }
            }

            if (val.type === 'temperature') {
              newtimestamp = { ...newtimestamp, temperature: val }
            }

            if (val.type === 'eda') {
              newtimestamp = { ...newtimestamp, eda: val }
            }
          })

          newtimestamp = { ...newtimestamp, index, track_action: value.track_action }
        }
      })

      const format = timeCalc(timeFinish)
      return {
        id: value.id,
        question: value.question_option.question.title,
        option: value.question_option.option.title,
        time: format,
        tracking: newTracking,
        timestamp: Object.keys(newtimestamp).length > 0 ? newtimestamp : null,
        track: {
          type: 'scatter',
          label: 'Answers',
          backgroundColor: '#FDC60E',
          borderColor: 'transparent',
          radius: 4,
          borderWidth: 2,
          fill: false,
          trackOption,
          id: 'track',
          data: value?.tracking?.map(({ timestamp }) => ({
            x: parseFloat(new Date(timestamp).getMinutes() + '.' + new Date(timestamp).getSeconds()),
          })),
        },
      }
    })

    setUser({
      name: data.data.name,
      lastname: data.data.lastname,
    })

    setQuestionInformation(newQuestionInformation)

    setSurvey(titleSuvey)

    setLoad(false)
  }, [id])

  const trackingList = (id) => {
    const listInd = openTrackingList.indexOf(id)

    const newTrackingList = [...openTrackingList]

    if (listInd === -1) {
      newTrackingList.push(id)
    } else {
      newTrackingList.splice(listInd, 1)
    }

    setOpenTrackingList(newTrackingList)
  }

  useEffect(() => {
    ParticipanDetail()
  }, [ParticipanDetail])

  if (load) {
    return (
      <Box sx={{ minHeight: window.outerHeight }}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box sx={{ minHeight: window.outerHeight, marginBottom: 5, marginTop: 6 }} className={classes.container}>
      <SnackMultiple ref={ref} message={message} variant="info" />

      {lenght > 0 || saveModal !== 0 ? (
        <Modal open={open} onClose={() => setOpen(false)}>
          <Card sx={{ ...ModalStyles, ...breakPointsModal }}>
            <HighlightOffIcon className={classes.btnClose} onClick={() => setOpen(false)} />
            {saveModal === 0 ? (
              <TableView columns={columns} rows={rows} filter="date" data={data} table={table} />
            ) : (
              <Box>
                <Box className={classes.THGraphic}>
                  <Typography className={classes.titleGraphic}>{titleTrack}</Typography>
                </Box>

                {Object.values(graphic)?.map((item, ind) => (
                  <Box key={ind} className={classes.graphicContainer}>
                    <Graphic datasets={item} track={track} />
                  </Box>
                ))}

                <Box className={classes.containerBM}>
                  <Box sx={{ flexGrow: 1 }} />

                  <Button variant="outlined" className={classes.buttonCancel} onClick={() => setOpen(false)}>
                      {I18n.t('listView.graphic.close')}
                  </Button>
                </Box>
              </Box>
            )}
          </Card>
        </Modal>
      ) : (
        <SnackSimple open={open} close={setOpen} message={I18n.t('listView.messages.empty')} type={'info'} />
      )}

      <Box sx={{ margin: 'auto', paddingX: 5 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={LinkStyles} to="/dashboard">
            {I18n.t('drawer.dashboard')}
          </Link>

          <Link style={LinkStyles} to="/dashboard/answers">
            {I18n.t('drawer.answers')}
          </Link>

          <Link style={LinkStyles} to={`/dashboard/answers/view/${id}`}>
            {I18n.t('drawer.view')}
          </Link>
        </Breadcrumbs>

        <Typography className={classes.title} variant="h6" color="initial">
          {I18n.t('listView.title')}
        </Typography>

        <Box className={classes.dowload}>
          <Typography variant="h4" className={classes.subtitle} sx={{ flexGrow: 1 }}>
            {user.name} {user.lastname}
          </Typography>

          <Box className={classes.dowloadInfo}>
            <Button color="inherit" size="small" variant="outlined" className={classes.buttonDowload} title="data watch" onClick={() => changeModal(0)}>
              <LibraryBooksIcon className={classes.dowloadIcon} />
              <Typography className={`${classes.dowloadText}`}>{I18n.t('listView.dowload')}</Typography>
            </Button>

            <Typography className={`${classes.survey}`}>
              {I18n.t('listView.survey')}: {survey}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ paddingX: 5 }}>
        {questionInformation.map((info) => (
          <Card key={info.id} elevation={0} className={classes.cardContainer}>
            <CardContent sx={{ display: 'flex' }}>
              <Typography sx={{ flexGrow: 1 }} variant="subtitle2">
                {info.question}
              </Typography>

              {Object.keys(graphic).length > 0 && (
                <IconButton
                  className={classes.graphic}
                  onClick={() => {
                    changeModal(1)
                    setTrack(info.track)
                    setTitleTrack(info.question)
                  }}
                >
                  {icons('graphic')}
                  <Typography variant="caption">{I18n.t('listView.graphic.view')}</Typography>
                </IconButton>
              )}
            </CardContent>

            {/* End tracking */}
            <CardContent className={classes.content}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" mr={2} className={`${classes.cardTypography} ${classes.cardTypographyColor}`}>
                    SS: {info.time.seconds} MS: {info.time.milliseconds}
                  </Typography>
                </Grid>

                {info.timestamp !== null ? (
                  <>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2" className={`${classes.cardTypography} ${classes.cardTypographyColor}`}>
                        {info.option}
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Box sx={{ display: 'flex' }}>
                        {info.timestamp !== null && info.timestamp.track_action === 'end_of_question' && (
                          <Grid container>
                            <Track info={info} />
                          </Grid>
                        )}

                        <Box sx={{ flexGrow: 1 }}></Box>
                        {openTrackingList.indexOf(info.id) === -1 ? (
                          <IconButton className={classes.fullScreen} onClick={() => trackingList(info.id)}>
                            <FullscreenSharpIcon />
                          </IconButton>
                        ) : (
                          <IconButton className={classes.fullScreen} onClick={() => trackingList(info.id)}>
                            <CloseFullscreenSharpIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={8}>
                      <Typography variant="subtitle2" className={`${classes.cardTypography} ${classes.cardTypographyColor}`}>
                        {info.option}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Box sx={{ display: 'flex', position: 'relative' }}>
                        <Box sx={{ flexGrow: 1, position: 'relative' }}></Box>
                        {openTrackingList.indexOf(info.id) === -1 ? (
                          <IconButton className={classes.fullScreen} onClick={() => trackingList(info.id)}>
                            <FullscreenSharpIcon />
                          </IconButton>
                        ) : (
                          <IconButton className={classes.fullScreen} onClick={() => trackingList(info.id)}>
                            <CloseFullscreenSharpIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>

            {/* Tracking list */}
            {openTrackingList.indexOf(info.id) !== -1 && (
              <>
                <Box sx={{ borderTop: '1px solid #ddd' }} className={classes.trackContent} />

                {info.tracking.map((track, ind) => (
                  <CardContent key={ind} className={classes.track}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography variant="subtitle2" mr={2} className={classes.cardTypography}>
                          SS: {track.time.seconds} MS: {track.time.milliseconds}
                        </Typography>
                      </Grid>

                      {info.timestamp !== null ? (
                        <>
                          <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.cardTypography}>
                              {track.quest.option.title}
                            </Typography>
                          </Grid>

                          {info.timestamp !== null && ind === info.timestamp.index && (
                            <Grid item xs={7}>
                              <Track info={info} />
                            </Grid>
                          )}
                        </>
                      ) : (
                        <>
                          <Grid item>
                            <Typography variant="subtitle2" className={classes.cardTypography}>
                              {track.quest.option.title}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                ))}
              </>
            )}
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default ListView
