import { Box, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useStyles from "../styles";
import I18n from "i18n-js";
import useSaveSurveys from "../../../../hooks/useSaveSurveys";
import QuestionnairesContext from "../../../../context/questionnairesContext";
import { LayoutButton } from "../../../../components/btnBN/layoutButton";
import { LayoutButtonLink } from "../../../../components/btnBN/layoutButtonLink";
import SimpleError from "../../../../components/errorMesage/simple";

const StepOne = () => {
  const [disableOne, setDisableOne] = useState(true);
  const classes = useStyles();
  const { step, setStep, data } = useContext(QuestionnairesContext);
  const { handleSurvey, returnData } = useSaveSurveys();

  useEffect(() => {
    const emptyValues = [!!returnData("title"), !!returnData("description")];
    if (emptyValues.every((v) => v)) setDisableOne(false);
    else setDisableOne(true);
    //eslint-disable-next-line
  }, [data]);
  const validateInput = (e) => {
    e.target.value = e.target.value.replace(/^\s/, "");
  };
  return (
    <Paper
      elevation={5}
      className={classes.bodyStepOne}
      sx={{ borderRadius: "20px" }}
    >
      <Box>
        <TextField
          margin="normal"
          name="title"
          required
          fullWidth
          label={I18n.t("questionnaries.form.title")}
          placeholder={I18n.t("questionnaries.form.questionnaireTitle")}
          onChange={(e) => handleSurvey(e)}
          onInput={(e) => validateInput(e)}
          value={returnData("title") ?? ""}
          error={returnData("title") === ""}
          inputProps={{ maxLength: 255 }}
        />
        <SimpleError
          error={returnData("title") === ""}
          message={I18n.t("users.messages.required")}
        />
        <TextField
          margin="normal"
          name="description"
          multiline
          required
          fullWidth
          rows={3}
          label={I18n.t("questionnaries.form.description")}
          placeholder={I18n.t("questionnaries.form.descriptionQuestionnaire")}
          onChange={(e) => handleSurvey(e)}
          onInput={(e) => validateInput(e)}
          value={returnData("description") ?? ""}
          error={returnData("description") === ""}
          inputProps={{ maxLength: 255 }}
        />
        <SimpleError
          error={returnData("description") === ""}
          message={I18n.t("users.messages.required")}
        />
        <TextField
          margin="normal"
          name="instructions"
          multiline
          fullWidth
          rows={3}
          label={I18n.t("questionnaries.form.instructions")}
          placeholder={I18n.t("questionnaries.form.questionnaireInstructions")}
          onChange={(e) => handleSurvey(e)}
          onInput={(e) => validateInput(e)}
          value={returnData("instructions") ?? ""}
          inputProps={{ maxLength: 255 }}
        />
      </Box>
      <Box className={classes.buttonsContainer}>
        <LayoutButtonLink
          label={I18n.t("general.cancelBtn")}
          link={"/dashboard/questionnaires"}
        />
        <LayoutButton
          variant="contained"
          label={I18n.t("general.nextBtn")}
          click={() => setStep(step + 1)}
          disabled={disableOne}
        />
      </Box>
    </Paper>
  );
};

export default StepOne;
