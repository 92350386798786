import { makeStyles } from "@mui/styles";

const homeStyles = makeStyles({
  container: {
    padding: 20,
    minHeight: window.innerHeight - 135,
  },
});

export default homeStyles;
