import fetchIntercept from 'fetch-intercept'

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    return [url, config]
  },

  requestError: function (error) {
    return Promise.reject(error)
  },

  response: async function (response) {
    if (response.status === 401) {
      window.location = '/logout'
      return Promise.reject(response)
    }
    return response
  },

  responseError: function (error) {
    return Promise.reject(error)
  },
})
