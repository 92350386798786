import { makeStyles } from "@mui/styles";
import colors from "../../themes/colors/colors";

const useStyles = makeStyles((theme) => ({
  formTwo: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",

    padding: "18px",
    justifyContent: "space-between",
  },

  pagesSec: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  firstPart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  pagesNumber: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  partTwo: {
    display: "flex",
    flexDirection: "column",
  },

  internalButtonBox: {
    display: "flex",
    width: "200px",
    justifyContent: "space-between",
  },
  partThree: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "40px",
    marginBottom: "16px",
  },

  idiom: {
    display: "grid",
    gridTemplateRows: "50px 50px 50px",
    alignItems: "center",
    gap: "15px",
  },

  button: {
    display: "flex",
    justifyContent: "space-between",
    gap: "18px",
    marginTop: "50px",
  },
  link: {
    color: colors.blueLighten,
    textDecoration: "none",
  },
}));

export default useStyles;
