import { useState } from "react";

export const useAuxTranslations = () => {
  const [data, setData] = useState({});

  const updateTranslationValue = (name, value, locale) => {
    const existeTraduccion = data[name]?.find((item) => item.locale === locale);
    if (existeTraduccion) {
      const dataActualizada = data[name].map((item) => {
        if (item.locale === locale) {
          return { ...item, value };
        }
        return item;
      });
      return dataActualizada;
    } else return [...(data[name] ?? []), { locale, value }];
  };

  const addOptions = (options) =>
    setData({ ...data, options: [...(data.options ?? []), options] });

  const handleChangeTraslation = (e, locale) => {
    setData({
      ...data,
      [e.target.name]: updateTranslationValue(
        e.target.name,
        e.target.value,
        locale
      ),
    });
  };
  const getDataIdiom = (locale) => {
    const values = Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        const value = data?.[key]?.find((item) => item.locale === locale);
        if (!value) return undefined;
        return { [key]: value.value };
      }
      return undefined;
    });
    let info;
    values.map((valor) => (info = { ...info, ...valor }));
    return info;
  };

  return { handleChangeTraslation, getDataIdiom, data, addOptions, setData };
};
