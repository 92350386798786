import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const useStyles = makeStyles({
  container: {
    height: 10,
    // minWidth: '10px',
  },
  text: {
    '&.MuiTypography-root': {
      color: colors.palette.error,
      fontSize: '.8em',
      marginTop: 2,
      marginLeft: 5,
    },
  },
  error: {'&.MuiTypography-root': { color: colors.palette.error }},
  success: {'&.MuiTypography-root': { color: colors.palette.primary }},
})

export default useStyles
