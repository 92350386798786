import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '47.5%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  help: {
    '&.MuiTypography-root': {
      marginBottom: 10,
      color: colors.disabledDarken,
    },
  },
  titleContainer: {
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginBottom: '16px',
    },
  },
  barContainer: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '45',
    },
  },
  InfoContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  tableSelection: {
    width: '47.5%',
    border: colors.disabledLighten + ' solid 1px',
    height: 'auto',
    background: colors.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  separation: {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& img': {
        transform: 'rotate(.25turn)',
      },
    },
  },
  tableSelected: {
    border: colors.disabledLighten + ' solid 1px',
    width: '47.5%',
    background: colors.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  message: {
    display: 'flex',
    height: '288px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100px',
    height: '100px',
  },
  table: { borderRadius: '2px' },
  tableHead: { background: colors.palette.greyLighten },
  tableRow: {
    height: '41px',
    background: colors.white,
    '&:hover': {
      background: colors.grey,
    },
    transition: '.2s',
  },
  tableCell: {
    width: '90%',
  },
  pagination: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  delete: {
    '& .MuiSvgIcon-root': {
      color: colors.palette.error,
    },
  },
}))

export default useStyles
