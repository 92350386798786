import { Route, Redirect} from 'react-router-dom'
import I18n from 'i18n-js'
import { FR, PT } from '../lenguages'

const PrivateRoutes = ({component: Component, ...rest}) => {
    I18n.translations = {
        fr: FR,
        pt: PT,
    }

    I18n.locale = localStorage.getItem('lang') || 'fr'
    
    I18n.fallbacks = true

    return (
        <Route {...rest}>
            {
                localStorage.getItem('token') ?
                <Component />
                :
                <Redirect to={{ pathname: '/'}}/>  
            }
        </Route>
    )
}

export default PrivateRoutes
