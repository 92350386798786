import { useEffect, useState } from "react";
import QuestionContext from "../context/questionContext";
import { useAuxTranslations } from "../hooks/useAuxTranslations";
import numStep from "../utils/questionStep";
import languages from "../utils/languages";
import { all } from "../service/CRUD";

function ProviderQuestion({ children }) {
  const [steps, setSteps] = useState(numStep.one);
  const [disabledOne, setDisabledOne] = useState(true);
  const [typeOfQuestion, setTypeOfQuestion] = useState(""); //Tipo de pregunta simple multiple
  const { handleChangeTraslation, getDataIdiom, setData, data, addOptions } =
    useAuxTranslations();
  const userLocale = localStorage.getItem("lang");
  const localesList = languages?.filter((item) => item?.locale !== userLocale);
  const [disabledThree, setDisabledThree] = useState(true);
  const [isLoadid, setIsLoading] = useState(true);
  const [questionTypes, setQuestionTypes] = useState();
  const [valueSwitch, setValueSwitch] = useState(false);
  const [translation, setTranslation] = useState();
  const [isEdition, setIsEdition] = useState(false);
  const [idLocale, setIdLocale] = useState();
  const [dataOptions, setDataOptions] = useState();
  const [groupName, setGroupName] = useState();
  const [arrayTranslations, setArrayTranslations] = useState();
  const [dataOpen, setDataOpen] = useState();

  useEffect(() => {
    all("question-types").then(({ data }) => {
      if (data) {
        const types = data.question_types.map((item) => {
          const typesLocale = item.translations.find(
            (element) => userLocale === element.locale
          );
          return { ...typesLocale, key: item.key };
        });
        setQuestionTypes(types);
      }
    }); // eslint-disable-next-line
  }, []);
  return (
    <QuestionContext.Provider
      value={{
        steps,
        setSteps,
        disabledOne,
        setDisabledOne,
        typeOfQuestion,
        setTypeOfQuestion,
        userLocale,
        localesList,
        handleChangeTraslation,
        getDataIdiom,
        data,
        setData,
        addOptions,
        disabledThree,
        setDisabledThree,
        isLoadid,
        setIsLoading,
        questionTypes,
        valueSwitch,
        setValueSwitch,
        translation,
        setTranslation,
        isEdition,
        setIsEdition,
        idLocale,
        setIdLocale,
        dataOptions,
        setDataOptions,
        groupName,
        setGroupName,
        arrayTranslations,
        setArrayTranslations,
        dataOpen,
        setDataOpen,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
}

export default ProviderQuestion;
