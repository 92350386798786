import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import tableStyles from "./themes/table";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Link } from "react-router-dom";
import LinkStyles from "../../themes/js/Link";
import colors from "../../themes/colors/colors";
import i18n from "i18n-js";
import { ModalQuestion } from "../modal/modalQuestion";
import { useEffect } from "react";
import I18n from "i18n-js";
import { icons } from "../../utils/icons";

const TableVerify = ({
  columns = [],
  rows = [],
  data,
  remove = null,
  parameter = "name",
  actions = null,
  separator = true,
  buttonUp = false,
  titleUp = "",
  onCreate,
  onEdit,
  configure = null,
  translations = null,

  check = null,
  modalCreate = null,
  editingModal = null,
}) => {
  const classes = tableStyles();

  const [text, setText] = useState("");

  const [trash, setTrash] = useState({});

  const [openDelete, setOpenDelete] = useState(false);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const newRow = rows?.filter(
    (row) =>
      row?.[parameter]?.toLowerCase()?.includes(text?.toLowerCase()) ||
      row?.email?.toLowerCase()?.includes(text?.toLowerCase()) ||
      row?.type?.toLowerCase()?.includes(text?.toLowerCase()) ||
      row?.key?.toLowerCase()?.includes(text?.toLowerCase())
  );

  useEffect(() => {
    if (newRow.length < page * rowsPerPage) {
      setPage(0);
      setRowsPerPage(10);
    } //eslint-disable-next-line
  }, [newRow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sendDelete = (row) => {
    remove(row);

    setOpenDelete(false);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.boxSearchCreate}>
        {buttonUp && (
          <Box className={classes.flex} mb={2}>
            <Box className={classes.flexGrow} />
            <Typography
              variant="body1"
              color="primary"
              className={classes.totalSurveys}
            >
              {titleUp}
            </Typography>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => onCreate && onCreate()}
            >
              {!onCreate ? (
                <Link style={{ ...LinkStyles, color: "#fff" }} to={data.to}>
                  {data.searchBtn}
                </Link>
              ) : (
                data.searchBtn
              )}
            </Button>
          </Box>
        )}

        <Typography
          variant="h5"
          mr={2}
          mb={1}
          sx={{ flexGrow: 1, paddingTop: 1 }}
        >
          {data.title}
        </Typography>
        {buttonUp && (
          <Box>
            <TextField
              className={`${classes.input} ${classes.inputSearch}`}
              size="small"
              label={data.search}
              value={text}
              onChange={(e) => setText(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon className={classes.searchIcon} />,
              }}
            />
          </Box>
        )}

        {!buttonUp && (
          <Box>
            <TextField
              className={classes.input}
              size="small"
              label={data.search}
              value={text}
              onChange={(e) => setText(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon className={classes.searchIcon} />,
              }}
            />
            {modalCreate && (
              <Button
                variant="contained"
                onClick={() => modalCreate(true)}
                className={classes.button}
              >
                {data.searchBtn}
              </Button>
            )}
            {!modalCreate && (
              <Button variant="contained" className={classes.button}>
                <Link style={{ ...LinkStyles, color: "#fff" }} to={data.to}>
                  {data.searchBtn}
                </Link>
              </Button>
            )}
          </Box>
        )}
      </Box>

      <ModalQuestion
        title={data.titleM}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        labelCancel={data.btnCancel}
        labelAccept={data.btnAccept}
        sendDelete={sendDelete}
        trash={trash}
      />

      {/* ============================= Table ======================== */}
      <Box>
        <TableContainer>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead style={{ height: 40 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={separator ? classes.cell : ""}
                  >
                    <Typography>{column.label}</Typography>
                  </TableCell>
                ))}

                <TableCell sx={{ width: 80 }}>
                  <Typography variant="body1" align="center">
                    {i18n.t("general.actions")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newRow.length > 0 ? (
                newRow
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        className={classes.color}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            className={
                              separator ? classes.cell : classes.normal
                            }
                          >
                            {row[column.id]}
                          </TableCell>
                        ))}

                        <TableCell
                          sx={{
                            width: "200px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {!editingModal && (
                              <IconButton
                                onClick={() => onEdit && onEdit(row.id)}
                              >
                                {!onEdit ? (
                                  <Link to={`${data.to2}/${row.id}`}>
                                    <CreateIcon
                                      sx={{ color: colors.palette.general }}
                                    />
                                  </Link>
                                ) : (
                                  <CreateIcon
                                    sx={{ color: colors.palette.general }}
                                  />
                                )}
                              </IconButton>
                            )}
                            {editingModal && (
                              <IconButton
                                onClick={() => editingModal(true, row.id)}
                              >
                                <CreateIcon
                                  sx={{ color: colors.palette.general }}
                                />
                              </IconButton>
                            )}

                            {translations && (
                              <IconButton
                                sx={{
                                  "& .MuiSvgIcon-root": { color: "#03A17F" },
                                }}
                              >
                                <Link to={`${translations}/${row.id}`}>
                                  {icons("language")}
                                </Link>
                              </IconButton>
                            )}
                            {configure && (
                              <IconButton
                                sx={{
                                  "& .MuiSvgIcon-root": { color: "#007FB1" },
                                }}
                              >
                                <Link to={`${configure}/${row.id}`}>
                                  {icons("settings")}
                                </Link>
                              </IconButton>
                            )}

                            {actions !== null && row?.actions}

                            {trash !== null &&
                              (remove !== null || check !== null) && (
                                <IconButton
                                  onClick={
                                    check
                                      ? () => check(row)
                                      : () => {
                                          setTrash(row);
                                          setOpenDelete(true);
                                        }
                                  }
                                >
                                  <DeleteOutlineOutlinedIcon color="error" />
                                </IconButton>
                              )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Box className={classes.textNoResults}>
                  <Typography>
                    {I18n.t("typeOfQuestion.errors.noResultsFound")}
                  </Typography>
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={newRow.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          className={classes.color}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

TableVerify.defaultProps = {
  data: {
    title: "",
    search: "",
    searchBtn: "",
    to: "",
    to2: "",
    titleM: "",
    btnCancel: "",
    btnAccept: "",
  },
};

export default TableVerify;
/*
.slice(
                  newRow.length > page * rowsPerPage
                    ? (page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : (0, 10)
                )
*/
