import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";
import I18n from "i18n-js";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import LanguagesText from "../languagesText";
import { questions } from "../../service/question";
import QuestionContext from "../../context/questionContext";
import { useParams, Link } from "react-router-dom";
import { LayoutButton } from "../btnBN/layoutButton";

const TranslatorAsks = (props) => {
  const {
    setSteps,
    nextStep,
    backStep,
    arrayElement,
    handleChangeTraslation,
    nameVar,
    getDataIdiom,
    bam,
    data,
    deleteData,
    contentAsk,
  } = props;
  const { id } = useParams();
  const { isEdition, userLocale } = useContext(QuestionContext);
  const [pages, setPages] = useState(0);
  const [auxLabel, setAuxLabel] = useState("");
  const [auxValue, setAuxValue] = useState("");

  const classes = useStyles();
  if (!Object.values(contentAsk)[pages]) <></>;

  const handleSubmit = async () => {
    const body = { ...data };

    let resp;
    const options = body.options;
    const newAnswers = options.map((option) => {
      const newAnswer = { ...option };
      if (newAnswer.weight === null) newAnswer.weight = null;
      if (newAnswer.weight)
        newAnswer.weight = Number.parseInt(newAnswer.weight);
      delete newAnswer.key;
      return newAnswer;
    });
    body.options = newAnswers;
    if (isEdition) {
      // eslint-disable-next-line
      resp = await questions.editQuestion(id, body);
    } else {
      resp = await questions.createQuestion(body);
    }
    if (resp.data) {
      setSteps(nextStep);
    }
  };

  const traverseArray = () => {
    setSteps(nextStep);
  };

  useEffect(() => {
    if (bam === false) setAuxLabel(contentAsk[nameVar[pages]]);
    // eslint-disable-next-line
  }, [nameVar]);

  useEffect(() => {
    if (bam === false) {
      if (contentAsk !== {}) {
        if (contentAsk[nameVar[pages]]) {
          setAuxLabel(contentAsk[nameVar[pages]]);
        }
      }
    }
    if (bam === true) {
      const content = contentAsk.map((item) => {
        const value_ = item.title.find(
          (element) => userLocale === element.locale
        );
        return value_.value;
      });
      setAuxLabel(content[pages]);
    }
    setAuxValue(Object.values(props.labelContent)[pages]);
    deleteData(); // eslint-disable-next-line
  }, [pages]);

  const text = (idiom) => {
    switch (idiom) {
      case "fr":
        return I18n.t("languages.french");
      case "pt":
        return I18n.t("languages.portuguese");
      default:
        return "Idiom not found";
    }
  };
  return (
    <Box
      elevation={3}
      className={classes.formTwo}
      sx={{ borderRadius: "20px" }}
    >
      <Box>
        <Box className={classes.firstPart}>
          <Typography variant="h7" color="GrayText">
            {I18n.t("question.translatorAsks.pleaseTranslateTheFollowingTexts")}
          </Typography>
          <Box className={classes.pagesNumber}>
            <Typography
              sx={{ fontSize: "20px" }}
              className={classes.pagesSec}
              variant="h9"
              color="GrayText"
            >
              <IconButton
                onClick={() => {
                  pages > 0 ? setPages(pages - 1) : setPages(0);
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <Box>
                {bam === false
                  ? pages + 1
                  : data.options?.length === 0 || !data.options
                  ? 0
                  : pages + 1}
                /{Object.values(props.contentAsk).length}
              </Box>
              <IconButton
                onClick={() => {
                  pages < Object.values(props.contentAsk).length - 1
                    ? setPages(pages + 1)
                    : setPages(Object.values(props.contentAsk).length - 1);
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Typography>
            <Typography sx={{ fontSize: "11px" }} color="GrayText">
              {I18n.t("question.translatorAsks.textToTranslate")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.partTwo}>
          <TextField
            disabled
            label={auxValue ?? ""}
            value={auxLabel ?? ""}
            multiline
          />
        </Box>
        <Box className={classes.partThree}>
          {arrayElement.map((elemen, key) => {
            return (
              <LanguagesText
                name={nameVar[pages]}
                locale={elemen.locale}
                handleChangeTraslation={handleChangeTraslation}
                text={text(elemen.locale)}
                key={key}
                getDataIdiom={getDataIdiom}
                bam={bam}
                id={bam === true ? (data.options ? data.options[pages] : 0) : 0}
                pages={pages}
              />
            );
          })}
        </Box>
      </Box>
      <Box className={classes.button}>
        <Button>
          <Link
            className={classes.link}
            to={"/dashboard/question/questionlist"}
          >
            {I18n.t("general.cancelBtn")}
          </Link>
        </Button>
        <Box className={classes.internalButtonBox}>
          <LayoutButton
            variant="outlined"
            click={() => {
              setSteps(backStep);
            }}
            label={I18n.t("general.backBtn")}
          />
          <LayoutButton
            variant="contained"
            click={!bam ? traverseArray : handleSubmit}
            label={I18n.t("general.nextBtn")}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TranslatorAsks;
