import { useState } from "react";
import QuestionnairesContext from "../context/questionnairesContext";

const ProviderQuestionnaires = ({ children }) => {
  const userLocale = localStorage.getItem("lang");
  const [data, setData] = useState({});
  const [dataStyles, setDataStyles] = useState({});
  const [step, setStep] = useState(0);
  const [list, setList] = useState();
  const [disableRemov, setDisableRemov] = useState(false);
  const [filteredQuestions, setFilteresQuestions] = useState();
  const [disableNextTwo, setDisableNextTwo] = useState(true);
  const [viewTwo, setViewTwo] = useState(false);
  const [noQuestions, setNoQuestions] = useState(false);
  const [enableSent, setEnableSent] = useState(true);

  return (
    <QuestionnairesContext.Provider
      value={{
        step,
        setStep,
        data,
        setData,
        userLocale,
        list,
        setList,
        filteredQuestions,
        setFilteresQuestions,
        disableRemov,
        setDisableRemov,
        disableNextTwo,
        setDisableNextTwo,
        viewTwo,
        setViewTwo,
        noQuestions,
        setNoQuestions,
        enableSent,
        setEnableSent,
        dataStyles,
        setDataStyles,
      }}
    >
      {children}
    </QuestionnairesContext.Provider>
  );
};

export default ProviderQuestionnaires;
