const apiUrl = process.env.REACT_APP_URL

export const isValidToken = async () => {
  const token = localStorage.getItem('token')

  if (token) {
    const response = await fetch(apiUrl + '/auth/me', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 401) {
      window.location = '/logout'
      return { data: null, error: response }
    }

    return { data: response, error: null }
  }
}
