import { Box, Button, Grid, IconButton, TextField } from '@mui/material'
import I18n from 'i18n-js'
import React from 'react'
import SimpleError from '../../../../components/errorMesage/simple'
import { icons } from '../../../../utils'
import useStyles from './styles'

const Step1 = ({ list, handleDelete, handleList, onChange, locale, id = null }) => {
  const classes = useStyles()

  return (
    <>
      {list?.map((item) => (
        <Box className={`${classes.card} ${classes.flex}`} key={item.id}>
          <Grid container spacing={2} className={classes.cardContainer}>
            <Grid item xs={6}>
              <TextField
                value={item?.[locale]?.description}
                name="description"
                onChange={(e) => onChange(e, item.id)}
                className={classes.input}
                label={I18n.t('exclusionConditions.form.labelTextField') + ' (*)'}
                multiline
                rows={4}
                size="small"
                error={item?.[locale]?.description === ''}
              />
              <SimpleError error={item?.[locale]?.description === ''} message={I18n.t('users.messages.required')} />
            </Grid>
            <Grid item xs={6}>
              <TextField value={item?.[locale]?.recommendation} name="recommendation" onChange={(e) => onChange(e, item.id)} className={classes.input} label={I18n.t('exclusionConditions.form.exclusionInstruction')} multiline rows={4} size="small" />
            </Grid>
          </Grid>

          {id === null && (
            <IconButton onClick={() => handleDelete(item.id)} className={classes.delete}>
              {icons('deleteOutline')}
            </IconButton>
          )}
        </Box>
      ))}

      {id === null && (
        <Button variant="text" className={classes.btnAdd} onClick={() => handleList()}>
          {I18n.t('exclusionConditions.form.add')}
        </Button>
      )}
    </>
  )
}

export default Step1
