import colors from "../colors/colors"

const ModalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: '90%',
    backgroundColor: colors.modal.primary,
    boxShadow: '3px 2px #5F6E6D',
    padding: '10px 20px',
}

export default ModalStyles